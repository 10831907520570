import { Fade, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import Pilote from '../assets/elements2D/pilote_assistant.svg';
import windowDialog from '../assets/ui/window_dialog.svg';
import { UIContext } from '../contexts/UIContext';
import Typewriter from '../utils/Typewriter';
import { NextButton } from './buttons/NextButton';

export const DialogAI = () => {
	const UIContextValues = React.useContext(UIContext);

	const [currentDialogIndex, setCurrentDialogIndex] = useState<number>(0);
	const extraSmallHeight = useMediaQuery('(max-width:400px)');

	return (
		<Fade in={UIContextValues.dialogAIVisibility}>
			<Box component={'div'} sx={styles.container}>
				<Box sx={styles.windowDialogArea}>
					<Box component="div" sx={styles.dialogIAContent}>
						<Box component="div" sx={styles.dialogBox}>
							<Typography
								gutterBottom
								sx={{
									...{ fontSize: extraSmallHeight ? '0.7em' : '1em' }
								}}
								variant="h4"
							>
								<Typewriter
									text={UIContextValues.AIdialogTextList[currentDialogIndex]}
									delay={30}
								/>
							</Typography>
						</Box>

						<Box sx={styles.buttonArea}>
							<NextButton
								id="nextButton"
								sx={styles.nextButton}
								onClick={() => {
									if (
										currentDialogIndex <
										UIContextValues.AIdialogTextList.length - 1
									) {
										setCurrentDialogIndex(currentDialogIndex + 1);
										return;
									}

									UIContextValues.nextOverlaysEvents?.();
									setCurrentDialogIndex(0);
								}}
							>
								Next
							</NextButton>
						</Box>
					</Box>
				</Box>

				<Box sx={styles.IAArea}>
					<Box component="div" sx={styles.pilote}>
						{' '}
					</Box>
				</Box>
			</Box>
		</Fade>
	);
};
const styles = {
	container: {
		position: 'absolute',
		height: '100%',
		width: '100%',

		// backgroundColor: 'green',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-end',
		bottom: '15vh'
	},
	windowDialogArea: {
		position: 'absolute',
		// backgroundColor: 'blue',
		// height: '50vh',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-start'
	},
	dialogIAContent: {
		position: 'relative',
		// backgroundColor: 'red',
		height: {
			xs: '10em',
			sm: '12em',
			md: '12em',
			lg: '12em'
		},
		width: '95%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		// justifyContent: 'space-around',
		// padding: '2em',
		borderImageSource: `url(${windowDialog})`,
		borderImageSlice: '22 22 22 22 fill',
		borderImageWidth: '20px 20px 20px 20px',
		borderImageOutset: '0px 0px 0px 0px',
		borderImageRepeat: 'stretch stretch',
		zIndex: 12
	},

	pilote: {
		backgroundImage: `url(${Pilote})`,
		backgroundSize: {
			xs: '5.5em',
			sm: '6em',
			md: '6em',
			lg: '7em'
		},
		// position: 'absolute',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		// bottom: '6em',
		// left: '1em',
		// backgroundCover: 'cover',
		height: {
			xs: '4.5em',
			sm: '6em',
			md: '5em',
			lg: '5em'
		},

		width: {
			xs: '4.5em',
			sm: '6em',
			md: '5em',
			lg: '5em'
		},

		borderRadius: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden',
		border: '0.4em solid #d7f1ff',

		zIndex: 13
	},
	dialogBox: {
		// backgroundColor: 'purple',
		// position: 'absolute',
		// padding: '1.5em',
		padding: '1.2em',
		marginTop: '2vh',
		display: 'flex',
		alignItems: 'flex-start',
		justifycontent: 'center',
		// bottom: '12vh',
		// left: '5em',
		height: '100%',
		width: '90%',
		color: 'black',
		zIndex: 12
	},
	buttonArea: {
		position: 'absolute',
		display: 'flex',
		justifyContent: 'flex-end',
		// backgroundColor: 'pink',
		height: '5vh',
		width: '90%',
		zIndex: 13,
		bottom: {
			xs: '2vh',
			sm: '1vh',
			md: '1vh'
		}
	},
	IAArea: {
		display: 'flex',
		position: 'absolute',

		justifyContent: 'flex-start',
		// backgroundColor: 'cyan',
		height: '6vh',
		width: '50%',
		left: '1em',
		zIndex: 12
	},
	nextButton: {
		// position: 'absolute',
		// right: '4em',
		// bottom: '11em',
		zIndex: 14
	}
};
