import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalButton } from '../components/buttons/GlobalButton';
import { BorderColorEnum } from '../components/containers/CornerContainer';
import { PopupContainer, PopupContainerProps } from './PopupContainer';

interface ConfirmationPopupProps extends Omit<PopupContainerProps, 'open' | 'onClickCloseButton'> {
	ref: any;
	yesText?: string;
	noText?: string;
	onYesClick?: () => void;
	onNoClick?: () => void;
}

export interface ConfirmationPopupMethods {
	// eslint-disable-next-line
	setOpen: (val: boolean) => void;
}

export const ConfirmationPopup = forwardRef<ConfirmationPopupMethods, ConfirmationPopupProps>(
	(props, ref) => {
		const [visibility, setVisibility] = useState(false);
		useImperativeHandle(ref, () => ({
			setOpen(val: boolean) {
				setVisibility(val);
			}
		}));
		const { t } = useTranslation();

		return (
			<PopupContainer
				{...props}
				borderColor={BorderColorEnum.warning}
				disableClosable={true}
				open={visibility!}
				onClickCloseButton={() => {
					setVisibility(false);
				}}
			>
				<Box sx={styles.container}>
					{props.children}
					<Stack sx={styles.buttonsContainer}>
						<GlobalButton
							onClick={() => {
								props.onYesClick?.();
								setVisibility(false);
							}}
						>
							{props.yesText ? props.yesText : t('confirmation_popup_yes')}
						</GlobalButton>
						<GlobalButton
							sx={styles.noButton}
							onClick={() => {
								props.onNoClick?.();
								setVisibility(false);
							}}
						>
							{props.noText ? props.noText : t('confirmation_popup_no')}
						</GlobalButton>
					</Stack>
				</Box>
			</PopupContainer>
		);
	}
);

const styles = {
	background: {
		position: 'absolute',
		top: 0,
		// background: "rgba(0, 0, 0, 0.8)",
		background: 'red',
		height: '100%',
		width: '100%',
		objectFit: 'cover'
	},
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		// paddingBottom: '1em',
		// paddingTop: '1em',
		alignItems: 'center',
		textAlign: 'center'
	},
	buttonsContainer: {
		paddingTop: '1em',

		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '1em',
		width: '100%'
		// backgroundColor: "red",
	},

	noButton: {
		fontSize: '1em',
		fontWeight: 'bold',
		// width: '100%',
		// height: '1em',
		backgroundColor: 'transparent',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		// padding: '0 !important',
		// paddingRight: '0.5em !important',
		color: 'primary.main',
		'&:hover': {
			backgroundColor: 'transparent',
			borderColor: 'primary.main',
			border: '1px solid'
		}
	}
};
