import { DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import Card from './Card';
import { CardProps } from './GameComponents.model';
import { Box } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQueryTheme';

export default function CardContainer({
	visible,
	cardData,
	onSideChosen,
	onCardDragMove
}: CardProps): JSX.Element {
	const verySmallHeight = useMediaQuery('(max-height:630px)');

	const moveRestriction = function (args: any) {
		const { transform } = args;

		return {
			...transform,
			x: Math.min(Math.max(transform.x, -50), 50),
			y: Math.min(Math.max(transform.y / 5, -20), 10)
		};
	};

	const mouseSensor = useSensor(MouseSensor, {
		// Require the mouse to move by 10 pixels before activating
		activationConstraint: {
			distance: 0,
			tolerance: 10
		}
	});
	const touchSensor = useSensor(TouchSensor, {
		// Press delay of 250ms, with tolerance of 5px of movement
		activationConstraint: {
			delay: 0,
			tolerance: 10
		}
	});

	const sensors = useSensors(mouseSensor, touchSensor);

	return (
		<Box
			sx={{
				visibility: visible ? 'visible' : 'hidden',
				position: 'absolute',

				bottom: {
					xs: verySmallHeight ? '-2%' : '10%',
					sm: '12%',
					md: '11%'
				},
				left: '50%',
				transform: 'translateX(-50%)'
			}}
		>
			<DndContext modifiers={[moveRestriction]} sensors={sensors}>
				<Card
					cardData={cardData}
					onSideChosen={onSideChosen}
					onCardDragMove={onCardDragMove}
				/>
			</DndContext>
		</Box>
	);
}
