import BottomNavigationAction, {
	BottomNavigationActionProps
} from '@mui/material/BottomNavigationAction';
import { useContext } from 'react';
import useSound from 'use-sound';
import clickButtonSfx from '../../assets/sounds/button-click.mp3';
import { ParametersContext } from '../../contexts/ParametersContext';

export const CustomBottomNavigationAction = (Props: BottomNavigationActionProps) => {
	const ParametersContextValues = useContext(ParametersContext);

	const [buttonClickSfx] = useSound(clickButtonSfx, {
		volume: 0.8,
		soundEnabled: ParametersContextValues?.soundEnabled
	});

	return (
		<BottomNavigationAction
			{...Props}
			onClick={(e: any) => {
				buttonClickSfx();
				Props?.onClick?.(e);
			}}
		/>
	);
};
