import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useContext, useEffect } from 'react';
import Cristal from '../assets/profil/green_cristal.svg';
import { Team, User } from '../contexts/Contexts.types';
import { UIContext } from '../contexts/UIContext';
import { UserContext } from '../contexts/UserContext';
import { getRankWithSuffix } from '../utils/UtilMethods';
import { GlobalPicture } from './GlobalPicture';
import { PlayerPicture } from './profile/PlayerPicture';

interface LeaderboardRankProps {
	user?: User;
	team?: Team;
	me?: boolean;
	podium?: boolean;
	hideRank?: boolean;
}

export const LeaderboardRank = (props: LeaderboardRankProps) => {
	const UIContextValues = useContext(UIContext);
	const UserContextValues = useContext(UserContext);

	const Item = styled(Box)(() => ({
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		overflowWrap: 'break-word'
	}));

	const [logo, setLogo] = React.useState<JSX.Element | null>(null);
	const [name, setName] = React.useState<JSX.Element | null>(null);
	// eslint-disable-next-line
	const [score, setScore] = React.useState<JSX.Element | null>(null);
	const [rank, setRank] = React.useState<JSX.Element | null>(null);

	useEffect(() => {
		const dynamicTypoSX = {
			color: props.me ? 'primary.main' : '#FFF',
			fontWeight: props.me ? 'bolder' : 'regular',
			textTransform: props.me ? 'uppercase' : ''
		};

		if (props.user) {
			setLogo(
				<PlayerPicture
					disabled={!UIContextValues.leaderboardInteractive}
					key={props.user?.id}
					user={props.user}
				/>
			);
			setName(<Typography sx={dynamicTypoSX}>{props.user?.name}</Typography>);
			setScore(
				<Typography sx={dynamicTypoSX}>
					{props.user?.score} <sup>pts</sup>
				</Typography>
			);
			setRank(
				<Typography sx={dynamicTypoSX}>
					{getRankWithSuffix(Number(props.user?.rank))}
				</Typography>
			);
		}

		if (props.team) {
			setLogo(
				<GlobalPicture
					disabled={
						UIContextValues?.introductionTeamsVisibility ||
						!UIContextValues?.leaderboardInteractive
					}
					team={props.team}
					size={{
						xs: 'scale(0.7)',
						sm: 'scale(0.8)'
					}}
				/>
			);
			setName(<Typography sx={dynamicTypoSX}>{props.team?.name}</Typography>);
			setScore(
				<Typography sx={dynamicTypoSX}>
					{props.team?.score} <sup>pts</sup>
				</Typography>
			);
			setRank(
				<Typography sx={dynamicTypoSX}>
					{getRankWithSuffix(Number(props.team?.rank))}
				</Typography>
			);
		}
	}, [props, props.team, props.me]);

	return props.podium ? (
		<>
			{logo}
			{name}
			{score}
		</>
	) : (
		<Grid
			id={props.team?.id === UserContextValues?.myTeam?.id ? 'my-team' : ''}
			container
			sx={styles.container}
		>
			{!props.hideRank && (
				<Grid item xs={1} md={1} sx={styles.row}>
					<Item>{rank}</Item>
				</Grid>
			)}
			<Grid item xs={4} md={4}>
				<Item>{logo}</Item>
			</Grid>
			<Grid item xs={7} md={7} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
				<Item>{name}</Item>
			</Grid>
			<Grid item xs={3} md={3}>
				<Item>{score}</Item>
			</Grid>
		</Grid>
	);
};

const styles = {
	container: {
		// backgroundColor: 'green',
		width: '100% !important',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		// marginBottom: '1em',
		'& .MuiTypography-root': {
			fontSize: '0.7em'
		}
	},
	row: {
		whiteSpace: 'nowrap'
		// backgroundColor: 'red',
	},
	icon: {
		height: '1.5em',
		width: '1.5em',
		backgroundRepeat: 'no-repeat',
		backgroundImage: `url(${Cristal})`,
		backgroundPosition: 'center'
	}
};
