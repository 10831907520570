import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import Cristal from '../../assets/profil/green_cristal.svg';
import soloRankIcon from '../../assets/profil/rank_solo.svg';
import teamRankIcon from '../../assets/profil/rank_team.svg';
import { Team, User } from '../../contexts/Contexts.types';
import { InfoIcon } from './InfoIcon';

interface MainInfoProfileProps {
	children?: React.ReactNode;
	user?: User;
	team?: Team;
	displayTitle?: boolean;
	flexDirection?: string;
}

export const MainInfoProfile = ({
	displayTitle = true,
	flexDirection = 'column',
	...props
}: MainInfoProfileProps) => {
	return (
		<Box sx={styles.container}>
			{displayTitle && (
				<Box component="div" sx={styles.infoPlayer}>
					<Typography variant="h5">{props.user?.name}</Typography>
				</Box>
			)}
			<Box
				component="div"
				sx={{
					...styles.infoiconPlayer,
					flexDirection: flexDirection === 'column' ? 'column' : 'row'
				}}
			>
				<InfoIcon icon={Cristal} text={`${props.user?.score}`} th={false}></InfoIcon>
				<InfoIcon icon={teamRankIcon} text={`${props.team?.rank}`} th={true}></InfoIcon>
				<InfoIcon icon={soloRankIcon} text={`${props.user?.rank}`} th={true}></InfoIcon>
			</Box>
		</Box>
	);
};

const styles = {
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-start',
		// backgroundColor: 'green',
		flexDirection: 'column',
		height: '100%'
	},
	infoPlayer: {
		paddingTop: 2,
		paddingBottom: 2,
		display: 'flex',

		justifyContent: 'center',
		alignItems: 'center',
		width: '100%'
		// backgroundColor: 'purple'
	},
	infoiconPlayer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-wrap',
		gap: '1em'
		// backgroundColor: 'green'
	}
};
