import Grid from '@mui/material/Unstable_Grid2';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as _ from 'underscore';
import { FixedPage } from '../../components/page/FixedPage';
import { Navbar } from '../../components/page/Navbar';
import AnimatedBackground from '../components/AnimatedBackground';
import CardContainer from '../components/CardContainer';
import GameOverBox from '../components/GameOverBox';
import GameProgression from '../components/GameProgression';
import Gauge from '../components/Gauge';
import NarrativeContent from '../components/NarrativeContent';
import ScoreBox from '../components/ScoreBox';
import { CardData } from '../datas/CardData';
import { ChapterData } from '../datas/ChapterData';
import { GaugeData } from '../datas/GaugeData';
import { SectionData } from '../datas/SectionData';

import { Box } from '@mui/system';
import useSound from 'use-sound';
import gameDialogueSfx from '../../assets/sounds/game-dialogue.mp3';
import gameMissionCompleteSfx from '../../assets/sounds/game-mission-complete.mp3';
import { MissionContext } from '../../contexts/MissionContext';
import { ParametersContext } from '../../contexts/ParametersContext';
import SwipeAnimation from '../components/SwipeAnimation';

/* eslint-disable */

export function GamePage() {
	const MissionContextValues = useContext(MissionContext);
	const ParametersContextValues = useContext(ParametersContext);
	const { chapterId } = useParams();
	const [currentChapter, setCurrentChapter] = useState<ChapterData>();
	const [allCards, setAllCards] = useState<CardData[]>([]);
	const [currentChapterSections, setCurrentChapterSections] = useState<SectionData[]>([]);
	const [currentSection, setCurrentSection] = useState<SectionData>();
	const [currentSectionIndex, setCurrentSectionIndex] = useState<number>();
	const [currentChapterCards, setCurrentChapterCards] = useState<CardData[]>([]);
	const [processedEventCardsId, setProcessedEventCardsId] = useState<string[]>([]);
	const [totalNbEventCardsToProcess, setTotalNbEventCardsToProcess] = useState<number>();
	const [currentCumulatedMaxEventCard, setCurrentCumulatedMaxEventCard] = useState<number>(0);
	const [currentCard, setCurrentCard] = useState<CardData>();
	const [gaugeValues, setGaugeValues] = useState<number[]>([50, 50, 50]);
	const [gaugeModifierPreviews, setGaugeModifierPreviews] = useState<number[]>([0, 0, 0]);
	const [currentChapterGauges, setCurrentChapterGauges] = useState<GaugeData[]>([
		new GaugeData(),
		new GaugeData(),
		new GaugeData()
	]);
	const [showScore, setShowScore] = useState(false);
	const [score, setScore] = useState(0);
	const [isGameOver, setIsGameOver] = useState(false);

	const [playGameDialogueSfx] = useSound(gameDialogueSfx, {
		volume: 0.7,
		soundEnabled: ParametersContextValues?.soundEnabled
	});
	const [playGameMissionCompleteSfx] = useSound(gameMissionCompleteSfx, {
		volume: 0.8,
		soundEnabled: ParametersContextValues?.soundEnabled
	});

	useEffect(() => {
		Promise.all([
			ChapterData.getEntities(),
			GaugeData.getEntities(),
			SectionData.getEntities(),
			CardData.getEntities()
		]).then(([chapters, gauges, sections, cards]) => {
			//
			const currentChapter = _.findWhere(chapters, { id: chapterId });

			const currentChapterGauges = _.filter(gauges, function (gauge: GaugeData) {
				return _.contains(
					[currentChapter.gauge1Id, currentChapter.gauge2Id, currentChapter.gauge3Id],
					gauge.id
				);
			});

			const currentChapterSections = _.where(sections, { chapterId: chapterId });
			const currentChapterSection = currentChapterSections[0];
			const currentChapterCards = _.where(cards, { chapterId: chapterId });
			const currentCard = _.findWhere(currentChapterCards, {
				id: currentChapterSection.introCardId
			});

			let eventCardsToProcess = 0;
			currentChapterSections.forEach(function (section: SectionData) {
				eventCardsToProcess += section.maxEventCards;
			});

			setAllCards(cards);
			setCurrentChapterCards(currentChapterCards);
			setCurrentCard(currentCard);
			setCurrentSection(currentChapterSection);
			setCurrentChapterSections(currentChapterSections);
			setTotalNbEventCardsToProcess(eventCardsToProcess);
			setCurrentChapter(currentChapter);
			setCurrentChapterGauges(currentChapterGauges);
			setGaugeValues([
				currentChapterGauges[0].startValue,
				currentChapterGauges[1].startValue,
				currentChapterGauges[2].startValue
			]);
			setCurrentSectionIndex(0);
		});
	}, [chapterId]);

	useEffect(() => {
		if (_.isUndefined(currentSectionIndex)) return;
		const currentChapterSection = currentChapterSections[currentSectionIndex];
		setCurrentSection(currentChapterSection);

		let cumulatedMaxEventCard = 0;
		currentChapterSections.forEach(function (section: SectionData, i: number) {
			if (i <= currentSectionIndex) cumulatedMaxEventCard += section.maxEventCards;
		});
		setCurrentCumulatedMaxEventCard(cumulatedMaxEventCard);
	}, [currentSectionIndex]);

	useEffect(() => {
		if (!currentCard) return;

		// console.info(currentCard);

		if (currentCard.type === CardData.CARD_TYPE_EVENT)
			setProcessedEventCardsId(_.union(processedEventCardsId, [currentCard.id]));
	}, [currentCard]);

	useEffect(() => {
		if (!gaugeValues || !currentChapterGauges || !gaugeValues) return;
		let score = 0;
		currentChapterGauges.forEach(function (gauge: GaugeData, i) {
			score += GaugeData.getScore(gauge, gaugeValues[i]);
		});
		setScore(score / currentChapterGauges.length);
	}, [gaugeValues]);

	const onCardSideChosen = (cardData: CardData, side: number) => {
		if (!currentSection || _.isUndefined(currentSectionIndex)) return;

		// Gauges
		const gaugeModifiers =
			side === 1
				? [
						cardData.actionRightGauge1,
						cardData.actionRightGauge2,
						cardData.actionRightGauge3
				  ]
				: [cardData.actionLeftGauge1, cardData.actionLeftGauge2, cardData.actionLeftGauge3];

		const newGaugeValues = _.clone(gaugeValues);
		let extremeGaugeGameOverCardId ;

		newGaugeValues.forEach(function (gaugeValue, i) {
			newGaugeValues[i] += gaugeModifiers[i];

			if (newGaugeValues[i] > currentChapterGauges[i].maxValue) {
				newGaugeValues[i] = currentChapterGauges[i].maxValue;
				if (currentChapterGauges[i].maxValueGameOverCardId)
					extremeGaugeGameOverCardId = currentChapterGauges[i].maxValueGameOverCardId;
			}

			if (newGaugeValues[i] < currentChapterGauges[i].minValue) {
				newGaugeValues[i] = currentChapterGauges[i].minValue;
				if (currentChapterGauges[i].minValueGameOverCardId)
					extremeGaugeGameOverCardId = currentChapterGauges[i].minValueGameOverCardId;
			}
		});

		setGaugeValues(newGaugeValues);
		setGaugeModifierPreviews([0, 0, 0]);

		// Next Card
		let nextCardId = side === 1 ? cardData.actionRightParam : cardData.actionLeftParam;
		let cardAction = side === 1 ? cardData.actionRightType : cardData.actionLeftType;

		if (!_.isNull(extremeGaugeGameOverCardId) && extremeGaugeGameOverCardId !== undefined) {
			nextCardId = extremeGaugeGameOverCardId;
			cardAction = CardData.ACTION_TYPE_SHOW_CARD;
		}

		const randomEventCard = _.sample(
			_.filter(currentChapterCards, function (card: CardData) {
				return (
					card.type === CardData.CARD_TYPE_EVENT &&
					card.sectionId === currentSection.sectionId &&
					!_.contains(processedEventCardsId, card.id)
				);
			})
		) as CardData;

		if (
			cardAction === CardData.ACTION_TYPE_SHOW_RANDOM_EVENT_CARD &&
			(processedEventCardsId.length >= currentCumulatedMaxEventCard ||
				_.isUndefined(randomEventCard))
		) {
			const nextSectionIndex = currentSectionIndex + 1;
			setCurrentSectionIndex(nextSectionIndex);
			cardAction = CardData.ACTION_TYPE_SHOW_CARD;
			nextCardId = currentChapterSections[nextSectionIndex].introCardId;
		}

		switch (cardAction) {
			case CardData.ACTION_TYPE_SHOW_CARD: {
				setCurrentCard(_.findWhere(allCards, { id: nextCardId }) as CardData);
				playGameDialogueSfx();
				break;
			}
			case CardData.ACTION_TYPE_SHOW_RANDOM_EVENT_CARD: {
				setCurrentCard(randomEventCard);
				playGameDialogueSfx();
				break;
			}
			case CardData.ACTION_TYPE_END_GAME: {
				MissionContextValues.registerChapterDone?.(currentChapter?.id || '', score);
				playGameMissionCompleteSfx();
				setShowScore(true);
				break;
			}
			case CardData.ACTION_TYPE_GAME_OVER: {
				setIsGameOver(true);
				break;
			}
		}
	};

	const onCardDragMove = (isInDropCondition: boolean, cardData: CardData, side: number) => {
		let newVal = [0, 0, 0];

		if (isInDropCondition) {
			newVal =
				side === 1
					? [
							cardData.actionRightGauge1 || 0,
							cardData.actionRightGauge2 || 0,
							cardData.actionRightGauge3 || 0
					  ]
					: [
							cardData.actionLeftGauge1 || 0,
							cardData.actionLeftGauge2 || 0,
							cardData.actionLeftGauge3 || 0
					  ];
		}

		if (gaugeModifierPreviews.join(',') !== newVal.join(',')) {
			setGaugeModifierPreviews(newVal);
		}
	};

	return (
		<FixedPage noPadding={true}>
			<AnimatedBackground state={0} zIndex={0} forceVisibility />

			{/* <Header></Header> */}

			<Grid
				container
				width={'100%'}
				height={'calc(100% - 15%)'}
				flex={1}
				direction={'column'}
				justifyContent={'space-between'}
				alignItems={'center'}
				alignContent={'center'}
				position={'relative'}
			>
				<Grid container spacing={2} columns={currentChapterGauges.length} width="95%">
					{currentChapterGauges.map((gaugeData, i) => (
						<Grid xs={1} key={i}>
							<Gauge
								gaugeData={gaugeData}
								value={gaugeValues[i]}
								modifierPreview={gaugeModifierPreviews[i]}
							/>
						</Grid>
					))}
				</Grid>
				<Box height={'5%'} />
				{<NarrativeContent card={currentCard || new CardData()} />}

				<Box flexGrow={1} />

				<CardContainer
					visible={currentCard !== undefined}
					key={currentCard?.id || '0'}
					cardData={currentCard || new CardData()}
					onSideChosen={onCardSideChosen}
					onCardDragMove={onCardDragMove}
				/>

				{showScore && <ScoreBox score={score} />}

				{isGameOver && <GameOverBox />}

				{!_.isUndefined(totalNbEventCardsToProcess) && totalNbEventCardsToProcess > 0 && (
					<GameProgression
						progress={processedEventCardsId.length / totalNbEventCardsToProcess}
					/>
				)}

				<SwipeAnimation></SwipeAnimation>
			</Grid>

			<Navbar></Navbar>
		</FixedPage>
	);
}
