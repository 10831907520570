import { useContext, useEffect } from 'react';
import { ScrollablePage } from '../../components/page/ScrollablePage';
import { UIContext } from '../../contexts/UIContext';

export const EndingSummaryPage = () => {
	const UIContextValues = useContext(UIContext);
	useEffect(() => {
		UIContextValues.setAsCompleteOverlayCompletion('endingSummarySpeech');
		UIContextValues.setAsCompleteOverlayCompletion('endingSummaryVideo');
		UIContextValues.setLeaderboardInteractive(false);
		UIContextValues.setLeaderboardVisibility(true);
	}, []);
	return (
		<>
			<ScrollablePage>
				<span></span>
			</ScrollablePage>
		</>
	);
};
