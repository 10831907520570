import { GSheetEntity } from './GSheetEntity';

export class SectionData extends GSheetEntity {
	chapterId = '';
	sectionId = '';
	introCardId = '';
	maxEventCards = 0;

	static override GSheetPageId = 'Sections';

	constructor() {
		super();
	}
}
