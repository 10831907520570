import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AvatarColorList } from '../../assets/MediaComponents';
import windowContainer from '../../assets/ui/window.svg';
import { GlobalButton } from '../../components/buttons/GlobalButton';
import { Header } from '../../components/page/Header';
import { ScrollablePage } from '../../components/page/ScrollablePage';
import { AccessorySelection } from '../../components/profile/AccessorySelection';
import { ColorSelection } from '../../components/profile/ColorSelection';
import { CustomAvatar } from '../../components/profile/CustomAvatar';
import { AvatarAccessory } from '../../contexts/Contexts.types';
import { UIContext } from '../../contexts/UIContext';
import { UserContext } from '../../contexts/UserContext';

export const ProfileAvatarCreationPage = () => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const UIContextValues = React.useContext(UIContext);

	const [currentAvatarColor, setCurrentAvatarColor] = React.useState(AvatarColorList[0]);
	const [currentAvatarAccessory, setCurrentAvatarAccessory] = React.useState<AvatarAccessory>(
		AvatarAccessory.CAPE
	);

	const UserContextValues = React.useContext(UserContext);

	useEffect(() => {
		UIContextValues.setHeaderInteractive(false);
	}, []);

	return (
		<>
			<Header title={t('profile_avatar_header_title')}></Header>
			<ScrollablePage>
				<Grid
					container
					direction="column"
					justifyContent="center"
					alignContent="center"
					alignItems="stretch"
					flexWrap={'nowrap'}
				>
					<Grid item xs={12}>
						<Typography
							sx={{ fontWeight: 'bolder', textAlign: 'center', paddingTop: '2vh' }}
							variant="body1"
						>
							{t('profile_avatar_text_intro_1')}{' '}
						</Typography>
						<Typography
							sx={{ fontWeight: 'bolder', textAlign: 'center' }}
							variant="body1"
						>
							{t('profile_avatar_text_intro_2')}{' '}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<CustomAvatar
							avatarInfos={{
								...UserContextValues.myProfile,
								avatar: {
									color: currentAvatarColor,
									accessoryName: currentAvatarAccessory
								}
							}}
						></CustomAvatar>
					</Grid>
					<Grid item xs={12} sx={styles.containerSelection}>
						<Grid item xs={12}>
							<ColorSelection
								oncolorchange={(c) => {
									setCurrentAvatarColor(c);
								}}
								label={t('profile_avatar_subtitle_color')}
							/>
						</Grid>

						<Grid item xs={12}>
							<AccessorySelection
								label={t('profile_avatar_subtitle_accessory')}
								onaccessorychange={(a) => {
									setCurrentAvatarAccessory(a);
								}}
							/>
						</Grid>
					</Grid>

					<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
						<GlobalButton
							sx={styles.button}
							onClick={() => {
								UserContextValues.setMyAvatar?.({
									color: currentAvatarColor,
									accessoryName: currentAvatarAccessory
								});
								navigate('/profile/BIO', { replace: true });
							}}
						>
							{t('profile_BIO_button')}
						</GlobalButton>
					</Grid>
				</Grid>
			</ScrollablePage>
		</>
	);
};

const styles = {
	containerSelection: {
		position: 'relative',
		height: '100%',
		width: '100%',
		padding: '3vh',
		borderImageSource: `url(${windowContainer})`,
		borderImageSlice: '37 37 37 37 fill',
		borderImageWidth: '25px 25px 25px 25px',
		borderImageOutset: '0px 0px 0px 0px',
		borderImageRepeat: 'stretch stretch'
	},
	button: {
		width: '50%',
		backgroundColor: 'primary.main',
		color: 'common.white',
		margin: {
			xs: '2vh',
			sm: '3em',
			md: '3em'
		}
	}
};
