import { Box } from '@mui/material';
import * as React from 'react';
import SpaceShipModel from '../../assets/elements2D/spaceshipBlue.svg';
import { UIContext } from '../../contexts/UIContext';
import { UserContext } from '../../contexts/UserContext';
import { SpaceShipProps } from './SpaceShip';

export const MySpaceShip = (props: SpaceShipProps) => {
	// eslint-disable-next-line
	const [_open, _setOpen] = React.useState(false);
	// eslint-disable-next-line
	const [_progression, _setProgression] = React.useState<number>(0);

	const UIContextValues = React.useContext(UIContext);

	const UserContextValues = React.useContext(UserContext);

	React.useEffect(() => {
		// calculate progression percentage for each spaceship
		const calculatedPercent = (props.team.score / UserContextValues.MAX_TEAM_SCORE) * 100;
		setTimeout(() => {
			_setProgression(calculatedPercent);
		}, 200 * props.animationdelay);
	}, [props.team]);

	return (
		<Box
			className="cursorPointer"
			sx={styles.spaceShipContainer}
			key={`spaceship_${props.index & Date.now()}`}
			onClick={() => {
				UIContextValues?.setLeaderboardVisibility!(true);
			}}
		>
			<Box
				id={'my-spaceship'}
				className="spaceShipBounce"
				component="div"
				sx={styles.spaceShip}
			>
				<Box className="spaceShipBlur" sx={styles.blurMask}></Box>
			</Box>
			<Box
				component="div"
				sx={{ ...styles.progressionTrail, height: `${_progression}%` }}
			></Box>
		</Box>
	);
};

const styles = {
	blurMask: {
		height: '100%',
		width: '100%',
		overflow: 'hidden',
		maskImage: `url(${SpaceShipModel})`,
		maskRepeat: 'no-repeat',
		position: 'sticky',
		maskPosition: 'center'
	},

	spaceShipContainer: {
		width: '60px',
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'center',
		alignContent: 'flex-end'
	},
	spaceShip: {
		position: 'sticky',
		height: '4.5em',
		width: '4.5em',
		backgroundImage: `url(${SpaceShipModel})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		transform: 'translateY(100%)',
		overflow: 'hidden',
		transition: 'filter 0.2s',
		'&:hover': {
			filter: 'drop-shadow(0px 0 1px rgb(75 156 255 / 0.2)) drop-shadow(-0px 0 2px rgb(75 156 255 / 0.2)) drop-shadow(0 0px 2px rgb(75 156 255 / 0.2)) drop-shadow(0 -0px 2px rgb(75 156 255 / 0.7))'
		}
	},
	progressionTrail: {
		transition: 'height 1s ease-in-out',

		height: '0%',
		minHeight: '20vh',
		maxHeight: '100%',
		backgroundColor: 'cyan',
		width: '5px',
		background: 'linear-gradient(#4B9CFF 30%, #FFFF,transparent)',
		outline: 'none',
		borderColor: '#4B9CFF',
		boxShadow: '0 0 10px #4B9CFF',
		borderRadius: '100%'
	}
};
