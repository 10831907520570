import Typography, { TypographyProps } from '@mui/material/Typography';

const LinkTypography = (props: TypographyProps) => {
	const { children, ...rest } = props;

	const textWithLinks = children
		?.toString()
		.split(/(https?:\/\/[^\s]+)/)
		.map((part, index) => {
			if (part.match(/https?:\/\/[^\s]+/)) {
				return (
					<a
						key={index}
						href={part}
						target="_blank"
						rel="noopener noreferrer"
						style={{
							fontFamily: 'AvenirNextLTPro',
							textDecoration: 'italic',
							color: '#FFFF',
							textAlign: 'justify',
							fontSize: '1em',
							whiteSpace: 'pre-wrap'
						}}
					>
						{part}
					</a>
				);
			}
			return part;
		});

	return <Typography {...rest}>{textWithLinks}</Typography>;
};

export default LinkTypography;
