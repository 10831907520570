import { Avatar, AvatarAccessory, BIO, Team, User, UserHistory } from '../contexts/Contexts.types';

import DefaultTeamLogo from '../assets/elements2D/DefaultTeamLogo.svg';
import FakeLogo from '../assets/profil/cristal.svg';

const myName = 'My first name';
const FakeDataAvatar: Avatar = {
	color: 'red',
	accessoryName: AvatarAccessory.CAPE
};

const FakeDataBIO: BIO = {
	motto: 'Carpe Diem',
	amazing: 'Swim across the English Channel',
	trust: 'No one can resist my smile',
	improve: "I talk to much. Don't hesitate to tell me"
};

const FakeDataOthersHistory: UserHistory = {} as UserHistory;

export const FakeDataMyHistory: UserHistory = {} as UserHistory;

export const FakeDataMyTeam: Team = {
	id: 0,
	name: 'My team',
	score: 50001,
	rank: 8,
	fileName: undefined,
	filePath: undefined,
	fileType: undefined,
	scanStatus: undefined,
	uploadDate: undefined,
	uploadedBy: undefined,
	base64: DefaultTeamLogo,
	members: [
		{
			id: 11,
			name: 'Other first name 11',
			avatar: { ...FakeDataAvatar },
			BIO: { ...FakeDataBIO },
			score: 250463,
			rank: 8,
			teamId: 0,
			history: FakeDataOthersHistory,
			homeFlag: false,
			introductionFlag: false,
			profileFlag: false,
			challengesFlag: false,
			microlearningFlag: false,
			missionsFlag: false,
			practiceFlag: false
		},
		{
			id: 12,
			name: 'Other first name 12',
			avatar: { ...FakeDataAvatar },
			BIO: { ...FakeDataBIO },
			score: 250463,
			rank: 8,
			teamId: 0,
			history: FakeDataOthersHistory,
			homeFlag: false,
			introductionFlag: false,
			profileFlag: false,
			challengesFlag: false,
			microlearningFlag: false,
			missionsFlag: false,
			practiceFlag: false
		},
		{
			id: 13,
			name: 'Other first name 13',
			avatar: { ...FakeDataAvatar },
			BIO: { ...FakeDataBIO },
			score: 250463,
			rank: 8,
			teamId: 0,
			history: FakeDataOthersHistory,
			homeFlag: false,
			introductionFlag: false,
			profileFlag: false,
			challengesFlag: false,
			microlearningFlag: false,
			missionsFlag: false,
			practiceFlag: false
		},
		{
			id: 14,
			name: 'Other first name 14',
			avatar: { ...FakeDataAvatar },
			BIO: { ...FakeDataBIO },
			score: 250463,
			rank: 8,
			teamId: 0,
			history: FakeDataOthersHistory,
			homeFlag: false,
			introductionFlag: false,
			profileFlag: false,
			challengesFlag: false,
			microlearningFlag: false,
			missionsFlag: false,
			practiceFlag: false
		}
	]
};

const FakeDataTeamMembers: User[] = GenerateRandomUsers(4, 12);

const randomTeamId = randomId();
export const FakeDataOtherTeam: Team = {
	id: randomTeamId,
	name: `Other team ${randomTeamId}`,
	score: randomTeamId * 10,
	rank: 10,
	fileName: FakeLogo,
	filePath: undefined,
	fileType: undefined,
	scanStatus: undefined,
	uploadDate: undefined,
	uploadedBy: undefined,
	members: FakeDataTeamMembers,
	base64: DefaultTeamLogo
};

export const FakeDataMyUser: User = {
	id: 0,
	name: myName,
	avatar: { ...FakeDataAvatar },
	BIO: { ...FakeDataBIO },
	score: 250463,
	rank: 8,
	teamId: 0,
	history: FakeDataMyHistory,
	homeFlag: false,
	introductionFlag: false,
	profileFlag: false,
	challengesFlag: false,
	microlearningFlag: false,
	missionsFlag: false,
	practiceFlag: false
};

const randomUserId = randomId();
export const FakeDataOtherUser: User = {
	id: randomUserId,
	name: 'Other first name',
	avatar: { ...FakeDataAvatar },
	BIO: { ...FakeDataBIO },
	score: randomUserId * 10,
	rank: randomUserId,
	teamId: 0,
	history: FakeDataOthersHistory,
	homeFlag: false,
	introductionFlag: false,
	profileFlag: false,
	challengesFlag: false,
	microlearningFlag: false,
	missionsFlag: false,
	practiceFlag: false
};

export function GenerateRandomUsers(count: number = 10, startIndex: number = 1): User[] {
	let users: User[] = [];

	for (let i = startIndex; i < count; i++) {
		users.push({
			id: i,
			name: `Other first name ${i}`,
			avatar: FakeDataAvatar,
			BIO: {
				motto: 'Carpe Diem',
				amazing: 'Swim across the English Channel',
				trust: 'No one can resist my smile',
				improve: "I talk to much. Don't hesitate to tell me"
			},
			score: (10 - i) * 100000,
			rank: i,
			teamId: 0,
			history: FakeDataOthersHistory,
			homeFlag: false,
			introductionFlag: false,
			profileFlag: false,
			challengesFlag: false,
			microlearningFlag: false,
			missionsFlag: false,
			practiceFlag: false
		});
	}

	return users;
}

// function random number id
function randomId() {
	return Math.ceil(Math.random() * 10);
}

export function GenerateRandomTeams(): Team[] {
	let teams: Team[] = [];

	for (let i = 1; i < 11; i++) {
		teams.push({
			id: i,
			name: `Other Team name ${i}`,
			score: Math.floor(Math.random() * 50000),
			rank: i,
			fileName: undefined,
			filePath: undefined,
			fileType: undefined,
			scanStatus: undefined,
			uploadDate: undefined,
			uploadedBy: undefined,
			members: FakeDataTeamMembers,
			base64: DefaultTeamLogo
		});
	}

	return teams;
}
