import { GaugeData } from '../datas/GaugeData';
import { useEffect, useState } from 'react';
import _ from 'underscore';
import { GaugeProps } from './GameComponents.model';
import chroma from 'chroma-js';
import Box from '@mui/material/Box';
import sliceBorderImage from '../assets/gauge-border.svg';
import bgImage from '../assets/gauge-bg.svg';
import gaugeArrowImage from '../assets/gauge-arrow.svg';
import gaugeImpactImage from '../assets/gauge-impact.svg';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Gauge({ gaugeData, value, modifierPreview }: GaugeProps): JSX.Element {
	const [modifierImportance, setModifierImportance] = useState(0);
	// eslint-disable-next-line
	const [gaugeScore, setGaugeScore] = useState(0);
	// eslint-disable-next-line
	const [valueColor, setValueColor] = useState('#fff');
	const [gaugeCursorPosition, setGaugeCursorPosition] = useState(0);
	const colorScale = chroma.scale(['#d74203', '#fe9d0f', '#fedc31', '#22ef30']);
	const smallHeight = useMediaQuery('(max-height:800px)');

	useEffect(() => {
		setGaugeScore(GaugeData.getScore(gaugeData, value));
		setValueColor(getColorFromValue(value));
		setGaugeCursorPosition(GaugeData.getScoreCursorPosition(gaugeData, value));
	}, [value]);

	useEffect(() => {
		setModifierImportance(
			_.isNumber(modifierPreview) && modifierPreview != 0
				? Math.abs(modifierPreview) > 5
					? 1
					: 0.5
				: 0
		);
	}, [modifierPreview]);

	const getColorFromValue = function (val: number) {
		return colorScale(GaugeData.getScore(gaugeData, val)).hex();
	};

	return (
		<Box sx={{ ...style.main, ...{ fontSize: smallHeight ? '8px' : '10px' } }}>
			<Box sx={style.bg}></Box>
			<Box sx={style.slice}></Box>
			<Box sx={style.content}>
				<Box
					sx={{ ...style.gaugeImpact, ...{ transform: `scale(${modifierImportance})` } }}
				>
					<img src={gaugeImpactImage} />
				</Box>

				<Box sx={style.gaugeName}>
					<Typography>{gaugeData.name}</Typography>
				</Box>

				<Box sx={style.arrow} style={{ left: gaugeCursorPosition * 100 + '%' }}>
					<img src={gaugeArrowImage} />
				</Box>

				<Box sx={style.line}></Box>

				<Box sx={style.gaugeColors}>
					{[...Array(5)].map((x, i) => (
						<Box
							sx={style.color}
							key={i}
							style={{
								backgroundColor: getColorFromValue(
									gaugeData.minValue +
										(gaugeData.maxValue - gaugeData.minValue) * (i / 4)
								)
							}}
						></Box>
					))}
				</Box>
			</Box>
		</Box>
	);
}

const borderWidth = 1.6;

const style = {
	main: {
		width: '100%',
		position: 'relative',
		fontSize: '10px'
	},
	bg: {
		backgroundImage: `url(${bgImage})`,
		backgroundSize: '3em',
		width: `calc(100% - ${borderWidth}em)`,
		left: `${borderWidth / 2}em`,
		height: `calc(100% - ${borderWidth}em)`,
		top: `${borderWidth / 2}em`,
		position: 'absolute'
	},
	slice: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		borderImageSlice: '12% 10% 13% 10% fill',
		borderImageWidth: `${borderWidth}em`,
		borderImageOutset: 0,
		borderImageRepeat: 'stretch stretch',
		borderImageSource: `url(${sliceBorderImage})`
	},
	content: {
		position: 'relative',
		padding: '0.7em 1.4em 1.4em 1.4em',
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		alignContent: 'center',
		alignItems: 'flex-start',
		justifyContent: 'space-between'
	},
	gaugeName: {
		width: '100%',
		height: '3.2em',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		'& > p': {
			color: '#fff',
			lineHeight: 1.1,
			textTransform: 'uppercase',
			fontSize: '1.2em',
			fontWeight: 'bold',
			textAlign: 'center'
		}
	},
	arrow: {
		position: 'relative',
		height: '0.2em',
		width: '1.6em',
		transform: 'translateX(-50%)',
		display: 'inline-block',
		transition: 'all 0.4s ease'
	},
	line: {
		borderBottom: '0.2em dotted #ffffffa6',
		width: '100%',
		margin: '0.2em 0 0.3em 0'
	},
	gaugeColors: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		width: '100%',
		borderRadius: '2px 2px 6px 6px',
		overflow: 'hidden'
	},
	color: {
		height: '1em',
		flex: 1
	},
	gaugeImpact: {
		width: '100%',
		height: '2em',
		textAlign: 'center',
		transform: 'scale(1)',
		transition: 'transform 0.1s ease',
		position: 'relative',
		'& > img': {
			width: '2em',
			height: '1em',
			position: 'relative',
			top: '0.5em'
		}
	}
};
