import { Fade, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UIContext } from '../contexts/UIContext';
import { GlobalButton } from './buttons/GlobalButton';
import { BorderColorEnum, ContentDisplayEnum, CornerContainer } from './containers/CornerContainer';
import { FixedPage } from './page/FixedPage';

// my-spaceship
// header
// my-team-profile
export const TutorialOverlay = () => {
	const { t } = useTranslation();
	const UIContextValues = React.useContext(UIContext);

	useEffect(() => {}, [
		UIContextValues.tutorialOverlayVisibility,
		UIContextValues.tutorialOverlayData
	]);

	return (
		<Fade in={UIContextValues.tutorialOverlayVisibility}>
			<Box sx={styles.container}>
				<FixedPage>
					<Box sx={styles.background}></Box>

					<CornerContainer
						borderColor={BorderColorEnum.tutorial}
						contentdisplay={ContentDisplayEnum.fixed}
						sx={styles.cornerContainer}
					>
						<Typography color={'white'} variant={'h4'}>
							{UIContextValues.tutorialOverlayData?.title}
						</Typography>
						<Typography variant={'body1'} sx={{ textAlign: 'center' }}>
							{UIContextValues.tutorialOverlayData?.description}
						</Typography>
						<GlobalButton
							onClick={() => {
								UIContextValues.nextOverlaysEvents?.();
							}}
						>
							{t('tutorial_button')}
						</GlobalButton>
					</CornerContainer>
				</FixedPage>
			</Box>
		</Fade>
	);
};

const styles = {
	container: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		overflow: 'hidden',
		top: 0,
		left: 0,
		zIndex: 11
	},
	background: {
		position: 'absolute',
		top: 0,
		background: 'rgba(0, 0, 0, 0.8)',
		height: '100%',
		width: '100%',

		objectFit: 'cover'
	},

	cornerContainer: {
		marginTop: '5vh',
		display: 'flex',
		padding: {
			xs: '3vh',
			sm: '2em'
		},
		justifyContent: 'center',
		alignItems: 'center',
		// width: '100%',
		// backgroundColor: 'purple',
		// flexDirection: 'column',
		gap: '1em'
	}
};

export const toggleHighlighting = (id: string, value?: boolean) => {
	const element = document.getElementById(id);
	if (element) {
		if (value !== undefined) {
			if (value) {
				element.classList.add('highlighted');
				duplicateElement(id);
			} else {
				element.classList.remove('highlighted');
				element.style.visibility = 'visible';

				const copy = document.getElementById(id + '-copy');
				if (copy) {
					copy.remove();
				}
			}
			return;
		}

		element.classList.toggle('highlighted');
	}
};

function duplicateElement(elementId: string) {
	const copyElement = document.getElementById(elementId + '-copy');
	if (copyElement) {
		return;
	}

	if (!elementId) {
		return;
	}
	// Get the element to be duplicated
	const element = document.getElementById(elementId);

	if (!element) {
		return;
	}
	// Get the parent element of the element to be duplicated
	const parent = element.parentNode;
	if (!parent) {
		return;
	}
	// Get the absolute position of the element
	const rect = element.getBoundingClientRect();

	// Create a copy of the element
	const copy = element.cloneNode(true) as HTMLElement;

	copy.style.position = 'absolute';

	copy.style.width = rect.width + 'px';
	copy.style.height = rect.height + 'px';

	copy.style.left = getOffset(element).left + 'px';
	copy.style.top = getOffset(element).top + 'px';
	copy.style.padding = '0px';

	var computedFontSize = window.getComputedStyle(element).fontSize;
	copy.style.fontSize = computedFontSize;

	copy.id = elementId + '-copy';

	// Add the copy to the parent element
	document.body.appendChild(copy);

	element.style.visibility = 'hidden';
}

function getOffset(el: any) {
	var _x = 0;
	var _y = 0;
	while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
		_x += el.offsetLeft - el.scrollLeft;
		_y += el.offsetTop - el.scrollTop;
		el = el.offsetParent;
	}
	return { top: _y, left: _x };
}
