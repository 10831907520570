import { createTheme } from '@mui/material';

export const theme = createTheme({
	palette: {
		common: {
			black: '#282828',
			white: '#FFFF'
		},
		primary: {
			main: '#329E51',
			dark: 'rgba(50, 158, 81, 0.6)'
		},
		secondary: {
			main: '#FFFF'
		},
		error: {
			main: '#FE757D',
			dark: '#F79637'
		},
		text: {
			primary: '#FFFF',
			secondary: '#FFFF',
			disabled: '#4A4A4A'
		}
	},
	typography: {
		fontFamily: 'AvenirNextLTPro',

		body1: {
			fontFamily: 'AvenirNextLTPro',

			color: '#FFFF',
			textAlign: 'justify',
			fontSize: '1em',
			whiteSpace: 'pre-wrap'
		},
		body2: {
			color: '#282828',
			fontFamily: 'AvenirNextLTPro'
		},
		h1: {
			fontFamily: 'RationalText',
			wordBreak: 'break-word',
			fontWeight: '900',
			fontSize: '1.5em',
			textTransform: 'uppercase'
		},
		h2: {
			fontFamily: 'RationalText',
			color: '#FE757D',
			textAlign: 'start',
			textTransform: 'uppercase',
			fontSize: '1.05em',
			wordBreak: 'break-word',
			fontWeight: '600'
		},
		h3: {
			fontFamily: 'RationalText',
			color: '#FFFF',
			fontWeight: '300',
			textAlign: 'start',
			textTransform: 'capitalize',
			fontSize: '1em',
			wordBreak: 'break-word'
		},
		h4: {
			fontFamily: 'RationalText',
			color: '#282828',
			fontWeight: '300',
			textAlign: 'center',
			textTransform: 'uppercase',
			fontSize: '1.2em',
			wordBreak: 'break-word'
		},
		h5: {
			fontFamily: 'RationalText',
			color: '#FFFF',
			fontWeight: '300',
			textAlign: 'center',
			textTransform: 'uppercase',
			fontSize: '1.1em',
			wordBreak: 'break-word'
		},
		h6: {
			fontFamily: 'RationalText',
			color: '#FFFF',
			fontWeight: '900',
			textAlign: 'center',
			textTransform: 'uppercase',
			fontSize: '1.2em',
			wordBreak: 'break-word'
		}
	}
});
