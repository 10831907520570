import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Cristal from '../../assets/profil/green_cristal.svg';

interface HistoryInputProps {
	point: number;
	historyDetails: string;
}
export const HistoryInput = (props: HistoryInputProps) => {
	return (
		<Box sx={styles.HistoryBox}>
			<Box component="div" sx={styles.cristal} />
			<Typography>+{props.point}</Typography>
			<Typography>{props.historyDetails}</Typography>
		</Box>
	);
};
const styles = {
	HistoryBox: {
		// marginBottom: '1em',
		display: 'flex',
		width: '100%',
		gap: '1em',
		marginBottom: '1em'
	},
	cristal: {
		height: '20px',
		width: '20px',
		backgroundImage: `url(${Cristal})`,
		backgroundRepeat: 'no-repeat'
	}
};
