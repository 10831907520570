import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { useContext } from 'react';
import { useNavigate } from 'react-router';
import useSound from 'use-sound';
import DefaultThumbnail from '../assets/elements2D/thumbnail_placeholder.jpg';
import clickButtonSfx from '../assets/sounds/button-click.mp3';
import arrowImage from '../assets/ui/learning-arrow.svg';
import windowBackground from '../assets/ui/window_card.svg';
import windowBackgroundHover from '../assets/ui/window_card_hover.svg';
import { MicroLearning } from '../contexts/Contexts.types';
import { ParametersContext } from '../contexts/ParametersContext';

interface LearningCardProps {
	microLearning: MicroLearning;
}

export const MicroLearningCard = (props: LearningCardProps) => {
	const navigate = useNavigate();

	const ParametersContextValues = useContext(ParametersContext);

	const [buttonClickSfx] = useSound(clickButtonSfx, {
		volume: 0.8,
		soundEnabled: ParametersContextValues?.soundEnabled
	});

	return (
		<Box
			sx={styles.card}
			onClick={() => {
				buttonClickSfx();
				navigate(`/micro-learning/${props.microLearning.id}`);
			}}
		>
			<Box sx={styles.imageBox}>
				<img
					src={props.microLearning.base64 ?? DefaultThumbnail}
					alt={'Image Link'}
					style={styles.imageSizing}
				/>
				<Chip sx={styles.chip} size="small" label={props.microLearning.type} />
			</Box>
			<Box sx={styles.cardContent}>
				<Stack>
					<Typography variant="h3" sx={styles.title}>
						{props.microLearning.title}
					</Typography>
					<Typography sx={styles.text}>{props.microLearning.shortDescription}</Typography>
				</Stack>
			</Box>

			<Box>
				<Box sx={styles.arrow}>
					<img className="arrow" src={arrowImage} />
				</Box>
			</Box>
		</Box>
	);
};

const styles = {
	card: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderImageSource: `url(${windowBackground})`,
		borderImageSlice: '21% 21% 21% 21% fill',
		borderImageWidth: '25px',
		borderImageOutset: '0px',
		borderImageRepeat: 'stretch stretch',
		'&:hover': {
			borderImageSource: `url(${windowBackgroundHover})`,
			borderImageSlice: '21% 21% 21% 21% fill',
			borderImageWidth: '25px',
			borderImageOutset: '0px',
			borderImageRepeat: 'stretch stretch',
			cursor: 'pointer',
			'img.arrow': {
				transform: 'translateX(0.4em)'
			}
		},
		width: '100%',
		minHeight: '120px'
	},
	chip: {
		backgroundColor: 'error.main',
		padding: '0.5em',
		marginBottom: '1em',
		fontWeight: 'bold',
		alignSelf: 'center'
	},
	cardContent: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: '10px',
		width: '100%',
		alignSelf: 'start'
	},
	imageBox: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginLeft: '10px',
		maxWidth: '25%'
	},
	imageSizing: {
		width: '100%',
		height: '100%',
		cursor: 'pointer',
		paddingTop: '10px',
		paddingBottom: '10px'
	},
	title: {
		marginTop: '1em',

		fontSize: {
			xs: '1em',
			sm: '1.1em'
		},
		marginBottom: {
			xs: '0.3em',
			sm: '0.5em'
		}
	},
	text: {
		fontSize: {
			xs: '0.7em',
			sm: '0.9em'
		},
		textAlign: 'left',
		display: '-webkit-box',
		WebkitLineClamp: 2,
		WebkitBoxOrient: 'vertical',
		overflow: 'hidden'
	},
	icon: {
		color: 'common.white',
		transform: {
			xs: 'scale(1)',
			sm: 'scale(1.5)'
		}
	},
	arrow: {
		width: '2em',
		marginLeft: '10px',

		'& > img': {
			width: '0.5em',
			transition: 'all 0.3s ease'
		}
	},
	button: {
		backgroundColor: 'transparent',
		boxShadow: 'none',
		'& hover': {
			backgroundColor: 'transparent'
		}
	}
};
