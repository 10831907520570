import { useDndMonitor, useDraggable } from '@dnd-kit/core';
import { Box } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { useContext, useEffect, useState } from 'react';
import _ from 'underscore';
import CardAction from './CardAction';
import CardNarrativeContent from './CardNarrativeContent';
import { CardProps } from './GameComponents.model';

import useSound from 'use-sound';
import swipeConditionSfx from '../../assets/sounds/game-in-swipe-condition.mp3';
import swipeSfx from '../../assets/sounds/game-swipe.mp3';
import { ParametersContext } from '../../contexts/ParametersContext';

export default function Card({ cardData, onSideChosen, onCardDragMove }: CardProps): JSX.Element {
	const ParametersContextValues = useContext(ParametersContext);

	const [isDragging, setIsDragging] = useState<boolean>(false);
	const [isInDropCondition, setIsInDropCondition] = useState<boolean>(false);
	const [currentSide, setCurrentSide] = useState<number>(0);
	const [sideChosen, setSideChosen] = useState<number>(0);
	const [displayed, setDisplayed] = useState<boolean>(false);
	const [cardSize, setCardSize] = useState<number>(300);
	// eslint-disable-next-line
	const [previousDelta, setPreviousDelta] = useState<number>(0);
	const { attributes, listeners, setNodeRef } = useDraggable({ id: 'draggable' });
	let { transform } = useDraggable({ id: 'draggable' });

	if (!transform) transform = { x: 0, y: 0, scaleX: 1, scaleY: 1 };

	const [playSwipeConditionSfx] = useSound(swipeConditionSfx, {
		volume: 0.1,
		soundEnabled: ParametersContextValues?.soundEnabled
	});
	const [playSwipeSfx] = useSound(swipeSfx, {
		volume: 0.3,
		soundEnabled: ParametersContextValues?.soundEnabled
	});

	const cardInteractionsStyle = {
		transformOrigin: 'center',
		transition: `transform ${
			isDragging ? 0 : 0.3
		}s ease, border 0.3s ease, box-shadow 0.1s ease, opacity 0.25s linear`,
		border: isInDropCondition ? '3px solid white' : '3px solid #4b9cff',
		boxShadow: isDragging
			? 'rgb(0 0 0 / 10%) 0px 8px 0px 3px'
			: 'rgb(0 0 0 / 10%) 0px 3px 0px 2px',
		transform: `translate3d(
      ${transform.x + sideChosen * 150}px, 
      ${transform.y - (isDragging ? 5 : 0) + (sideChosen != 0 ? 50 : 0)}px,
      0) 
      rotate(${transform.x / 10 + sideChosen * 20}deg)`,
		opacity: sideChosen != 0 ? 0 : 1
	};

	useEffect(() => {
		if (!transform) transform = { x: 0, y: 0, scaleX: 1, scaleY: 1 };
		setDisplayed(true);
		window.addEventListener('resize', onResize, false);
		onResize();
	}, []);

	const onResize = () => {
		setCardSize(Math.min(window.innerHeight / 2.9, 350));
	};

	useDndMonitor({
		onDragStart() {
			setIsDragging(true);
		},
		onDragMove(event: any) {
			const side: number = event.delta.x > 0 ? 1 : -1;
			setCurrentSide(side);
			const isInCondition = Math.abs(event.delta.x) > 20;
			setIsInDropCondition(isInCondition);
			onCardDragMove(isInCondition, cardData, side);
			setPreviousDelta(event.delta.x);
		},
		onDragEnd() {
			setIsDragging(false);
			if (isInDropCondition) {
				setSideChosen(currentSide);
				_.delay(onSideChosen, 800, cardData, currentSide);
				playSwipeSfx();
			}
		}
	});

	useEffect(() => {
		if (isInDropCondition) playSwipeConditionSfx();
	}, [isInDropCondition]);

	return (
		<Zoom in={displayed}>
			<Box>
				<div
					ref={setNodeRef}
					style={{
						...cardInteractionsStyle,
						...style.card,
						...{
							width: cardSize,
							height: cardSize
						}
					}}
					{...listeners}
					{...attributes}
				>
					<Box
						sx={{
							...style.cardImage,
							...{
								backgroundImage: `url(${process.env.PUBLIC_URL}/cardImages/${cardData.image}.png)`
							}
						}}
					></Box>

					{cardData.actionRightText && transform! && (
						<CardAction
							side={1}
							action={cardData.actionRightText}
							transformX={transform.x}
						/>
					)}

					{cardData.actionLeftText && transform! && (
						<CardAction
							side={-1}
							action={cardData.actionLeftText}
							transformX={transform.x}
						/>
					)}

					{!cardData.actionRightText && !cardData.actionLeftText && (
						<CardNarrativeContent content={cardData.mainText} />
					)}
				</div>
			</Box>
		</Zoom>
	);
}

const style = {
	card: {
		touchAction: 'none',
		cursor: 'grab'
	},
	cardImage: {
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		touchAction: 'none',
		PointerEvent: 'none',
		width: '100%',
		height: '100%',
		position: 'absolute',
		opacity: '.8',
		animation: 'flickering 0.1s infinite alternate'
	}
};
