import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FixedPage } from '../components/page/FixedPage';
import { GlobalContext } from '../contexts/GlobalContext';

export const IntroductionVideoPage = () => {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const [controls, setControls] = useState(true);

	const [errorMessage, setErrorMessage] = useState('');

	const videoRef = useRef<HTMLVideoElement>(null);
	const GlobalContextValues = useContext(GlobalContext);

	useEffect(() => {
		if (GlobalContextValues?.introductionVideoUrl) {
			videoRef.current?.play().catch(() => {
				setControls(true);
			});
		} else {
			setErrorMessage(t('introduction_video_error_not_found'));
			setTimeout(() => {
				handleVideoEnded();
			}, 1000);
		}
	}, []);

	const handleVideoEnded = () => {
		navigate('/profile/avatar', { replace: true });
	};

	return (
		<>
			<FixedPage noPadding={true}>
				<Box sx={styles.container}>
					{!errorMessage ? (
						<video
							style={styles.video}
							ref={videoRef}
							onError={handleVideoEnded}
							autoPlay
							controls={controls}
							onEnded={handleVideoEnded}
						>
							<source
								src={GlobalContextValues.introductionVideoUrl}
								type="video/mp4"
							/>
							{t('introduction_video_error_browser')}
						</video>
					) : (
						<Typography sx={styles.errorMessage} variant="body1">
							Loading video...
						</Typography>
					)}
					{errorMessage && (
						<Typography sx={styles.errorMessage} variant="body1">
							{errorMessage}
						</Typography>
					)}
				</Box>
			</FixedPage>
		</>
	);
};

const styles = {
	container: {
		height: '100%',
		width: '100%',
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	errorMessage: {
		textAlign: 'center',
		marginTop: '1em',
		color: 'error.main'
	},
	video: {
		backgroundColor: '#282828',

		top: 0,
		height: '100%',
		width: '100%'
	}
};
