import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Chip, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { useContext } from 'react';
import { useNavigate } from 'react-router';
import useSound from 'use-sound';
import CrystalEmptySvg from '../assets/profil/crystal_empty.svg';
import clickButtonSfx from '../assets/sounds/button-click.mp3';
import arrowImage from '../assets/ui/learning-arrow.svg';
import windowBackground from '../assets/ui/window_card.svg';
import windowBackgroundHover from '../assets/ui/window_card_hover.svg';
import { Challenge, ChallengeStatus } from '../contexts/Contexts.types';
import { ParametersContext } from '../contexts/ParametersContext';

interface ChallengeCardProps {
	challenge: Challenge;
}

export const ChallengeCard = (props: ChallengeCardProps) => {
	const navigate = useNavigate();
	const ParametersContextValues = useContext(ParametersContext);
	const [buttonClickSfx] = useSound(clickButtonSfx, {
		volume: 0.8,
		soundEnabled: ParametersContextValues?.soundEnabled
	});

	const onClick = () => {
		buttonClickSfx();

		switch (props.challenge.status) {
			case ChallengeStatus.UPCOMING:
				navigate(`/challenges/upcoming/${props?.challenge.id}`);
				break;

			case ChallengeStatus.SUBMITTED:
			case ChallengeStatus.AVAILABLE:
				navigate(`/challenges/available/${props?.challenge.id}`);
				break;

			default:
				console.warn('Challenge status not handled');
				break;
		}
	};

	return (
		<Grid
			container
			columns={12}
			direction="row"
			justifyContent="center"
			alignItems="center"
			sx={{
				...styles.card,
				pointerEvents:
					props.challenge.status === ChallengeStatus.SUBMITTED ? 'initial' : 'initial'
			}}
			onClick={onClick}
		>
			{/* Content */}
			<Grid item xs={10} sx={styles.content}>
				<Typography
					variant="h3"
					sx={{
						...styles.title,
						color:
							props.challenge.status === ChallengeStatus.SUBMITTED
								? 'primary.main'
								: 'text.primary'
					}}
				>
					{props.challenge.name}{' '}
				</Typography>

				<Grid item sx={{ ...styles.scoreText, marginBottom: '5px' }}>
					<Typography sx={{ color: 'text.primary' }}>
						{(props.challenge.score ?? 0) + '/' + props.challenge.maxPoints}
					</Typography>
					<Box sx={{ ...styles.icon, backgroundImage: `url(${CrystalEmptySvg})` }}></Box>
				</Grid>
				{/* Score & difficulty */}

				<Grid
					container
					columns={2}
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					textAlign="start"
				>
					{props.challenge.complexity !== 'notapplicable' && (
						<Grid item xs={1}>
							<Chip
								sx={styles.chipDifficulty}
								size="small"
								label={props.challenge.complexity}
							/>
						</Grid>
					)}
					<Grid item xs={1}>
						<Chip
							sx={styles.chipType}
							size="small"
							label={props.challenge.challengeType}
						/>
					</Grid>
				</Grid>
			</Grid>

			{/* Arrow / chekmark */}

			<Grid item xs={2}>
				<Box>
					{!(props.challenge.status === ChallengeStatus.SUBMITTED) && (
						<Fade in={true}>
							<Box sx={styles.arrow}>
								<img className="arrow" src={arrowImage} />
							</Box>
						</Fade>
					)}

					{props.challenge.status === ChallengeStatus.SUBMITTED && (
						<Fade in={true}>
							<Box sx={styles.pendingIcon}>
								<TaskAltIcon />
							</Box>
						</Fade>
					)}
				</Box>
			</Grid>
		</Grid>
	);
};

const styles = {
	card: {
		width: '100%',
		height: '6em',
		marginTop: '2vh',
		borderImageSource: `url(${windowBackground})`,
		borderImageSlice: '21% 21% 21% 21% fill',
		borderImageWidth: '25px',
		borderImageOutset: '0px',
		borderImageRepeat: 'stretch stretch',
		'&:hover': {
			borderImageSource: `url(${windowBackgroundHover})`,
			borderImageSlice: '21% 21% 21% 21% fill',
			borderImageWidth: '25px',
			borderImageOutset: '0px',
			borderImageRepeat: 'stretch stretch',
			cursor: 'pointer',
			'img.arrow': {
				transform: 'translateX(0.4em)'
			}
		}
	},
	content: {
		paddingLeft: '1em'
	},

	title: {
		fontSize: {
			xs: '0.9em',
			sm: '1em'
		},
		marginBottom: {
			xs: '0.5em',
			sm: '0.5em'
		}
	},
	icon: {
		backgroundImage: `url(${CrystalEmptySvg})`,
		backgroundCover: 'contain',
		height: '80%',
		width: '80%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'left'
		// backgroundColor: 'yellow'
	},
	scoreContainer: {
		width: '100%'
		// height: '4vh',
		// backgroundColor: 'purple'
	},
	scoreText: {
		fontWeight: 'bold',
		fontSize: {
			xs: '0.8em',
			sm: '1em'
		},
		// backgroundColor: 'green',
		height: '3vh',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		gap: '1vh'
	},
	chipType: {
		pointerEvents: 'none',
		backgroundColor: 'error.dark',
		color: 'white',
		padding: '0.5vh',
		fontWeight: 'bold',
		fontSize: {
			xs: '0.65em',
			sm: '0.7em'
		}
	},
	chipDifficulty: {
		pointerEvents: 'none',
		backgroundColor: 'error.main',
		color: 'white',
		padding: '0.5vh',
		fontWeight: 'bold',
		fontSize: {
			xs: '0.65em',
			sm: '0.7em'
		}
	},
	arrow: {
		width: '3em',

		'& > img': {
			width: '0.5em',
			transition: 'all 0.3s ease'
		}
	},
	pendingIcon: {
		color: 'primary.main',
		width: '3em'
	}
};
