import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarIconText } from '../components/CalendarIconText';
import { BorderColorEnum } from '../components/containers/CornerContainer';
import { CalendarContext } from '../contexts/CalendarContext';
import { CalendarEventItem, CalendarEventType } from '../contexts/Contexts.types';
import { PopupContainer } from './PopupContainer';

export const CalendarPopup = () => {
	const CalendarContextValues = useContext(CalendarContext);
	const { t } = useTranslation();

	return (
		<PopupContainer
			borderColor={BorderColorEnum.tutorial}
			title={t('calendar_title')}
			open={CalendarContextValues?.CalendarVisibility!}
			onClickCloseButton={() => {
				CalendarContextValues?.setCalendarVisibility!(false);
			}}
		>
			<Box sx={styles.Content}>
				<Typography variant="body1" sx={styles.introText}>
					{t('calendar_text')}
				</Typography>

				<TileAndEvents
					title={t('calendar_subtitle_learning')}
					events={CalendarContextValues?.upcomingEvents.LearningEvents}
				/>

				<TileAndEvents
					title={t('calendar_subtitle_challenges')}
					events={CalendarContextValues?.upcomingEvents.ChallengesEvents}
				/>

				<TileAndEvents
					title={t('calendar_subtitle_practice')}
					events={CalendarContextValues?.upcomingEvents.PracticesEvents}
				/>

				<TileAndEvents
					title={t('calendar_subtitle_mission')}
					events={CalendarContextValues?.upcomingEvents.MissionEvents}
				/>
			</Box>
		</PopupContainer>
	);
};

const styles = {
	Content: {
		width: '100%',
		maxHeight: '55vh',
		display: 'flex',
		flexDirection: 'column',
		gap: '1em'
	},
	introText: {
		textAlign: 'center'
	},
	event: {
		// marginBottom: '1em !important'
		// height: '100%',
		// display: 'flex',
		// flexDirection: 'row',
		// alignItems: 'center',
		// justifyContent: 'flex-start',
		// margin: '0 !important',
		// backgroundColor: 'red',
	},

	textEvent: {
		// backgroundColor: 'blue',
		// width: '80%',
		paddingLeft: '1em'
	},
	eventRow: {
		// display: 'flex',
		// flexDirection: 'row',
		// alignItems: 'center',
		// justifyContent: 'center',
		width: '100%',
		backgroundColor: 'purple'
	},
	iconEvent: {
		// backgroundColor: 'red',
	}
};

const TileAndEvents = (props: {
	title: string;
	events: { type: CalendarEventType; title: string }[];
}) => {
	if (!props.events || props.events.length === 0) return null;
	return (
		<>
			<Typography variant="h2" sx={styles.event}>
				{props.title}
			</Typography>
			{props.events?.map((el: CalendarEventItem) => (
				<CalendarIconText key={el.title} event={el} />
			))}
		</>
	);
};
