import React, { createContext, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { APIContextValue, APISession, CalendarUpcomingEvents } from '../Contexts.model';
import { Challenge, MicroLearning, Mission, Notification, Team, User } from '../Contexts.types';
import {
	APIGetAllChallenges,
	APIGetCalendar,
	APIGetHistory,
	APIGetMicroLearnings,
	APIGetMissions,
	APIGetNotifications,
	APIGetProfile,
	APIGetSession,
	APIGetTeam,
	APIUpdateProfile,
	APIgetLeaderboardTeams,
	APIgetLeaderboardUsers,
	UpdatingProfile
} from './APIFechers';

// const imageTestToUplaod = '../../assets/navbar/astro.png';

export const APIContext = createContext<APIContextValue>({
	currentSession: {} as APISession,
	setCurrentSession: () => {},
	teamsLeaderboard: [],
	myProfile: {} as User,
	myTeam: {} as Team,
	isAvatarSet: false,
	setIsAvatarSet: () => {},
	usersLeaderboard: [],
	allChallenges: [],
	missions: [],
	microLearnings: [],
	calendar: {} as CalendarUpcomingEvents,
	notificationList: [],
	getProfile: {} as () => Promise<User | undefined>,
	getTeam: {} as () => Promise<Team | undefined>,
	// eslint-disable-next-line
	updateMyProfile: {} as (profile: UpdatingProfile) => Promise<User | undefined>,
	getSession: {} as () => Promise<APISession | undefined>,
	getLeaderboardTeams: {} as () => Promise<Team[] | undefined>,
	getLeaderboardUsers: {} as () => Promise<User[] | undefined>,
	getAllChallenges: {} as () => Promise<Challenge[] | undefined>,
	getMissions: {} as () => Promise<Mission[] | undefined>,
	getMicroLearnings: {} as () => Promise<MicroLearning[] | undefined>,
	getCalendar: {} as () => Promise<CalendarUpcomingEvents | undefined>,
	// eslint-disable-next-line
	getNotifications: {} as (red: boolean, range?: string) => Promise<Notification[] | undefined>
});

interface APIProviderProps {
	children: React.ReactNode;
}

export const APIProvider: React.FC<APIProviderProps> = ({ children }) => {
	// eslint-disable-next-line
	const [currentSession, setCurrentSession] = useState<APISession>({} as APISession);
	const [teamsLeaderboard, setTeamsLeaderboard] = useState<Team[]>([]);
	const [usersLeaderboard, setUsersLeaderboard] = useState<User[]>([]);
	const [myProfile, setMyProfile] = useState<User>({} as User);
	const [isAvatarSet, setIsAvatarSet] = useState<boolean>(false);
	const [myTeam, setMyTeam] = useState<Team>({} as Team);
	const [allChallenges, setAllChallenges] = useState<Challenge[]>([]);
	const [missions, setMissions] = useState<Mission[]>([]);
	const [microLearnings, setMicroLearnings] = useState<MicroLearning[]>([]);
	const [calendar, setCalendar] = useState<CalendarUpcomingEvents>({} as CalendarUpcomingEvents);
	const [notificationList, setNotificationList] = useState<Notification[]>([]);

	useEffect(() => {}, []);

	const getSession = useCallback(async (userId: number) => {
		const res: { currentSession: APISession; success: boolean } = await APIGetSession(userId);
		if (res.success) {
			setCurrentSession(res.currentSession);
			// console.log('res.session', res.currentSession);
			return res.currentSession;
		}
	}, []);

	const getProfile = useCallback(
		async (profileId: number, myProfile?: Boolean, sessionId?: number) => {
			// check id
			if (profileId === undefined || profileId === null) {
				console.error('USER_INFO.id is not set', profileId);
				return;
			}

			const historyResponse = await APIGetHistory(
				profileId,
				sessionId || currentSession.sessionId
			);

			const res: { user: User | null; success: boolean } = await APIGetProfile(profileId);

			if (res.success && res.user) {
				if (res.user.history && historyResponse.success) {
					res.user.history = historyResponse.userHistory;
				}
				// TODO: BRT remove when call api id is corrected
				if (myProfile) {
					res.user.id = profileId;
					setMyProfile(res.user);
				}
				return res.user;
			}
		},
		[currentSession.sessionId]
	);

	const updateMyProfile = async (newProfileData: UpdatingProfile): Promise<User | undefined> => {
		const isSuccessPost: boolean = await APIUpdateProfile(newProfileData);
		if (isSuccessPost) {
			const updatedProfile: User = {
				...myProfile,
				BIO: newProfileData.bio,
				...newProfileData
			};

			setMyProfile(updatedProfile);

			return updatedProfile;
		}

		return undefined; // If APIUpdateProfile is not successful, return undefined
	};

	const getTeam = useCallback(async (TeamId: number, myTeam?: Boolean) => {
		// check id
		if (TeamId == undefined) {
			console.error('teamid is not set', TeamId);
			return;
		}

		const res: { team: Team | null; success: boolean } = await APIGetTeam(TeamId);

		if (res.success && res.team) {
			if (myTeam) {
				// TODO: check with BRT id number set, remove when call api id is corrected from BRT
				res.team.id = TeamId;
				setMyTeam(res.team);
			}
			// console.log('res.team', res.team);
			return res.team;
		}
	}, []);

	const getLeaderboardTeams = useCallback(async () => {
		const res: { teams: Team[]; success: boolean } = await APIgetLeaderboardTeams(
			currentSession.sessionId
		);
		if (res.success) {
			setTeamsLeaderboard(res.teams);
			return res.teams;
		}
	}, [currentSession.sessionId]);

	const getLeaderboardUsers = useCallback(async () => {
		const res: { users: User[]; success: boolean } = await APIgetLeaderboardUsers(
			currentSession.sessionId
		);
		if (res.success) {
			setUsersLeaderboard(res.users);
			// console.log('res.users', res.users);

			return res.users;
		}
	}, [currentSession.sessionId]);

	const getAllChallenges = async () => {
		const res: { challenges: Challenge[]; success: boolean } = await APIGetAllChallenges(
			currentSession.sessionId
		);

		if (res.success) {
			setAllChallenges(res.challenges);
			// console.log('res.challenges', res.challenges);
			return res.challenges;
		}
	};

	const getMissions = useCallback(async () => {
		const res: { missions: Mission[]; success: boolean } = await APIGetMissions(
			myProfile.id,
			currentSession.sessionId
		);

		if (res.success) {
			setMissions(res.missions);
			return res.missions;
		}
	}, [myProfile.id, currentSession.sessionId]);

	const getMicroLearnings = async () => {
		const res: { microLearnings: MicroLearning[]; success: boolean } =
			await APIGetMicroLearnings(currentSession.sessionId);
		if (res.success) {
			setMicroLearnings(res.microLearnings);
			return res.microLearnings;
		}
	};

	const getCalendar = async () => {
		const res: { calendar: CalendarUpcomingEvents; success: boolean } = await APIGetCalendar();
		if (res.success) {
			setCalendar(res.calendar);
			// console.log('res.calendar', res.calendar);
			return res.calendar;
		}
	};

	const getNotifications = useCallback(
		async (read: boolean, range?: string) => {
			const res: { notifications: Notification[]; success: boolean } =
				await APIGetNotifications(myProfile.id, read, range);
			const response = await axios.get(`/api/back-end/profiles/${myProfile.id}`);
			const data = response.data;
			console.log(data.introductionFlag);
			if (res.success && data.homeFlag) {
				setNotificationList(res.notifications);
				return res.notifications;
			}
		},
		[myProfile.id]
	);

	return (
		<APIContext.Provider
			value={{
				currentSession,
				setCurrentSession,
				myProfile,
				isAvatarSet,
				myTeam,
				teamsLeaderboard,
				setIsAvatarSet,
				usersLeaderboard,
				allChallenges,
				missions,
				microLearnings,
				calendar,
				notificationList,
				getProfile,
				getTeam,
				updateMyProfile,
				getSession,
				getLeaderboardTeams,
				getLeaderboardUsers,
				getAllChallenges,
				getMissions,
				getMicroLearnings,
				getCalendar,
				getNotifications
			}}
		>
			{children}
		</APIContext.Provider>
	);
};

export const blobToBase64 = (blob: Blob | File): Promise<string | ArrayBuffer | null> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.readAsDataURL(blob);

		reader.onload = () => resolve(reader.result);

		reader.onerror = (error) => reject(error);
	});
};

// const imageToBase64 = async (imageUrl: any) => {
// 	const response = await APIGet(imageUrl);
// 	const blob = await response.blob();
// 	return new Promise((resolve, reject) => {
// 		const reader = new FileReader();
// 		reader.onloadend = () => resolve(reader.result);
// 		reader.onerror = (error) => reject(error);
// 		reader.readAsDataURL(blob);
// 	});
// };
