import { Route, Routes } from 'react-router-dom';
import { GameChapterSelectorPage } from '../game/pages/ChapterSelectorPage';
import { GamePage } from '../game/pages/GamePage';

import { BackgroundContainer } from '../components/containers/BackgroundContainer';
import { HomePage } from '../pages/HomePage';
import { IntroductionVideoPage } from '../pages/IntroductionVideoPage';
import { LegalsPage } from '../pages/LegalsPage';
import { LoginPage } from '../pages/LoginPage';
import { PracticePage } from '../pages/PracticePage';
import { TeamPage } from '../pages/TeamPage';
import { ChallengeHUBPage } from '../pages/challenge/ChallengeHUBPage';
import { ChallengePage } from '../pages/challenge/ChallengePage';
import { ChallengeSubmissionPage } from '../pages/challenge/ChallengeSubmissionPage';
import { ChallengesUpcomingPage } from '../pages/challenge/ChallengesUpcomingPage';
import { EndingSummaryPage } from '../pages/ending/EndingSummaryPage';
import { EndingSummarySpeechPage } from '../pages/ending/EndingSummarySpeechPage';
import { EndingSummaryVideoPage } from '../pages/ending/EndingSummaryVideoPage';
import { MicroLearningPage } from '../pages/microLearning/MicroLearningPage';
import { MicroLearningPageHub } from '../pages/microLearning/MicroLearningPageHub';
import { ProfileAvatarCreationPage } from '../pages/profile/ProfileAvatarCreationPage';
import { ProfileBIOCreationPage } from '../pages/profile/ProfileBIOCreationPage';
import { ProfilePage } from '../pages/profile/ProfilePage';
import AuthGuard from './AuthGuard';

function AppRouter() {
	return (
		<Routes>
			<Route path="/" element={<BackgroundContainer></BackgroundContainer>}>
				<Route path="/" element={<AuthGuard></AuthGuard>}>
					<Route path="/" element={<HomePage />} />
					<Route path="/introduction-video" element={<IntroductionVideoPage />} />
					<Route path="/profile/avatar" element={<ProfileAvatarCreationPage />} />
					<Route path="/profile/BIO" element={<ProfileBIOCreationPage />} />
					<Route path="/team/:teamId" element={<TeamPage />} />
					<Route path="/profile/:profileId" element={<ProfilePage />} />
					<Route path="/challenges" element={<ChallengeHUBPage />} />
					<Route
						path="/challenges/upcoming/:challengeId"
						element={<ChallengesUpcomingPage />}
					/>
					<Route path="/challenges/available/:challengeId" element={<ChallengePage />} />
					<Route
						path="/challenges/available/:challengeId/submission/"
						element={<ChallengeSubmissionPage />}
					/>
					<Route path="/practice" element={<PracticePage />} />
					<Route path="/micro-learning" element={<MicroLearningPageHub />} />
					<Route
						path="/micro-learning/:microlearningId"
						element={<MicroLearningPage />}
					/>
					<Route path="/game" element={<GameChapterSelectorPage />} />
					<Route path="/game/chapter/:chapterId" element={<GamePage />} />
					<Route path="/ending-summary" element={<EndingSummaryPage />} />
					<Route path="/ending-summary-video" element={<EndingSummaryVideoPage />} />
					<Route path="/ending-summary-speech" element={<EndingSummarySpeechPage />} />
					<Route path="/legals" element={<LegalsPage />} />
				</Route>
				<Route path="/login/*" element={<LoginPage />} />
			</Route>
		</Routes>
	);
}
export default AppRouter;
