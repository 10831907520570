import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GlobalButton } from './GlobalButton';

interface NavigationButtonProps extends React.ComponentProps<typeof Button> {
	gotopath: string;
}

export const NavigationButton = (props: NavigationButtonProps) => {
	const navigate = useNavigate();

	return (
		<GlobalButton
			variant="contained"
			size="medium"
			sx={{ width: '100%' }}
			{...props}
			onClick={() => {
				navigate(props.gotopath, { replace: true });
			}}
		></GlobalButton>
	);
};
