import { Box, CardActionArea, CardMedia, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import uploadFileIcon from '../../assets/ui/upload_file.svg';
import { GlobalButton } from '../../components/buttons/GlobalButton';

import axios from 'axios';
import { UIContext } from '../../contexts/UIContext';
import { getMimeType } from '../../utils/UtilMethods';

interface MediaComponentProps {
	link?: string;
	externalLink?: boolean;
	type?: string;
}

interface ImageContentProps {
	src: string;
	alt: string;
	onClick?: () => void;
}

const isYouTubeLink = (link: string) => {
	return link.includes('youtube.com') || link.includes('youtu.be');
};

const isExternal = (link: string) => {
	return link.includes('http') || link.includes('://');
};

const MediaComponent: React.FC<MediaComponentProps> = ({ link, externalLink, type }) => {
	const [showVideo, setShowVideo] = useState(false);
	const [showInternalVideo, setShowInternalVideo] = useState(false);
	const UIContextValues = useContext(UIContext);

	const { t } = useTranslation();

	useEffect(() => {
		if (isYouTubeLink(link ?? '')) {
			setShowVideo(true);
		} else if (type === 'video' && !externalLink) {
			setShowInternalVideo(true);
		}
	}, [link]);

	const JoinButton = ({ onClick }: { onClick: () => void }) => (
		<GlobalButton onClick={onClick} sx={styles.arrowButton}>
			{t('micro-learning_button_goto')}
		</GlobalButton>
	);

	const ImageContent = ({ src, alt, onClick }: ImageContentProps) => {
		return (
			<img
				src={src}
				alt={alt}
				style={{
					width: '100%',
					height: '100%',
					objectFit: 'cover',
					cursor: onClick ? 'pointer' : 'auto'
				}}
				onClick={onClick}
			/>
		);
	};

	const handleImageDownload = async (fileId: string) => {
		try {
			if (externalLink === true || isExternal(link ?? '')) {
				window.open(link ?? '', '_blank');
			} else {
				const response = await axios.get(`/api/download/${fileId}`);
				const downloadLink = document.createElement('a');

				downloadLink.href = response.data ?? '';
				downloadLink.download = fileId;
				downloadLink.click();
			}
		} catch (e: any) {
			UIContextValues?.setErrorPopupText!({
				title: 'Could not download file',
				description: e
			});
			UIContextValues?.setErrorPopupVisibility?.(true);
		}
	};

	function extractVideoID(url: any) {
		var regExp = 'youtu(?:.*/v/|.*v=|.be/)([A-Za-z0-9_-]{11})';
		var match = url.match(regExp);
		console.log('url', url);
		console.log('match', match);
		if (match && match[1]?.length == 11) {
			return match[1];
		}
	}

	if (!link) return <></>;
	const fileType = getMimeType(link);

	return (
		<Box sx={styles.mediaContainer}>
			<CardActionArea>
				<CardMedia sx={styles.media}>
					{/* Media content */}
					{type === 'video' && isYouTubeLink(link ?? '') ? (
						<iframe
							title="YouTube Video"
							width="100%"
							height="100%"
							src={`https://www.youtube.com/embed/${extractVideoID(link)}`}
							allowFullScreen
						/>
					) : type === 'image' ? (
						<ImageContent src={link ?? ''} alt="Image Link" />
					) : type === 'event' ? (
						<>
							<JoinButton
								onClick={() => {
									const teamsMeetingLink = link;

									const isTeamsAppAvailable =
										window.navigator.userAgent.includes('Teams');

									if (isTeamsAppAvailable) {
										window.location.href = `msteams://join?meetingUrl=${encodeURIComponent(
											teamsMeetingLink
										)}`;
									} else {
										window.open(teamsMeetingLink, '_blank');
									}
								}}
							/>
						</>
					) : type === 'video' && showInternalVideo ? (
						<CardMedia
							style={styles.video}
							component="video"
							autoPlay
							controls
							src={`/api/video/${link}?type=${fileType}`}
						/>
					) : (
						!showVideo &&
						!showInternalVideo && (
							<div style={{ justifyContent: 'center' }}>
								<img
									src={uploadFileIcon} // Replace with the URL of your file image
									alt="File Link"
									onClick={() => {
										handleImageDownload(link);
									}}
									style={{
										cursor: 'pointer',
										maxWidth: '150px'
									}}
								/>
								<Typography variant="body1" style={{ textAlign: 'center' }}>
									Download File
								</Typography>
							</div>
						)
					)}
				</CardMedia>
			</CardActionArea>
		</Box>
	);
};

const styles = {
	chip: {
		backgroundColor: 'error.main',
		padding: '0.5em',
		fontWeight: 'bold'
		// width: '20%'
	},
	textContainer: {
		marginTop: '2em',
		display: 'flex',
		alignItems: 'center'
	},
	mediaContainer: {
		backgroundColor: 'transparent',
		boxShadow: '0',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '1em'
	},
	mediaContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
		'& 	.MuiCardActionArea-focusHighlight': {
			background: 'none'
		}
	},
	media: {
		maxWidth: '100%',
		maxHeight: '100%',
		width: 'auto',
		height: 'auto',
		display: 'block',
		margin: '0 auto',
		textAlign: 'center'
	},

	overlay: {
		backgroundColor: 'rgba(40, 40, 40, .6)'
	},
	link: {
		paddingTop: '2em'
	},
	subtitle: {
		width: '100%',
		fontSize: {
			xs: '1em',
			sm: '1.2em'
		}
	},
	video: {
		backgroundColor: '#282828',

		top: 0,
		height: '100%',
		width: '100%'
	},
	arrowButton: {
		width: '2em', // Adjust this value to change the button size
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		cursor: 'pointer',
		position: 'absolute'
	}
};

export default MediaComponent;
