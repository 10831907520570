import { Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';

interface TimerProps {
	endTime: number;
	uppercase?: boolean;
}

export const Timer = (props: TimerProps) => {
	const [daysLeft, setDaysLeft] = React.useState(0);
	const [hoursLeft, setHoursLeft] = React.useState(0);
	const [minutesLeft, setMinutesLeft] = React.useState(0);
	const [secondsLeft, setSecondsLeft] = React.useState(0);
	const formatDate = (timestamp) => {
		const date = new Date(timestamp);

		// Extract year, month, day, hours, minutes, and seconds
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
		const day = String(date.getDate()).padStart(2, '0');

		// Format as yyyy-MM-DD HH:MM:SS
		return `${year}-${month}-${day}`;
	};
	useEffect(() => {
		const dateTimeString = `${formatDate(props.endTime! + 86400000)}T00:00:00`;
		// Create a Date object
		const date2 = new Date(dateTimeString);

		// Get time in milliseconds
		const timeInMilliseconds = date2.getTime();
		secondsToTime((timeInMilliseconds - new Date().getTime()) / 1000);
		const interval = setInterval(() => {
			secondsToTime((timeInMilliseconds - new Date().getTime()) / 1000);
		}, 1000);
		return () => clearInterval(interval);
	}, [props.endTime]);

	// get day left, hour left, minute left, second left
	const secondsToTime = useCallback((seconds: number) => {
		let days = Math.floor(seconds / (3600 * 24));
		let hours = Math.floor((seconds % (3600 * 24)) / 3600);
		let minutes = Math.floor((seconds % 3600) / 60);
		let sec = Math.floor(seconds % 60);

		setDaysLeft(days);
		setHoursLeft(hours);
		setMinutesLeft(minutes);
		setSecondsLeft(sec);
	}, []);

	return (
		<Typography
			sx={{ ...styles.text, textTransform: props.uppercase ? 'uppercase' : 'initial' }}
			variant="body1"
		>{`${Math.max(0, daysLeft)} days ${Math.max(0, hoursLeft)} hours ${Math.max(
			0,
			minutesLeft
		)} min ${Math.max(0, secondsLeft)} s `}</Typography>
	);
};
const styles = {
	text: {
		fontWeight: 'bold',
		fontSize: {
			xs: '1em',
			sm: '1.2em'
		},
		marginTop: '1vh',
		textAlign: 'center'
	}
};
