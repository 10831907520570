import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { AvatarColorList } from '../../assets/MediaComponents';
import { ArrowButton, Direction } from '../buttons/ArrowButton';

interface ColorSelectionProps {
	label: string | null;
	// eslint-disable-next-line
	oncolorchange: (color: string) => void;
}

const ColoredDot = (props: any) => {
	return (
		<Box
			component="div"
			sx={{
				...styles.coloredDot,
				backgroundColor: props.color
			}}
		></Box>
	);
};

export const ColorSelection = (props: ColorSelectionProps) => {
	//@ts-ignore
	const swipeableViewsRef = React.useRef(null);
	const [swipeIndex, setSwipeIndex] = React.useState(0);

	const onClickSwipe = React.useCallback(
		(step: number) => {
			if (swipeableViewsRef) {
				//@ts-ignore
				const childCount = swipeableViewsRef.current.containerNode.childElementCount;
				const nextIndex = Math.abs(childCount + (swipeIndex + step)) % childCount;

				setSwipeIndex(nextIndex);
				props.oncolorchange(AvatarColorList[nextIndex]);
			}
		},
		[swipeIndex]
	);

	return (
		<Grid
			container
			direction="row"
			justifyContent="center"
			alignItems="center"
			alignContent="center"

			// bgcolor='purple'
		>
			<Grid item xs={12}>
				<Typography
					variant="h3"
					sx={{
						textAlign: 'center',
						fontSize: {
							xs: '0.9em',
							sm: '1em',
							md: '1em'
						}
					}}
				>
					{props.label}
				</Typography>
			</Grid>

			<Grid sx={styles.dotContainer} item xs={12}>
				<Grid item xs={2}>
					<ArrowButton
						arrowdirection={Direction.Left}
						onClick={() => {
							onClickSwipe(-1);
						}}
					/>
				</Grid>
				<Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center' }}>
					<Box sx={styles.colorDotArea}>
						<SwipeableViews
							style={{
								borderRadius: '100%'
							}}
							ref={swipeableViewsRef}
							index={swipeIndex}
						>
							{AvatarColorList.map((c) => {
								return (
									<Box key={`box_${c}`}>
										<ColoredDot key={`${c}`} color={c} value={c}></ColoredDot>
									</Box>
								);
							})}
						</SwipeableViews>
					</Box>
				</Grid>
				<Grid item xs={2}>
					<ArrowButton
						arrowdirection={Direction.Right}
						onClick={() => {
							onClickSwipe(1);
						}}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

const styles = {
	coloredDot: {
		// position: 'relative',
		borderRadius: '100%',
		height: {
			xs: '2em',
			sm: '3em',
			md: '3em'
		},
		width: {
			xs: '2em',
			sm: '3em',
			md: '3em'
		}
	},
	colorDotArea: {
		display: 'flex',
		justifyContent: 'center',
		width: {
			xs: '2em',
			sm: '3em',
			md: '3em'
		},
		padding: '1em',
		borderRadius: '100%'
	},
	container: {
		alignItems: 'center',
		justifyContent: 'space-around'
	},
	dotContainer: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		// backgroundColor: 'green',
		width: '100%',
		marginTop: {
			xs: '1vh',
			sm: '1em',
			md: '1em'
		}
	}
};
