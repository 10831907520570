import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import returnIcon from '../../assets/ui/return_arrow.svg';
import { GlobalButton } from './GlobalButton';

import { useContext } from 'react';
import useSound from 'use-sound';
import goBackSfx from '../../assets/sounds/go-back.mp3';
import { ParametersContext } from '../../contexts/ParametersContext';

interface ReturnButtonProps {
	goto?: string;
}

export const ReturnButton = (props: ReturnButtonProps) => {
	const ParametersContextValues = useContext(ParametersContext);
	const [backSfx] = useSound(goBackSfx, {
		volume: 0.5,
		soundEnabled: ParametersContextValues?.soundEnabled
	});

	const navigate = useNavigate();

	return (
		<GlobalButton
			disablesfx={true}
			sx={styles.container}
			onClick={() => {
				backSfx();
				if (props.goto) {
					navigate(props.goto);
					return;
				}

				navigate(-1);
			}}
		>
			<Box component="div" sx={styles.arrow} />
		</GlobalButton>
	);
};

const styles = {
	container: {
		minWidth: '0',
		backgroundColor: 'transparent',
		'&:hover': {
			backgroundColor: 'transparent'
		}
	},
	arrow: {
		height: '1.5em',
		width: '2em',
		backgroundImage: `url(${returnIcon})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center'
		// backgroundColor: 'red'
	}
};
