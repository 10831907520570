import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
interface CloseButtonProps {
	onClick?: any;
}

export const CloseButton = (props: CloseButtonProps) => {
	return (
		<IconButton
			sx={{
				position: 'absolute',
				top: '0.4em',
				right: '0.4em',
				color: '#FFF',
				backgroundColor: 'error.main',
				borderRadius: '0%',
				padding: '0.1em',
				transform: 'scale(1.2)',
				zIndex: 1
			}}
			size="large"
			edge="start"
			aria-label="menu"
			onClick={(e) => {
				// buttonClickSfx();
				props.onClick?.(e);
			}}
		>
			<CloseIcon />
		</IconButton>
	);
};
