import { Grid } from '@mui/material';

interface FixedPageProps {
	noPadding?: boolean;
	children?: React.ReactNode;
}

export const FixedPage = ({ children, noPadding = false }: FixedPageProps) => {
	return (
		<Grid
			sx={{
				...styles.content,
				padding: noPadding ? 0 : { xs: '2.5em 2em 2.5em 2em', sm: '5em 2em 5em 2em' }
			}}
		>
			{children}
		</Grid>
	);
};

const styles = {
	content: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		alignItems: 'flex-start'
	}
};
