import { Box, Chip, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ReturnButton } from '../../components/buttons/ReturnButton';
import { CornerContainer } from '../../components/containers/CornerContainer';
import { Header } from '../../components/page/Header';
import { Navbar } from '../../components/page/Navbar';
import { ScrollablePage } from '../../components/page/ScrollablePage';
import { Challenge } from '../../contexts/Contexts.types';
import { GlobalContext } from '../../contexts/GlobalContext';

export const ChallengesUpcomingPage = () => {
	const GlobalContextValues = React.useContext(GlobalContext);
	const [startDate, setStartDate] = React.useState<string>('');

	const navigate = useNavigate();

	const [_challengeData, setChallengeData] = React.useState<Challenge>();

	const { t } = useTranslation();

	const { challengeId } = useParams();

	useEffect(() => {
		setStartDate(new Date(Number(_challengeData?.startDate)).toLocaleDateString('us-US'));
	}, [_challengeData]);

	useEffect(() => {
		if (!challengeId) {
			navigate('/challenges', { replace: true });
			return;
		}

		const challenge = GlobalContextValues?.upcomingChallenges?.find(
			(challenge) => challenge.id === Number(challengeId)
		);

		if (!challenge) {
			navigate('/challenges', { replace: true });
			return;
		}

		setChallengeData(challenge);

		return () => {};
	}, [challengeId]);

	return (
		<>
			<Header
				title={t('challenges_upcoming_header_title')}
				leftSlot={<ReturnButton goto={'/challenges'} />}
			></Header>
			<Box sx={styles.overlay}>
				<ScrollablePage>
					<Stack sx={styles.textContainer} spacing={2}>
						<Typography sx={styles.title} variant="h5">
							{_challengeData?.name}
						</Typography>
						<Box sx={styles.chipContainerDifficulty}>
							{_challengeData?.complexity !== 'notapplicable' && (
								<Chip
									sx={styles.chipDifficulty}
									size="small"
									label={_challengeData?.complexity}
								/>
							)}
							<Chip
								sx={styles.chipType}
								size="small"
								label={_challengeData?.challengeType.toString()}
							/>
						</Box>

						{/* <Typography sx={styles.subtitleRessources} variant='h2' >{t('challenges_upcoming_subtitle_ressources')}</Typography> */}

						<Typography variant="h2">
							{t('challenges_upcoming_subtitle_start_date')}
						</Typography>
						<Typography variant="body1">{startDate}</Typography>

						<Typography variant="h2">
							{t('challenges_upcoming_subtitle_upcoming_challenge')}
						</Typography>
						<Typography variant="body1">{_challengeData?.announcement}</Typography>

						<Typography variant="h2">{t('challenge_property_score')}</Typography>
						<Typography variant="body1">
							{t('challenge_property_points', {
								count: _challengeData?.maxPoints
							})}
						</Typography>

						{GlobalContextValues?.upcomingChallenges?.length === 0 && (
							<Box sx={styles.errorMessageBox}>
								<CornerContainer>
									<Box component="div" sx={styles.errorMessageContent}>
										<Typography sx={styles.errorMessage} variant="body1">
											{t('challenges_upcoming_text_nochallenge')}
										</Typography>
									</Box>
								</CornerContainer>
							</Box>
						)}
					</Stack>
				</ScrollablePage>
			</Box>

			<Navbar></Navbar>
		</>
	);
};
const styles = {
	textContainer: {
		marginTop: '2em',
		width: '100%'
	},

	title: {
		marginTop: '2em'
		// marginBottom: '2em'
	},
	link: {
		paddingTop: '2em'
	},
	overlay: {
		position: 'relative',
		backgroundColor: 'rgba(28,28,28,0.5)',
		height: '100%',
		width: '100%',
		top: '-1em'
	},
	subtitleRessources: {
		wordBreak: 'break-word',
		width: '100%'
	},
	errorMessage: {
		textAlign: 'center',
		// marginTop: '8vh',
		color: 'error.main'
		// backgroundColor: 'red',
		// height: '100%'
	},
	errorMessageContent: {
		// backgroundColor: 'yellow',
		padding: '3vh',
		height: '12vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	errorMessageBox: {
		marginTop: '8vh'
	},
	chipContainerDifficulty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '1em'
	},
	chipDifficulty: {
		backgroundColor: 'error.main',
		padding: '0.5em',
		fontWeight: 'bold'
	},
	chipType: {
		backgroundColor: 'error.dark',
		padding: '0.5em',
		fontWeight: 'bold'
	}
};
