import { Grid } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useContext } from 'react';
import TopBar from '../../assets/navbar/topbar.svg';
import MenuIcon from '../../assets/ui/menu_icon.svg';
import { UIContext } from '../../contexts/UIContext';
import { ParametersPopup } from '../../popup/ParametersPopup';

interface HeaderProps {
	title?: string;
	leftSlot?: React.ReactNode;
}

export const Header = (props: HeaderProps) => {
	const extraSmallHeight = useMediaQuery('(max-width:400px)');
	const UIContextValues = useContext(UIContext);
	return (
		<Box id="header">
			<AppBar position="absolute" sx={styles.topbarContainer}>
				<Toolbar sx={styles.test}>
					<Grid container columns={12} sx={styles.row}>
						<Grid item xs={5} sx={styles.columnScore}>
							{props.leftSlot}
						</Grid>

						<Grid item xs={8} sx={styles.columnTitle}>
							<Typography
								variant="h6"
								component="div"
								sx={{
									flexGrow: 1,
									wordBreak: 'keep-all',
									...{ fontSize: extraSmallHeight ? '0.92em' : '1.12em' }
								}}
								textAlign={'center'}
							>
								{props.title}
							</Typography>
						</Grid>

						<Grid item xs={5} sx={styles.columnMenu}>
							<IconButton
								sx={{
									visibility: UIContextValues.headerInteractive
										? 'visible'
										: 'hidden'
								}}
								size="large"
								edge="end"
								color="inherit"
								aria-label="menu"
								onClick={() => {
									UIContextValues?.setMenuVisibility!(true);
								}}
							>
								<Box id={'settings'} component="div" sx={styles.settings} />
							</IconButton>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>

			<ParametersPopup></ParametersPopup>
		</Box>
	);
};

const styles = {
	columnTitle: {
		position: 'absolute',
		width: '100%',
		display: 'flex',
		overflow: 'visible',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'transparent'
	},
	columnScore: {
		// position: 'absolute',

		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		paddingLeft: '0.8vh',
		backgroundColor: 'transparent',
		fontSize: {
			xs: '0.9em',
			sm: '1em',
			md: '1em'
		}
	},

	columnMenu: {
		// position: 'absolute'

		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		// paddingRight: '1em',
		backgroundColor: 'transparent'
	},
	row: {
		width: '100%',
		padding: '0 !important',
		display: 'flex',
		flexWrap: 'nowrap',
		justifyContent: 'space-around',
		alignItems: 'center',
		backgroundColor: 'transparent'
	},
	test: {
		padding: '0 !important',
		backgroundColor: 'transparent'
	},
	topbarContainer: {
		// position: 'absolute',
		top: {
			xs: '0',
			sm: '0'
		},

		borderImageSource: `url(${TopBar})`,
		borderImageSlice: {
			xs: '20 20 20 20 fill',
			sm: '27 27 27 27 fill'
		},
		borderImageWidth: {
			xs: '15px 15px 15px 15px',
			sm: '20px 20px 20px 20px'
		},
		borderImageOutset: '25px 0px 0px 0px',
		borderImageRepeat: 'stretch stretch',
		display: 'flex',
		alignItems: 'space-around',
		justifyContent: 'center',
		height: {
			xs: '3.8em',
			sm: '4.2em'
		},
		backgroundColor: 'transparent !important',
		boxShadow: 'none',
		padding: '0!important',
		zIndex: '1'
	},

	settings: {
		height: '1.3em',
		width: '1.3em',
		backgroundImage: `url(${MenuIcon})`,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center'
	}
};
