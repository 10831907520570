import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import SettingsIcon from '@mui/icons-material/Settings';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import arrowImage from '../assets/ui/learning-arrow.svg';
import menu_button from '../assets/ui/menu_button.svg';
import windowBackgroundHover from '../assets/ui/window_card_hover.svg';
import { GlobalButton } from '../components/buttons/GlobalButton';
import { BorderColorEnum } from '../components/containers/CornerContainer';
import { AvatarMember } from '../components/profile/AvatarMember';
import { APIContext } from '../contexts/API/APIContext';
import { CalendarContext } from '../contexts/CalendarContext';
import { ParametersContext } from '../contexts/ParametersContext';
import { UIContext } from '../contexts/UIContext';
import { UserContext } from '../contexts/UserContext';
import { PopupContainer } from './PopupContainer';

export const MenuPopup = () => {
	const UserContextValues = useContext(UserContext);
	const APIContextValues = useContext(APIContext);

	const UIContextValues = useContext(UIContext);
	const ParametersContextValues = useContext(ParametersContext);
	const CalendarContextValues = useContext(CalendarContext);
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<PopupContainer
			borderColor={BorderColorEnum.tutorial}
			title={t('menu_title')}
			open={UIContextValues?.MenuVisibility!}
			onClickCloseButton={() => {
				UIContextValues?.setMenuVisibility!(false);
			}}
		>
			<Box sx={styles.Content}>
				<Grid
					container
					sx={styles.profileArea}
					onClick={() => {
						UIContextValues?.setMenuVisibility!(false);

						navigate(`/profile/${UserContextValues.myProfile?.id}`);
					}}
				>
					<Grid item xs={4} sx={styles.profileAvatar}>
						<AvatarMember
							displayFirstName={false}
							user={UserContextValues?.myProfile!}
							me={true}
						/>
					</Grid>
					<Grid item xs={8} sx={styles.profileInfos}>
						<Typography variant="h6" sx={styles.nameProfil}>
							{UserContextValues.myProfile?.name}
						</Typography>
						<Typography variant="h3">{UserContextValues.myTeam?.name}</Typography>
						<Typography sx={styles.seeMore}>{t('menu_button_view_profile')}</Typography>
					</Grid>
				</Grid>

				<GlobalButton
					sx={styles.button}
					onClick={() => {
						APIContextValues.getCalendar();
						CalendarContextValues?.setCalendarVisibility!(true);
						UIContextValues?.setMenuVisibility!(false);
					}}
				>
					<Grid container sx={styles.buttonContent}>
						<Grid item xs={2} sx={styles.buttonContentIcon}>
							<InsertInvitationIcon />
						</Grid>
						<Grid item xs={9} sx={styles.buttonContentText}>
							<Typography variant="h5">{t('menu_button_calendar')}</Typography>
						</Grid>
						<Grid item xs={1}>
							<Box sx={styles.arrow}>
								<img className="arrow" src={arrowImage} />
							</Box>
						</Grid>
					</Grid>
				</GlobalButton>
				<GlobalButton
					sx={styles.button}
					onClick={() => {
						APIContextValues.getLeaderboardTeams();
						APIContextValues.getLeaderboardUsers();
						UIContextValues?.setLeaderboardVisibility!(true);
						UIContextValues?.setMenuVisibility!(false);
					}}
				>
					<Grid container sx={styles.buttonContent}>
						<Grid item xs={2} sx={styles.buttonContentIcon}>
							<SettingsIcon />
						</Grid>
						<Grid item xs={9} sx={styles.buttonContentText}>
							<Typography variant="h5">{t('menu_button_leaderboard')}</Typography>
						</Grid>
						<Grid item xs={1}>
							<Box sx={styles.arrow}>
								<img className="arrow" src={arrowImage} />
							</Box>
						</Grid>
					</Grid>
				</GlobalButton>
				<GlobalButton
					sx={styles.button}
					onClick={() => {
						ParametersContextValues?.setParametersVisibility!(true);
						UIContextValues?.setMenuVisibility!(false);
					}}
				>
					<Grid container sx={styles.buttonContent}>
						<Grid item xs={2} sx={styles.buttonContentIcon}>
							<SettingsIcon />
						</Grid>
						<Grid item xs={9} sx={styles.buttonContentText}>
							<Typography variant="h5">{t('menu_button_settings')}</Typography>
						</Grid>
						<Grid item xs={1}>
							<Box sx={styles.arrow}>
								<img className="arrow" src={arrowImage} />
							</Box>
						</Grid>
					</Grid>
				</GlobalButton>
			</Box>
		</PopupContainer>
	);
};

const styles = {
	Content: {
		width: '100%',

		// backgroundColor: 'red',
		display: 'flex',
		flexDirection: 'column'
	},
	profileArea: {
		// backgroundColor: 'red',
		// height: '7em',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
		border: '1px solid transparent',
		'&:hover': {
			outlineOffset: '4px',
			outline: '1px solid #329E51'
		},
		marginBottom: '1em',
		marginTop: '1em'
	},
	profileAvatar: {
		// backgroundColor: 'green',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		transform: {
			xs: 'scale(0.7)',
			sm: 'scale(0.8)'
		},
		height: '100%'
	},
	profileInfos: {
		paddingLeft: '0.5em',
		// backgroundColor: 'cyan',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'space-around',
		color: 'common.white'
	},
	nameProfil: {
		textAlign: 'start',
		fontSize: {
			xs: '1em',
			sm: '1.2em'
		}
	},
	seeMore: {
		fontSize: {
			xs: '0.7em',
			sm: '0.8em'
		},
		fontWeight: 'bold',
		textTransform: 'uppercase',
		width: '100%',
		height: '2em',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 !important',
		color: 'primary.main',
		marginTop: '0.5em',
		marginBottom: '0.5em'
	},

	button: {
		width: '100%',
		height: '3em',
		borderImageSource: `url(${menu_button})`,
		borderImageSlice: '53% 14% 30% 19% fill',
		borderImageWidth: '20px',
		borderImageOutset: '0px',
		borderImageRepeat: 'stretch stretch',
		backgroundColor: 'transparent',
		marginBottom: '1em',

		'&:hover': {
			borderImageSource: `url(${windowBackgroundHover})`,
			borderImageSlice: '21% 21% 21% 21% fill',
			borderImageWidth: '25px',
			borderImageOutset: '0px',
			borderImageRepeat: 'stretch stretch',
			backgroundColor: 'transparent',

			cursor: 'pointer',
			'img.arrow': {
				transform: 'translateX(0.4em)'
			}
		}
	},
	buttonContent: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		// backgroundColor: 'red',
		width: '100%'
	},
	buttonContentIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
		// backgroundColor: 'green',
	},
	buttonContentText: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start'
		// backgroundColor: 'cyan',
	},
	arrow: {
		width: '2em',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',

		'& > img': {
			width: '0.5em',
			transition: 'all 0.3s ease'
		}
	}
};
