import { Fade } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect } from 'react';
import arrow_left from '../assets/arrow-left.svg';
import arrow_right from '../assets/arrow-right.svg';
import figer_pointer from '../assets/finger-pointer.svg';

// interface SwipeAnimationProps extends React.HTMLAttributes<HTMLDivElement> {
// 	// visible?: boolean;
// }

export default function SwipeAnimation(): JSX.Element {
	const afkTimeToDisplay = 15000;
	const [lastCick, setLastClick] = React.useState(Date.now() - 5000);
	const [visible, setVisible] = React.useState(false);

	useEffect(() => {
		// on mouse click down
		document.addEventListener('mousedown', () => {
			setLastClick(Date.now());
		});

		// listen for drag
		document.addEventListener('drag', () => {
			setLastClick(Date.now());
		});

		// listen for touch
		document.addEventListener('touchstart', () => {
			setLastClick(Date.now());
		});

		return () => {
			document.removeEventListener('mousedown', () => {});
			document.removeEventListener('drag', () => {});
			document.removeEventListener('touchstart', () => {});
		};
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			if (Date.now() - lastCick > afkTimeToDisplay) {
				setVisible(true);
			} else {
				setVisible(false);
			}
		}, 1000);
		return () => clearInterval(interval);
	}, [lastCick]);

	return (
		<Fade in={visible} timeout={500}>
			<Box>
				<Box id={'mini-game-tutorial-hand'} sx={styles.hand} />
				{/* <Box id={"mini-game-tutorial-left-arrow"} sx={styles.arrowLeft} /> */}
				{/* <Box id={"mini-game-tutorial-right-arrow"} sx={styles.arrowRight} /> */}
			</Box>
		</Fade>
	);
}

const styles = {
	hand: {
		pointerEvents: 'none',
		backgroundImage: `url(${figer_pointer})`,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		width: {
			xs: '5em',
			sm: '5em',
			md: '6em'
		},
		height: {
			xs: '5em',
			sm: '5em',
			md: '6em'
		},
		position: 'absolute',
		top: '60%',
		left: '50%',
		zIndex: 20
	},
	arrowLeft: {
		pointerEvents: 'none',
		backgroundImage: `url(${arrow_left})`,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		width: '5em',
		height: '5em',
		position: 'absolute',
		bottom: '30%',
		left: '10%',
		zIndex: 20
	},
	arrowRight: {
		pointerEvents: 'none',
		backgroundImage: `url(${arrow_right})`,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		width: '5em',
		height: '5em',
		position: 'absolute',
		bottom: '30%',
		right: '10%',
		zIndex: 20
	}
};
