import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid/Grid';
import axios from 'axios';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GlobalButton } from '../../components/buttons/GlobalButton';
import { ReturnButton } from '../../components/buttons/ReturnButton';
import { Header } from '../../components/page/Header';
import { ScrollablePage } from '../../components/page/ScrollablePage';
import { BioInput } from '../../components/profile/BioInput';
import { APIContext } from '../../contexts/API/APIContext';
import { UpdatingProfile } from '../../contexts/API/APIFechers';
import { UIContext } from '../../contexts/UIContext';
import { UserContext } from '../../contexts/UserContext';

export const ProfileBIOCreationPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const APIContextValues = React.useContext(APIContext);
	const UIContextValues = React.useContext(UIContext);
	const UserContextValues = React.useContext(UserContext);

	const [_BIO, setBIO] = React.useState(
		APIContextValues.myProfile.BIO || UserContextValues.defaultBIO
	);

	React.useEffect(() => {
		UIContextValues.setHeaderInteractive(false);
		UIContextValues.resetOverlaysEventsCompletion();
	}, []);

	const submit = async () => {
		let profile;

		if (!(APIContextValues.myProfile.id && APIContextValues.myProfile.teamId)) {
			const response = await axios.get('/api/loggedIn');
			let { isAuthenticated, userInfo } = response.data;

			profile = await APIContextValues.getProfile(userInfo?.id);
			if (profile === null || !isAuthenticated) navigate('/login', { replace: true });
		}

		const UpdatingProfile: UpdatingProfile = {
			avatar: UserContextValues.myProfile.avatar,
			bio: _BIO,
			teamId: APIContextValues.myProfile.teamId,
			userID: APIContextValues.myProfile.id
		};

		await APIContextValues.updateMyProfile(UpdatingProfile);
		APIContextValues.setIsAvatarSet(true);

		navigate('/', { replace: true });
	};

	return (
		<>
			<Header
				title={t('profile_BIO_header_title')}
				leftSlot={
					<ReturnButton
						goto={'/profile/avatar'} /*navigationParams={{ replace: true }} */
					/>
				}
			></Header>

			<ScrollablePage>
				<Grid item xs={12}>
					<Typography
						sx={{
							textAlign: 'center',
							fontWeight: 'bolder',
							paddingTop: '2vh',
							paddingBottom: { xs: '6vh', sm: '4vh' }
						}}
						variant="body1"
					>
						{t('profile_BIO_text_intro')}{' '}
					</Typography>
				</Grid>
				<Grid>
					<BioInput
						title={t('profile_BIO_text_motto')}
						key={`bio-motto`}
						editable={true}
						BIO={_BIO}
						BIOProperty={'motto'}
						setbio={(_b) => {
							setBIO!(_b);
						}}
					/>
					<BioInput
						title={t('profile_BIO_text_amazing')}
						key={`bio-amazing`}
						editable={true}
						BIO={_BIO}
						BIOProperty={'amazing'}
						setbio={(_b) => {
							setBIO!(_b);
						}}
					/>
					<BioInput
						title={t('profile_BIO_text_trust')}
						key={`bio-trust`}
						editable={true}
						BIO={_BIO}
						BIOProperty={'trust'}
						setbio={(_b) => {
							setBIO!(_b);
						}}
					/>
					<BioInput
						title={t('profile_BIO_text_improve')}
						key={`bio-improve`}
						editable={true}
						BIO={_BIO}
						BIOProperty={'improve'}
						setbio={(_b) => {
							setBIO!(_b);
						}}
					/>
				</Grid>

				<GlobalButton sx={styles.button} onClick={submit}>
					{t('profile_BIO_button')}
				</GlobalButton>
			</ScrollablePage>
		</>
	);
};

const styles = {
	button: {
		width: '50%',
		margin: {
			xs: '2vh',
			sm: '3em',
			md: '3em'
		}
	}
};
