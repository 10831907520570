import { Box, Fade, Grid } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import earthIcons from '../assets/elements2D/earthIcons.svg';
import marsIcons from '../assets/elements2D/marsIcons.svg';
import { FixedPage } from '../components/page/FixedPage';
import { Header } from '../components/page/Header';
import { Navbar } from '../components/page/Navbar';
import { InfoHomePage } from '../components/profile/InfoHomePage';
import { MySpaceShip } from '../components/spaceships/MySpaceShip';
import { SpaceShip } from '../components/spaceships/SpaceShip';
import { APIContext } from '../contexts/API/APIContext';
import { GlobalContext } from '../contexts/GlobalContext';
import { UIContext } from '../contexts/UIContext';
import { UserContext } from '../contexts/UserContext';

const SpaceShips = (props: { position: string | 'left' | 'right' }) => {
	const globalContext = React.useContext(GlobalContext);
	const UserContextValues = React.useContext(UserContext);

	let _ships: JSX.Element[] = [];

	let teamsCount = Math.min(globalContext?.teamsLeaderboard?.length || 0, 10);
	teamsCount = Math.max(teamsCount, 0);

	let startIndex = 0;
	let endIndex = teamsCount / 2;

	if (props.position === 'right') {
		startIndex = teamsCount / 2;
		endIndex = teamsCount;
	}

	globalContext
		?.teamsLeaderboard!.slice(startIndex, endIndex)
		.filter((team) => team.id !== UserContextValues?.myTeam!.id)
		.forEach((team, index) => {
			_ships.push(
				<SpaceShip
					key={`spaceship_${team.id}`}
					team={team}
					index={index}
					animationdelay={index}
				/>
			);
		});

	return <>{_ships}</>;
};

export const HomePage = () => {
	const UserContextValues = useContext(UserContext);
	const UIContextValues = useContext(UIContext);
	const APIContextValues = useContext(APIContext);

	useEffect(() => {
		if (UIContextValues?.overlaysEventsCompletion?.introduction == false) {
			UIContextValues.checkOverlaysEventsCompletion?.('introduction');
		} else if (UIContextValues?.overlaysEventsCompletion?.home == false) {
			UIContextValues.checkOverlaysEventsCompletion?.('home');
		} else {
			UIContextValues.setShipsVisibility?.(true);
		}
	}, [UIContextValues?.overlaysEventsCompletion]);

	useEffect(() => {
		APIContextValues.getLeaderboardTeams();
		APIContextValues.getLeaderboardUsers();
		APIContextValues.getTeam(APIContextValues.myProfile.teamId, true);
		APIContextValues.getCalendar();
		APIContextValues.getNotifications(false);
	}, [APIContextValues.currentSession.sessionId, APIContextValues.myProfile]);

	return (
		<>
			<Header
				leftSlot={
					<InfoHomePage
						user={UserContextValues.myProfile}
						team={UserContextValues.myTeam}
					/>
				}
			></Header>
			<FixedPage>
				<Fade
					in={
						UIContextValues.shipsVisibility &&
						!UIContextValues.insideShipOverlayVisibility
					}
				>
					<Grid container sx={styles.spaceShipContainer}>
						{/* <Box component={"img"} sx={styles.mars}></Box> */}

						<Box component={'div'} sx={styles.mars}></Box>

						<Grid item xs={4} sx={styles.spaceShips}>
							<SpaceShips position="left"></SpaceShips>
						</Grid>

						<Grid item xs={1} sx={styles.spaceShips}>
							<MySpaceShip
								key={`spaceship_${UserContextValues.myTeam?.id}`}
								team={UserContextValues.myTeam!}
								index={0}
								animationdelay={9}
							/>
						</Grid>

						<Grid item xs={4} sx={styles.spaceShips}>
							<SpaceShips position="right"></SpaceShips>
						</Grid>
						<Box component={'div'} sx={styles.earth}></Box>
					</Grid>
				</Fade>
			</FixedPage>

			<Navbar></Navbar>
		</>
	);
};
const styles = {
	mars: {
		position: 'absolute',
		top: '-8vh',
		backgroundImage: `url(${marsIcons})`,
		backgroundColor: '#962320',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100pt',
		width: '100pt',
		borderColor: '#EE332D',
		boxShadow: '0 0 50px #EE332D',
		borderRadius: '100%',
		scale: '2'
	},
	earth: {
		position: 'absolute',
		bottom: {
			xs: '-14em',
			sm: '-15em'
		},
		backgroundImage: `url(${earthIcons})`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: {
			xs: '250pt',
			sm: '300pt'
		},
		width: {
			xs: '250pt',
			sm: '300pt'
		},
		outline: 'none',
		borderColor: '#0BE3CD',
		boxShadow: '0 0 70px #0BE3CD',
		borderRadius: '100%'
	},

	spaceShipContainer: {
		height: '100%',
		width: '100%',
		// backgroundColor: 'orange',
		display: 'flex',
		margin: '0 !important',

		justifyContent: 'space-around',
		alignItems: 'center !important'
	},
	spaceShips: {
		display: 'flex',
		flexDirection: 'row',
		height: '100%',
		width: '100%',

		justifyContent: 'center'
		// backgroundColor: 'cyan'
	}
};
