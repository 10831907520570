import { Grid } from '@mui/material';

interface ScrollablePageProps {
	children?: React.ReactNode;
}

export const ScrollablePage = (props: ScrollablePageProps) => {
	return (
		<Grid sx={styles.overflow}>
			<Grid sx={styles.parent}>
				<Grid sx={styles.content}>{props.children}</Grid>
			</Grid>
		</Grid>
	);
};

const styles = {
	overflow: {
		overflow: 'hidden',
		height: {
			xs: '94vh',
			sm: '100vh',
			md: '100vh'
		},
		padding: '0.5rem',
		paddingTop: {
			xs: '8vh',
			sm: '3.8rem',
			md: '3.8rem'
		}
	},
	parent: {
		height: {
			xs: '94vh',
			sm: '90vh',
			md: '90vh',
			lg: '84vh'
		},
		overflowY: 'scroll',
		overflowX: 'hidden',
		// backgroundColor: 'orange',
		position: 'relative'
		// padding: '2rem',
		// paddingTop: '4rem',
	},
	content: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '1.5em',
		paddingBottom: {
			xs: '30vh',
			sm: '30vh',
			md: '40vh',
			lg: '15vh',
			xl: '30vh'
		},
		// backgroundColor: 'orange',
		overflow: 'hidden'
	}
};
