import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { FormGroup } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useContext } from 'react';
import { BorderColorEnum } from '../components/containers/CornerContainer';
import { UIContext } from '../contexts/UIContext';
import { PopupContainer } from './PopupContainer';

export const SuccessPopup = () => {
	const UIContextValues = useContext(UIContext);

	return (
		<PopupContainer
			borderColor={BorderColorEnum.success}
			title={UIContextValues.successPopupText?.title!}
			open={UIContextValues?.successPopupVisibility!}
			onClickCloseButton={() => {
				UIContextValues?.setSuccessPopupVisibility!(false);
			}}
		>
			<Box sx={styles.settingContent}>
				<Box sx={styles.iconArea}>
					<TaskAltIcon sx={styles.icon} />
				</Box>
				<FormGroup sx={styles.setting}>
					<Typography variant="body1" sx={{ textAlign: 'center', width: '100%' }}>
						{UIContextValues.successPopupText?.description}
					</Typography>
				</FormGroup>
			</Box>
		</PopupContainer>
	);
};

const styles = {
	iconArea: {
		// backgroundColor: 'yellow',
		width: '100%',
		height: '5em',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingBottom: '0.2em'
	},
	icon: {
		color: 'primary.main',
		transform: {
			xs: 'scale(3)',
			sm: 'scale(4)'
		}
	},
	settingContent: {
		width: '100%',
		// backgroundColor: 'red',
		display: 'flex',
		flexDirection: 'column',
		gap: '2em'
	},
	setting: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: '2em'
	}
};
