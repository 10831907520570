import { GameProgressionProps } from './GameComponents.model';
import Box from '@mui/material/Box';
import sliceBorderImage from '../assets/progress-border.svg';
import bgImage from '../assets/progress-bg.svg';
import gradientBgImage from '../assets/progress-bg-gradient.svg';
import pointImage from '../assets/progress-point.svg';
import shipImage from '../assets/progress-ship.svg';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function GameProgression({ progress }: GameProgressionProps): JSX.Element {
	const smallHeight = useMediaQuery('(max-height:800px)');
	const verySmallHeight = useMediaQuery('(max-height:630px)');

	return (
		<Box
			sx={{
				...style.main,
				...{
					fontSize: smallHeight ? '7px' : '10px',
					display: verySmallHeight ? 'none' : 'block'
				}
			}}
		>
			<Box sx={style.bg}></Box>
			<Box sx={style.slice}></Box>
			<Box sx={style.gradientBg}></Box>

			<Box sx={style.content}>
				<Box sx={style.point}>
					<img src={pointImage} />
				</Box>
				<Box sx={style.line} width={`${progress * 100}%`}></Box>
				<Box sx={style.ship}>
					<img src={shipImage} />
				</Box>
				<Box
					sx={{ ...style.line, ...{ borderBottomStyle: 'dashed' } }}
					width={`${(1 - progress) * 100}%`}
				></Box>
				<Box sx={style.point}>
					<img src={pointImage} />
				</Box>
			</Box>
		</Box>
	);
}

const borderWidth = 1.2;

const style = {
	main: {
		width: '90%',
		position: 'relative',
		fontSize: '10px'
	},
	bg: {
		backgroundImage: `url(${bgImage})`,
		backgroundSize: '1.2em',
		width: '100%',
		height: '100%',
		position: 'absolute'
	},
	slice: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		borderImageSlice: '18% 18% 18% 18% fill',
		borderImageWidth: `${borderWidth}em`,
		borderImageOutset: 0,
		borderImageRepeat: 'stretch stretch',
		borderImageSource: `url(${sliceBorderImage})`
	},
	gradientBg: {
		width: `calc(100% - ${borderWidth * 2}em)`,
		height: `calc(100% - ${borderWidth * 2}em)`,
		position: 'absolute',
		backgroundImage: `url(${gradientBgImage})`,
		top: `${borderWidth}em`,
		left: `${borderWidth}em`,
		mixBlendMode: 'overlay'
	},
	content: {
		position: 'relative',
		padding: '2em 3em',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		alignContent: 'center',
		alignItems: 'center',
		justifyContent: 'space-between',
		'& > div': {
			transition: 'all 0.6s ease'
		}
	},
	point: {
		width: '2em',
		height: '2em'
	},
	ship: {
		width: '5em',
		height: '2.2em',

		textAlign: 'center',

		'& > img': {
			width: '90%'
		}
	},
	line: {
		borderBottom: '0.3em solid #dcfffa',
		height: '0px',
		position: 'relative',
		top: '-0.15em'
	}
};
