import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import useSound from 'use-sound';
import clickButtonSfx from '../../assets/sounds/button-click.mp3';
import { ParametersContext } from '../../contexts/ParametersContext';

let _GlobalButton = styled(Button)<ButtonProps>(({ theme }) => ({
	minWidth: '40%',
	backgroundColor: theme.palette.primary.main,
	color: theme.palette.common.white,
	position: 'relative',
	height: '3rem',

	'&:hover': {
		backgroundColor: isMobile ? theme.palette.primary.main : theme.palette.primary.dark
	},
	'&.Mui-disabled': {
		backgroundColor: 'rgba(50, 158, 81, 0.2)',
		color: 'rgba(28, 28, 28, 0.6)'
	}
}));

interface GlobalButtonProps extends ButtonProps {
	disablesfx?: boolean;
}

export const GlobalButton = ({ disablesfx = false, ...Props }: GlobalButtonProps) => {
	const ParametersContextValues = useContext(ParametersContext);

	const [buttonClickSfx] = useSound(clickButtonSfx, {
		volume: 0.8,
		soundEnabled: ParametersContextValues?.soundEnabled
	});

	return (
		<_GlobalButton
			{...Props}
			onClick={(e) => {
				if (!disablesfx) {
					buttonClickSfx();
				}

				Props?.onClick?.(e);
			}}
		/>
	);
};
