import { FormGroup } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useContext } from 'react';
import { BorderColorEnum } from '../components/containers/CornerContainer';
import { UIContext } from '../contexts/UIContext';
import { PopupContainer } from './PopupContainer';

export const ErrorPopup = () => {
	const UIContextValues = useContext(UIContext);

	return (
		<PopupContainer
			borderColor={BorderColorEnum.warning}
			title={UIContextValues.errorPopupText?.title!}
			open={UIContextValues?.errorPopupVisibility!}
			onClickCloseButton={() => {
				UIContextValues?.setErrorPopupVisibility!(false);
			}}
		>
			<Box sx={styles.settingContent}>
				<FormGroup sx={styles.setting}>
					<Typography>{UIContextValues.errorPopupText?.description}</Typography>
				</FormGroup>
			</Box>
		</PopupContainer>
	);
};

const styles = {
	settingContent: {
		width: '100%',

		// backgroundColor: 'red',
		display: 'flex',
		flexDirection: 'column',
		gap: '2em'
	},
	setting: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '2em'
	}
};
