import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import arrowImage from '../assets/chapter-arrow.svg';
import borderImage from '../assets/chapter-border.svg';
import lockImage from '../assets/chapter-lock.svg';
import { GameChapterProps } from './GameComponents.model';

import { useContext } from 'react';
import useSound from 'use-sound';
import chapterSelectionSfx from '../../assets/sounds/game-chapter-selection.mp3';
import { ParametersContext } from '../../contexts/ParametersContext';

export default function GameChapter({ chapter }: GameChapterProps): JSX.Element {
	const ParametersContextValues = useContext(ParametersContext);

	const smallHeight = useMediaQuery('(max-height:650px)');
	const navigate = useNavigate();
	const [playChapterSelectionSfx] = useSound(chapterSelectionSfx, {
		volume: 0.8,
		soundEnabled: ParametersContextValues?.soundEnabled
	});

	return (
		<Box
			sx={{
				...style.main,
				...{
					fontSize: smallHeight ? '8px' : '10px',
					opacity: chapter.locked ? 0.6 : 1,
					margin: smallHeight ? '0.7em 0' : '1.2em 0',
					cursor: chapter.locked ? 'default' : 'pointer'
				}
			}}
			onClick={() => {
				if ((chapter.displayName || chapter.name) && !chapter.locked) {
					navigate(`chapter/${chapter.id}`);
					playChapterSelectionSfx();
				}
			}}
		>
			<Box sx={{ ...style.illustration, ...{ height: smallHeight ? '8em' : '9em' } }}>
				<img src={`${process.env.PUBLIC_URL}/chapterImages/${chapter.image}.png`} />
			</Box>

			<Box sx={style.content}>
				<Typography sx={style.name}>{chapter.displayName || chapter.name}</Typography>

				<Box sx={{ ...style.score, ...{ marginTop: smallHeight ? '0.5em' : '1em' } }}>
					<Box sx={style.finalScore}>{Math.round(chapter.score || 0)}</Box>
					<Box sx={style.maxScore}> / 100</Box>
				</Box>

				<Box sx={style.scoreTime}>
					<Typography>{chapter.durationInMinutes} min</Typography>
				</Box>
			</Box>

			{!chapter.locked && (
				<Box sx={style.arrow}>
					<img className="arrow" src={arrowImage} />
				</Box>
			)}

			{chapter.locked && (
				<Box sx={style.lock}>
					<img src={lockImage} />
				</Box>
			)}
		</Box>
	);
}

const style = {
	main: {
		borderImageSlice: '21% 21% 21% 21% fill',
		borderImageWidth: '25px',
		borderImageOutset: '0px',
		borderImageRepeat: 'stretch stretch',
		borderImageSource: `url(${borderImage})`,
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		justifyContent: 'flex-start',
		alignItems: 'center',
		width: '100%',
		color: '#DCFFFA',
		'&:hover': {
			'img.arrow': {
				transform: 'translateX(0.4em)'
			}
		}
	},
	illustration: {
		'& > img': {
			display: 'block',
			height: '100%'
		}
	},
	content: {
		flex: '1',
		padding: '0 2em'
	},
	name: {
		fontSize: '1.3em',
		marginTop: '0.5em',
		textAlign: 'left',
		textTransform: 'uppercase',
		lineHeight: '1.1',
		color: '#DCFFFA'
	},
	score: {
		lineHeight: '1',
		marginBottom: '0.2em'
	},
	finalScore: {
		fontSize: '2em',
		display: 'inline'
	},
	maxScore: {
		fontSize: '1.1em',
		display: 'inline'
	},
	scoreTime: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	arrow: {
		width: '2em',

		'& > img': {
			width: '0.8em',
			transition: 'all 0.3s ease'
		}
	},
	lock: {
		width: '0',

		'& > img': {
			width: '2.8em',
			transform: 'translateX(-50%)'
		}
	}
};
