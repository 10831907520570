import EditIcon from '@mui/icons-material/Edit';
import { Button, FormControl, Grid, Grow, Stack, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { BIO } from '../../contexts/Contexts.types';

import { useTranslation } from 'react-i18next';
import useSound from 'use-sound';
import clickButtonSfx from '../../assets/sounds/button-click.mp3';
import inputValidateSfx from '../../assets/sounds/input-validate.mp3';
import { ParametersContext } from '../../contexts/ParametersContext';

interface BioInputProps {
	BIO?: { [key: string]: string };
	editable?: boolean;
	title: string;
	BIOProperty: string;
	setbio?: (bio: BIO) => void; // eslint-disable-line
}

export const BioInput = (props: BioInputProps) => {
	const [editing, setEditing] = React.useState(false);

	const inputRef = React.useRef<HTMLInputElement>(null);
	const wrapperRef = React.useRef(null);

	const [intermediaryValue, setIntermediaryValue] = React.useState(
		props.BIO?.[props.BIOProperty] || ''
	);

	const { t } = useTranslation();

	const ParametersContextValues = React.useContext(ParametersContext);
	const [buttonClickSfx] = useSound(clickButtonSfx, {
		volume: 0.8,
		soundEnabled: ParametersContextValues?.soundEnabled
	});
	const [validateSfx] = useSound(inputValidateSfx, {
		volume: 0.8,
		soundEnabled: ParametersContextValues?.soundEnabled
	});

	/**
	 * Alert if clicked on outside of element
	 */
	const handleClickOutside = (event: any) => {
		//@ts-ignore
		if (wrapperRef.current && !wrapperRef.current.contains(event.target) && editing) {
			onValidate();
		}
	};

	useEffect(() => {
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);

		// focus on input
		if (editing && inputRef.current) {
			inputRef.current.focus();
		}

		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [props.BIO, editing, props.editable, intermediaryValue]);

	useEffect(() => {
		setIntermediaryValue(props.BIO?.[props.BIOProperty] || '');
	}, [props.BIO, props.BIOProperty]);

	const onValidate = useCallback(() => {
		validateSfx();

		let _bio = props.BIO;
		// @ts-ignore
		props.setbio({
			..._bio,
			[props.BIOProperty]: intermediaryValue
		});
		setEditing(false);
	}, [props.BIO, intermediaryValue, validateSfx]);

	const sanitizeInput = (input: string): string => {
		const sanitizedValue = input.replace(
			/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
			''
		);
		return sanitizedValue;
	};

	return (
		<Stack sx={styles.bioInputContainer} flexDirection={'column'}>
			<FormControl>
				{/* // Titre et bouton EDIT*/}
				<Grid container sx={styles.upperBox}>
					<Grid item xs={11} sx={styles.subtitleStyle}>
						<Typography>{props.title}</Typography>
					</Grid>
					<Grow in={!editing && props.editable}>
						<Grid item xs={1} sx={styles.editPenIcon}>
							<Button
								onClick={() => {
									buttonClickSfx();
									setEditing(true);
								}}
							>
								<EditIcon color="primary" sx={styles.iconStyle} />
							</Button>
						</Grid>
					</Grow>
				</Grid>

				{/* // Zone champs text EDIT ON */}
				<Grid container sx={styles.InputBoxEditON} ref={wrapperRef}>
					<Grid item xs={12} sx={editing ? { ...styles.cancelledArea } : {}}>
						<Grid item xs={12} sx={styles.inputCancelledArea}>
							<TextField
								sx={styles.textBox}
								value={intermediaryValue}
								id="outlined-textarea"
								placeholder={t('profile_BIO_button_placeholder')}
								color="primary"
								multiline
								maxRows={5}
								inputRef={inputRef}
								disabled={!editing}
								onChange={(e) => {
									setIntermediaryValue(
										sanitizeInput(e.target.value.substring(0, 140))
									);
								}}
								onClick={() => {
									if (props.editable) {
										setEditing(true);
										buttonClickSfx();
									}
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			</FormControl>
		</Stack>
	);
};
const styles = {
	//MAIN CONTAINER

	bioInputContainer: {
		marginBottom: '1.5em'
	},

	// QUESTIONS & EDIT PEN ICON
	upperBox: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: '0.5em'
	},
	subtitleStyle: {},
	editPenIcon: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center'
	},
	iconStyle: {
		cursor: 'pointer'
	},

	// EDIT OFF

	InputBoxEditOFF: {
		display: 'flex',
		flexDirection: 'row',
		backgroundColor: 'blue'
	},

	textBox: {
		backgroundColor: '#FFFF',
		color: '#282828 !important',
		borderRadius: '0.4em',
		width: '100%',
		'& .MuiInputBase-input': {
			color: '#282828 !important'
		}
	},

	// EDIT ON

	InputBoxEditON: {
		transition: '0.2s'
	},

	cancelledArea: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
		// height: '6.2em'
	},
	rowCancelledArea: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'space-between',
		justifyContent: 'space-between',
		backgroundColor: '#282828',
		borderRadius: '0.5em',
		overflow: 'hiden'
	},
	cancelButton: {
		display: 'flex',
		alignItems: 'center'
	},
	inputCancelledArea: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row'
	},

	icon: {
		display: 'flex',
		position: 'relative',
		alignItems: 'center',
		justifyContent: 'center',
		color: 'primary.main'
	}
};
