import { useContext, useEffect } from 'react';
import { ScrollablePage } from '../../components/page/ScrollablePage';
import { UIContext } from '../../contexts/UIContext';

export const EndingSummarySpeechPage = () => {
	const UIContextValues = useContext(UIContext);

	useEffect(() => {
		if (UIContextValues?.overlaysEventsCompletion?.endingSummaryVideo == false) {
			UIContextValues.checkOverlaysEventsCompletion?.('endingSummaryVideo');
		} else {
			UIContextValues.checkOverlaysEventsCompletion?.('endingSummary');
		}
	}, []);

	return (
		<>
			<ScrollablePage>
				<span></span>
			</ScrollablePage>
		</>
	);
};
