import * as React from 'react';

import { createContext, FC, PropsWithChildren } from 'react';
import buttonClickSfx from '../assets/sounds/button-click.mp3';
import { ParametersContextInterface } from './Contexts.model';

import useSound from 'use-sound';
export const ParametersContext = createContext<ParametersContextInterface>({});

export const ParametersProvider: FC<PropsWithChildren> = ({ children }) => {
	const [soundEnabled, setSoundEnabled] = React.useState(true);

	const [parametersVisibility, setParametersVisibility] = React.useState(false);
	const handleParametersOpen = () => setParametersVisibility(true);
	const handleParametersClose = () => setParametersVisibility(false);

	const [paramOneValue, setParamOneValue] = React.useState<number>(30);
	const [playButtonClick] = useSound(buttonClickSfx, { volume: 0.8, soundEnabled: soundEnabled });

	React.useEffect(() => {
		playButtonClick();
	}, [soundEnabled]);

	return (
		<ParametersContext.Provider
			value={{
				parametersVisibility,
				setParametersVisibility,
				handleParametersOpen,
				handleParametersClose,
				paramOneValue,
				setParamOneValue,
				soundEnabled,
				setSoundEnabled
			}}
		>
			{children}
		</ParametersContext.Provider>
	);
};
