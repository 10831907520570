import { GSheetEntity } from './GSheetEntity';

export class GaugeData extends GSheetEntity {
	id = '';
	name = '...';
	minValue = 0;
	maxValue = 100;
	bestValue = 50;
	startValue = 50;
	minValueGameOverCardId = '';
	maxValueGameOverCardId = '';

	static override GSheetPageId = 'Jauges';

	constructor() {
		super();
	}

	public static getScore(gaugeData: GaugeData, value: number): number {
		let score = 0;
		const range = gaugeData.maxValue - gaugeData.minValue;

		const a =
			gaugeData.bestValue != gaugeData.minValue && gaugeData.bestValue != gaugeData.maxValue
				? gaugeData.bestValue / range
				: 1;

		score = 1 - Math.abs(gaugeData.bestValue - value) / (range * a);

		return Math.round(score * 1000) / 1000;
	}

	public static getScoreCursorPosition(gaugeData: GaugeData, value: number): number {
		const range = gaugeData.maxValue - gaugeData.minValue;

		return 1 - (gaugeData.maxValue - value) / range;
	}
}
