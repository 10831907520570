import { CircularProgress, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MicroLearningCard } from '../../components/MicroLearningCard';
import { Header } from '../../components/page/Header';
import { Navbar } from '../../components/page/Navbar';
import { ScrollablePage } from '../../components/page/ScrollablePage';
import { APIContext } from '../../contexts/API/APIContext';
import { GlobalContext } from '../../contexts/GlobalContext';
import { UIContext } from '../../contexts/UIContext';

export const MicroLearningPageHub = () => {
	const globalContext = React.useContext(GlobalContext);

	const APIContextValues = React.useContext(APIContext);
	const UIContextValues = React.useContext(UIContext);
	const { t } = useTranslation();

	useEffect(() => {
		APIContextValues.getMicroLearnings();
		UIContextValues.checkOverlaysEventsCompletion?.('microlearning');
	}, []);

	return (
		<>
			<Header title={t('micro-learning_HUB_header_title')}></Header>
			<ScrollablePage>
				<Typography
					sx={{
						textAlign: 'center',
						fontWeight: 'bolder',
						paddingTop: '2vh',
						paddingBottom: { xs: '4vh', sm: '1vh' }
					}}
					variant="body1"
				>
					{t('micro-learning_HUB_text_intro')}{' '}
				</Typography>
				<Stack sx={styles.container} spacing={2}>
					{globalContext?.microLearning && globalContext?.microLearning.length > 0 ? (
						globalContext?.microLearning.map((el, i) => {
							return (
								<MicroLearningCard key={`micro_learning_${i}`} microLearning={el} />
							);
						})
					) : (
						<CircularProgress />
					)}
				</Stack>
			</ScrollablePage>
			<Navbar></Navbar>
		</>
	);
};
const styles = {
	container: {
		marginTop: '2em',
		width: '100%',
		position: 'relative',
		alignItems: 'center'
	},
	button: {
		width: '50%',
		margin: '5em'
	}
};
