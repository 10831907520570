import { Grid } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	ChallengeIcon,
	GameIcon,
	HomeIcon,
	LearningIcon,
	PracticeIcon
} from '../../assets/MediaComponents';
import NavbarImage from '../../assets/navbar/navbar.svg';
import { UIContext } from '../../contexts/UIContext';
import { CustomBottomNavigationAction } from '../buttons/CustomBottomNavigationAction';

export const Navbar = () => {
	const [value, setValue] = React.useState<string>('');
	const navigate = useNavigate();
	const location = useLocation();
	const UIContextValues = React.useContext(UIContext);
	const { t } = useTranslation();

	React.useEffect(() => {
		const trunkedPath = `/${location.pathname.split('/')[1]}`;
		setValue(trunkedPath);
	}, [location.pathname]);

	return (
		<Grid container sx={styles.navbarContainer}>
			<BottomNavigation
				showLabels
				sx={styles.navigationBar}
				value={value}
				onChange={(event, newValue) => {
					navigate(newValue, { replace: true });
					setValue(newValue);
				}}
			>
				<CustomBottomNavigationAction
					disabled={!UIContextValues.navbarInteractive}
					sx={styles.navigationButton}
					label={t('homePage_navbar_learning')}
					value={'/micro-learning'}
					icon={LearningIcon}
				/>
				<CustomBottomNavigationAction
					disabled={!UIContextValues.navbarInteractive}
					sx={styles.navigationButton}
					label={t('homePage_navbar_challenge')}
					value={'/challenges'}
					icon={ChallengeIcon}
				/>
				<CustomBottomNavigationAction
					disabled={!UIContextValues.navbarInteractive}
					sx={styles.navigationButton}
					label={t('homePage_navbar_home')}
					value={'/'}
					icon={HomeIcon}
				/>
				<CustomBottomNavigationAction
					disabled={!UIContextValues.navbarInteractive}
					sx={styles.navigationButton}
					label={t('homePage_navbar_practice')}
					value={'/practice'}
					icon={PracticeIcon}
				/>

				<CustomBottomNavigationAction
					disabled={!UIContextValues.navbarInteractive}
					sx={styles.navigationButton}
					label={t('homePage_navbar_game')}
					value={'/game'}
					icon={GameIcon}
				/>
			</BottomNavigation>
		</Grid>
	);
};

const styles = {
	navbarContainer: {
		position: 'absolute',
		bottom: '-0.1em',
		maxWidth: 'xs',
		borderImageSource: {
			xs: 'none',
			sm: `url(${NavbarImage})`
		},
		borderImageSlice: '30% 10% 5% 10% fill',
		borderImageWidth: '20em',
		borderImageOutset: '0.5em 0px 0px 0px',
		borderImageRepeat: 'stretch stretch',
		backgroundColor: {
			xs: '#282828',
			sm: `transparent`
		},
		display: 'flex',
		alignItems: {
			xs: 'flex-start',
			sm: 'flex-end'
		},
		justifyContent: 'center',
		height: {
			xs: '3em',
			sm: '4em'
		},
		zIndex: 11

		// ' & .Mui-selected': {
		//   color: 'primary.main'
		// }
	},

	navigationButton: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'center',
		color: 'common.white',
		gap: '2px',
		padding: '0 !important',
		minWidth: '70px !important',
		'& .MuiBottomNavigationAction-label': {
			fontSize: '0.6em !important',
			textTransform: 'uppercase !important'
		},
		' & .Mui-selected': {
			color: 'primary.main'
		},
		'&:active': {
			color: 'primary.main !important'
		}
	},

	navigationBar: {
		width: '100%',
		backgroundColor: 'transparent',
		alignItems: {
			xs: 'flex-start',
			sm: 'center'
		},
		paddingTop: {
			xs: '0.4em',
			sm: '0'
		}
	}
};
