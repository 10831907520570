import * as React from 'react';

import { createContext, FC, PropsWithChildren } from 'react';
import buttonClickSfx from '../assets/sounds/button-click.mp3';
import { CalendarContextInterface, CalendarUpcomingEvents } from './Contexts.model';

import useSound from 'use-sound';
import { APIContext } from './API/APIContext';
export const CalendarContext = createContext<CalendarContextInterface>(undefined!);

export const CalendarProvider: FC<PropsWithChildren> = ({ children }) => {
	const APIContextValues = React.useContext(APIContext);

	const [soundEnabled] = React.useState(true);
	const [CalendarVisibility, setCalendarVisibility] = React.useState(false);

	const [playButtonClick] = useSound(buttonClickSfx, { volume: 0.8, soundEnabled: soundEnabled });

	const [upcomingEvents, setUpcomingEvents] = React.useState<CalendarUpcomingEvents>({
		LearningEvents: [],
		ChallengesEvents: [],
		PracticesEvents: [],
		MissionEvents: []
	});

	React.useEffect(() => {
		playButtonClick();
	}, [soundEnabled]);

	React.useEffect(() => {
		setUpcomingEvents(APIContextValues.calendar);
	}, [APIContextValues.calendar]);
	return (
		<CalendarContext.Provider
			value={{
				CalendarVisibility,
				setCalendarVisibility,

				upcomingEvents,
				setUpcomingEvents
			}}
		>
			{children}
		</CalendarContext.Provider>
	);
};
