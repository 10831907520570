import { Box, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { FixedPage } from '../../components/page/FixedPage';
import { Header } from '../../components/page/Header';
import { Navbar } from '../../components/page/Navbar';

import { APIContext } from '../../contexts/API/APIContext';
import { MissionContext } from '../../contexts/MissionContext';
import { UIContext } from '../../contexts/UIContext';
import bgScreenImage from '../assets/progress-bg.svg';
import AnimatedBackground from '../components/AnimatedBackground';
import GameChapter from '../components/GameChapter';

export function GameChapterSelectorPage() {
	const UIContextValues = useContext(UIContext);
	const APIContextValues = useContext(APIContext);

	useEffect(() => {
		UIContextValues.checkOverlaysEventsCompletion?.('missions');
		APIContextValues.getMissions();
	}, []);

	const MissionContextValues = useContext(MissionContext);

	return (
		<FixedPage noPadding={true}>
			<AnimatedBackground state={0} zIndex={0} forceVisibility />

			<Header title={'Chapter selection'} />

			<Box sx={style.main}>
				<Box sx={style.content}>
					<Typography variant="h5" sx={{ lineHeight: '1.1', marginBottom: '10px' }}>
						Select the chapter
						<br />
						you would like to play:
					</Typography>

					{MissionContextValues.chapters &&
						MissionContextValues.chapters.map((chapter) => {
							// display only the tutorial if the tutorial is not done
							return <GameChapter key={chapter.id} chapter={chapter} />;
						})}
				</Box>
			</Box>

			<Navbar></Navbar>
		</FixedPage>
	);
}

const style = {
	main: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		height: '100%',
		position: 'relative',
		fontSize: '10px'
	},
	content: {
		width: 'calc(80% - 20px)',
		padding: '2em',
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		backgroundImage: `url(${bgScreenImage})`,
		backgroundSize: '1.2em',
		border: '1em solid #282828',
		alignItems: 'center',
		flexDirection: 'column'
	}
};
