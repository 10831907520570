import { Box, Stack, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import mursionImg from '../assets/elements2D/mursion.png';
import { GlobalButton } from '../components/buttons/GlobalButton';
import { BorderColorEnum } from '../components/containers/CornerContainer';
import { Header } from '../components/page/Header';
import { Navbar } from '../components/page/Navbar';
import { ScrollablePage } from '../components/page/ScrollablePage';
import { UIContext } from '../contexts/UIContext';
import { ConfirmationPopup, ConfirmationPopupMethods } from '../popup/ConfirmationPopup';

export const PracticePage = () => {
	const { t } = useTranslation();

	const confirmationPopupRef = React.useRef<ConfirmationPopupMethods>(null);

	const UIContextValues = React.useContext(UIContext);

	useEffect(() => {
		UIContextValues.checkOverlaysEventsCompletion?.('practice');
	}, []);

	return (
		<>
			<Header title={t('practice_header_title')}></Header>
			<ScrollablePage>
				<Stack sx={styles.textContainer} spacing={3}>
					<Typography variant="h2">{t('practice_subtitle_mursion')}</Typography>
					<Typography variant="body1" sx={{ textAlign: 'justify' }}>
						{t('practice_text_mursion')}
					</Typography>
					<Typography variant="body1" sx={{ textAlign: 'justify' }}>
						{t('practice_text_mursion_2')}
					</Typography>
					<Box
						component="img"
						sx={styles.image}
						src={`${mursionImg}`}
						alt={'mursion_image'}
					></Box>
					<Typography variant="h2">{t('practice_subtitle_book')}</Typography>
					<Typography variant="body1" sx={{ textAlign: 'justify' }}>
						{t('practice_text_book')}
					</Typography>

					<Typography variant="h2">{t('practice_subtitle_join')}</Typography>
					<Typography variant="body1" sx={{ textAlign: 'justify' }}>
						{t('practice_text_join')}
					</Typography>

					<GlobalButton
						onClick={() => {
							confirmationPopupRef.current?.setOpen(true);
						}}
					>
						{t('practice_button_go_to')}
					</GlobalButton>
				</Stack>
			</ScrollablePage>

			<ConfirmationPopup
				ref={confirmationPopupRef}
				borderColor={BorderColorEnum.warning}
				title={t('confirmation_popup_title_challenge')}
				onYesClick={() => {
					axios.get('/api/mursionUrl').then((e) => {
						window.open(e.data.url, '_blank', 'noreferrer');
					});
				}}
			>
				<Typography>{t('practice_content')}</Typography>
			</ConfirmationPopup>
			<Navbar></Navbar>
		</>
	);
};
const styles = {
	textContainer: {
		marginTop: '2em',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%'
	},
	image: {
		width: '100%'
	}
};
