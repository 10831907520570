import { Typography } from '@mui/material';

export const Version = () => {
	// VERSION DYMANIQUE NAME FROM PACKAGE.JSON
	return <Typography sx={styles.version}>V 0.9.31</Typography>;
};
const styles = {
	version: {
		fontSize: '1em'
	}
};
