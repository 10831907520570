import React, { useCallback, useEffect, useState } from 'react';

import { createContext, FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toggleHighlighting } from '../components/TutorialOverlay';
import { UpdatingProfile } from '../contexts/API/APIFechers';
import { APIContext } from './API/APIContext';
import { UIContextInterface } from './Contexts.model';
import { Notification, PopupData, PopupData2 } from './Contexts.types';
import { UserContext } from './UserContext';

export const UIContext = createContext<UIContextInterface>(undefined!);

export const UIProvider: FC<PropsWithChildren> = ({ children }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const APIContextValues = React.useContext(APIContext);
	const UserContextValues = React.useContext(UserContext);

	const [endingSummaryTrigger, setEndingSummaryTrigger] = useState<boolean>(false);

	const [endingSummaryVisibility, setEndingSummaryVisibility] = useState<boolean>(false);

	const [overlaysEventsCompletion, setOverlaysEventsCompletion] = useState<{
		[key: string]: boolean;
	}>({
		introduction: false,
		home: false,
		profile: false,
		challenges: false,
		microlearning: false,
		missions: false,
		practice: false,
		endingSummarySpeech: true,
		endingSummaryVideo: false,
		endingSummary: false
	});

	const overlaysEvents: { [key: string]: (() => void)[] } = {
		introduction: [
			() => {
				setAIDialogTextList([
					t('introduction_text_dialog_1'),
					t('introduction_text_dialog_2')
				]);

				setDialogAIVisibility(true);
				setInsideShipOverlayVisibility(true);
				setTutorialOverlayVisibility(false);
				setShipsVisibility(false);
				setNavbarInteractive(false);
				setHeaderInteractive(false);
			},
			() => {
				setDialogAIVisibility(false);
				setIntroductionTeamsVisibility(true);
				toggleHighlighting('my-team');
			},
			() => {
				setDialogAIVisibility(false);
				setInsideShipOverlayVisibility(false);

				setHeaderInteractive(true);
				setNavbarInteractive(true);
				setShipsVisibility(true);
				setAsCompleteOverlayCompletion('introduction');
			}
		],
		home: [
			() => {
				setShipsVisibility(true);
				setInsideShipOverlayVisibility(false);

				setNavbarInteractive(false);
				setHeaderInteractive(false);

				setTutorialOverlayVisibility(true);
				toggleHighlighting('my-spaceship', true);

				setTutorialOverlayData({
					title: t('tutorial_home_title'),
					description: t('tutorial_home_content_1')
				});
			},
			() => {
				toggleHighlighting('my-spaceship', false);
				toggleHighlighting('my-points', true);
				toggleHighlighting('settings', true);

				setTutorialOverlayData({
					title: t('tutorial_home_title'),
					description: t('tutorial_home_content_2')
				});
			},
			() => {
				toggleHighlighting('my-points', false);
				toggleHighlighting('settings', false);
				toggleHighlighting('header', false);

				setDialogAIVisibility(false);
				setInsideShipOverlayVisibility(false);
				setTutorialOverlayVisibility(false);
				setShipsVisibility(true);
				setNavbarInteractive(true);
				setHeaderInteractive(true);

				setAsCompleteOverlayCompletion('home');
			}
		],
		profile: [
			() => {
				setTutorialOverlayVisibility(true);
				toggleHighlighting('my-team-profile', true);
				setNavbarInteractive(false);
				setHeaderInteractive(false);

				setTutorialOverlayData({
					title: t('tutorial_profile_title'),
					description: t('tutorial_profile_content_1')
				});
			},
			() => {
				toggleHighlighting('my-team-profile', false);

				setTutorialOverlayData({
					title: t('tutorial_profile_title'),
					description: t('tutorial_profile_content_2')
				});

				window.scrollTo(0, document.body.scrollHeight);
			},
			() => {
				setNavbarInteractive(true);

				setTutorialOverlayVisibility(false);
				setNavbarInteractive(true);
				setHeaderInteractive(true);

				setAsCompleteOverlayCompletion('profile');
			}
		],
		challenges: [
			() => {
				setTutorialOverlayVisibility(true);
				toggleHighlighting('challenges', true);
				setHeaderInteractive(false);
				setNavbarInteractive(false);

				setTutorialOverlayData({
					title: t('tutorial_challenge_title'),
					description: t('tutorial_challenge_content_1')
				});
			},

			() => {
				setTutorialOverlayVisibility(false);
				setNavbarInteractive(true);
				setHeaderInteractive(true);

				setAsCompleteOverlayCompletion('challenges');
			}
		],
		microlearning: [
			() => {
				setTutorialOverlayVisibility(true);
				setNavbarInteractive(false);
				setHeaderInteractive(false);

				setTutorialOverlayData({
					title: t('tutorial_learning_title'),
					description: t('tutorial_learning_content_1')
				});
			},

			() => {
				setTutorialOverlayVisibility(false);
				setNavbarInteractive(true);
				setHeaderInteractive(true);

				setAsCompleteOverlayCompletion('microlearning');
			}
		],
		missions: [
			() => {
				setTutorialOverlayVisibility(true);
				setNavbarInteractive(false);
				setHeaderInteractive(false);

				setTutorialOverlayData({
					title: t('tutorial_mission_title'),
					description: t('tutorial_mission_content_1')
				});
			},
			() => {
				setTutorialOverlayData({
					title: t('tutorial_mission_title'),
					description: t('tutorial_mission_content_2')
				});
			},
			() => {
				setTutorialOverlayData({
					title: t('tutorial_mission_title'),
					description: t('tutorial_mission_content_3')
				});
			},
			() => {
				setTutorialOverlayVisibility(false);
				setNavbarInteractive(true);
				setHeaderInteractive(true);

				setAsCompleteOverlayCompletion('missions');
			}
		],
		practice: [
			() => {
				setTutorialOverlayVisibility(true);
				setNavbarInteractive(false);
				setHeaderInteractive(false);

				setTutorialOverlayData({
					title: t('tutorial_practice_title'),
					description: t('tutorial_practice_content_1')
				});
			},
			() => {
				setTutorialOverlayVisibility(false);
				setNavbarInteractive(true);

				setHeaderInteractive(true);

				setAsCompleteOverlayCompletion('practice');
			}
		],
		endingSummarySpeech: [
			() => {
				navigate('/ending-summary-video', { replace: true });
				setDialogAIVisibility(false);
				setInsideShipOverlayVisibility(false);
				setAsCompleteOverlayCompletion('endingSummarySpeech');
			}
		],
		endingSummaryVideo: [
			() => {
				navigate('/ending-summary-video', { replace: true });
			}
		],
		endingSummary: [
			() => {
				navigate('/ending-summary', { replace: true });
			}
		]
	};

	const [currentOverlaysEventsIndex, setCurrentOverlaysEventsIndex] = useState(0);

	const [currentOverlaysEvents, setCurrentOverlaysEvents] = useState<(() => void)[]>([]);

	const [shipsVisibility, setShipsVisibility] = useState<boolean>(false);
	const [insideShipOverlayVisibility, setInsideShipOverlayVisibility] = useState<boolean>(false);

	const [headerInteractive, setHeaderInteractive] = useState<boolean>(true);
	const [navbarInteractive, setNavbarInteractive] = useState<boolean>(true);
	const [leaderboardInteractive, setLeaderboardInteractive] = useState<boolean>(true);

	const [dialogAIVisibility, setDialogAIVisibility] = useState<boolean>(false);
	const [MenuVisibility, setMenuVisibility] = useState<boolean>(false);

	const [tutorialOverlayData, setTutorialOverlayData] = useState<{
		title: string;
		description: string;
	}>({
		title: '',
		description: ''
	});

	const [tutorialOverlayVisibility, setTutorialOverlayVisibility] = useState<boolean>(false);

	const [introductionTeamsVisibility, setIntroductionTeamsVisibility] = useState<boolean>(false);

	const [AIdialogTextList = [], setAIDialogTextList] = useState<string[]>([]);

	const [errorPopupVisibility, setErrorPopupVisibility] = useState<boolean>(false);
	const [errorPopupText, setErrorPopupText] = useState<PopupData>({
		title: t('homePage_popup_error_title'),
		description: ''
	});

	const [leaderboardVisibility, setLeaderboardVisibility] = React.useState<boolean>(false);

	const [successPopupVisibility, setSuccessPopupVisibility] = useState<boolean>(false);
	const [successPopupText, setSuccessPopupText] = useState<PopupData>({
		title: t('homePage_popup_success_title'),
		description: ''
	});

	const [notificationList, setNotificationList] = useState<Notification[]>([]);

	const [notificationPopupVisibility, setNotificationPopupVisibility] = useState<boolean>(false);
	const [notificationPopupText, setNotificationPopupText] = useState<PopupData2>({
		title: '',
		description: '',
		challengeId: 0,
		isBonus: false
	});

	useEffect(() => {
		const overlaysEventsCompletionStorage = localStorage.getItem('overlaysEventsCompletion');

		if (overlaysEventsCompletionStorage) {
			setOverlaysEventsCompletion(JSON.parse(overlaysEventsCompletionStorage));
		}

		if (APIContextValues.myProfile) {
			setOverlaysEventsCompletion({
				introduction: APIContextValues?.myProfile?.introductionFlag || false,
				home: APIContextValues?.myProfile?.homeFlag || false,
				profile: APIContextValues?.myProfile?.profileFlag || false,
				challenges: APIContextValues?.myProfile?.challengesFlag || false,
				microlearning: APIContextValues?.myProfile?.microlearningFlag || false,
				missions: APIContextValues?.myProfile?.missionsFlag || false,
				practice: APIContextValues?.myProfile?.practiceFlag || false,
				endingSummarySpeech: false,
				endingSummaryVideo: false,
				endingSummary: false
			});
		}
	}, [APIContextValues.myProfile]);

	// Update notification list when APIContextValues.notificationList changes
	useEffect(() => {
		setNotificationList(APIContextValues.notificationList);

		if (APIContextValues.notificationList.length > 0) {
			const notification = APIContextValues.notificationList[0];
			displayNotificationPopup(
				notification.title,
				notification.description,
				notification.challengeId,
				notification.isBonus
			);
		}
	}, [APIContextValues.notificationList]);

	const startOverlaysEvents = () => {
		setCurrentOverlaysEventsIndex(0);
		currentOverlaysEvents[0]?.();
	};

	const nextOverlaysEvents = () => {
		const nextOverlaysEventsIndex = currentOverlaysEventsIndex + 1;

		if (nextOverlaysEventsIndex >= currentOverlaysEvents.length) {
			// console.log("overlays events completed")
			return;
		}

		setCurrentOverlaysEventsIndex(nextOverlaysEventsIndex);
		currentOverlaysEvents[nextOverlaysEventsIndex]();
	};

	const updateFlag = (flagKey: string, flagValue: boolean) => {
		if (APIContextValues?.myProfile && flagKey in APIContextValues.myProfile) {
			const UpdatingProfile: UpdatingProfile = {
				avatar: APIContextValues.myProfile.avatar,
				bio: APIContextValues.myProfile.BIO || UserContextValues.defaultBIO,
				teamId: APIContextValues.myProfile.teamId,
				userID: APIContextValues.myProfile.id,
				[flagKey]: flagValue
			};

			APIContextValues.updateMyProfile(UpdatingProfile);
		}
	};

	const setAsCompleteOverlayCompletion = (_key: string) => {
		const newOverlaysEventsCompletion = { ...overlaysEventsCompletion, [_key]: true };

		setOverlaysEventsCompletion(newOverlaysEventsCompletion);

		updateFlag(_key + 'Flag', true);
		// save local storage
		localStorage.setItem(
			'overlaysEventsCompletion',
			JSON.stringify(newOverlaysEventsCompletion)
		);
		// console.log('set as complete overlay completion');
	};

	const checkOverlaysEventsCompletion = useCallback(
		(_key: string) => {
			// retrieve local storage of overlaysCompletion
			let overlaysEventsCompletionLocalStorage = JSON.parse(
				localStorage.getItem('overlaysEventsCompletion') ||
					JSON.stringify({ ...overlaysEventsCompletion })
			);

			const keyExist =
				Object.keys(overlaysEventsCompletionLocalStorage)?.includes(_key) &&
				Object.keys(overlaysEvents)?.includes(_key);

			if (!keyExist) return;

			const flagKey = _key + 'Flag';
			const isFlagCompleted = APIContextValues.myProfile[flagKey];
			const isCompleted = overlaysEventsCompletionLocalStorage[_key] || isFlagCompleted;

			if (isCompleted) return;

			setCurrentOverlaysEvents(overlaysEvents[_key]);

			setCurrentOverlaysEventsIndex(0);
			overlaysEvents[_key][0]?.();
		},
		[overlaysEventsCompletion, overlaysEvents, currentOverlaysEvents]
	);

	const displayNotificationPopup = (
		title: string,
		description: string,
		challengeId: number,
		isBonus: boolean
	) => {
		console.log(isBonus);
		setNotificationPopupText({ title, description, challengeId, isBonus });
		setNotificationPopupVisibility(true);
	};

	const resetOverlaysEventsCompletion = () => {
		const newOverlaysEventsCompletion: {
			[key: string]: boolean;
		} = { ...overlaysEventsCompletion };

		Object.keys(newOverlaysEventsCompletion).forEach((key) => {
			newOverlaysEventsCompletion[key] = false;
		});

		setOverlaysEventsCompletion(newOverlaysEventsCompletion);
		// save local storage
		localStorage.setItem(
			'overlaysEventsCompletion',
			JSON.stringify(newOverlaysEventsCompletion)
		);
	};

	return (
		<UIContext.Provider
			value={{
				/**
				 * UI
				 */

				shipsVisibility,
				setShipsVisibility,
				insideShipOverlayVisibility,
				setInsideShipOverlayVisibility,

				headerInteractive,
				setHeaderInteractive,

				navbarInteractive,
				setNavbarInteractive,

				leaderboardInteractive,
				setLeaderboardInteractive,

				dialogAIVisibility,
				setDialogAIVisibility,

				tutorialOverlayVisibility,
				setTutorialOverlayVisibility,
				tutorialOverlayData,
				setTutorialOverlayData,
				checkOverlaysEventsCompletion,

				leaderboardVisibility,
				setLeaderboardVisibility,

				AIdialogTextList,
				setAIDialogTextList,

				introductionTeamsVisibility,
				setIntroductionTeamsVisibility,

				startOverlaysEvents,
				nextOverlaysEvents,

				resetOverlaysEventsCompletion,
				setAsCompleteOverlayCompletion,

				currentOverlaysEvents,
				setCurrentOverlaysEvents,

				overlaysEventsCompletion,

				// ERROR POPUP
				errorPopupVisibility,
				setErrorPopupVisibility,
				errorPopupText,
				setErrorPopupText,

				// SUCCESS POPUP
				successPopupVisibility,
				setSuccessPopupVisibility,
				successPopupText,
				setSuccessPopupText,

				endingSummaryTrigger,
				setEndingSummaryTrigger,

				endingSummaryVisibility,
				setEndingSummaryVisibility,

				MenuVisibility,
				setMenuVisibility,

				// NOTIFICATION POPUP
				notificationPopupVisibility,
				setNotificationPopupVisibility,
				notificationPopupText,
				setNotificationPopupText,
				displayNotificationPopup,
				notificationList,
				setNotificationList
			}}
		>
			{children}
		</UIContext.Provider>
	);
};
