import {
	createContext,
	FC,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useState
} from 'react';
import { FakeDataMyTeam, FakeDataMyUser } from '../fakeDatas/fakeDataGenerator';
import { UserContextInterface } from './Contexts.model';
import { Avatar, BIO, Team, User } from './Contexts.types';
import { GlobalContext } from './GlobalContext';
import { APIContext } from './API/APIContext';

export const UserContext = createContext<UserContextInterface>(undefined!);

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
	const APIContextValues = useContext(APIContext);
	const GlobalContextValues = useContext(GlobalContext);

	const [firstLogin, setFirstLogin] = useState<boolean>(true);

	const [MAX_TEAM_SCORE] = useState<number>(125000);
	const [MAX_INDIVIDUAL_SCORE] = useState<number>(7500);

	const [challengesProgression, setChallengesProgression] = useState([
		{
			id: 0,
			submitted: false,
			corrected: false
		},
		{
			id: 1,
			submitted: false,
			corrected: false
		}
	]);

	// eslint-disable-next-line
	const [defaultBIO, setDefaultBIO] = useState<BIO>({
		motto: '',
		amazing: '',
		trust: '',
		improve: ''
	});

	const [myProfile, setMyProfile] = useState<User>(FakeDataMyUser);
	const [myTeam, setMyTeam] = useState<Team>(FakeDataMyTeam);

	useEffect(() => {
		setMyProfile(APIContextValues.myProfile);
	}, [APIContextValues.myProfile]);

	useEffect(() => {
		setMyTeam(APIContextValues.myTeam);
	}, [APIContextValues.myTeam]);

	const setMyBIO = useCallback(
		(_bio: BIO) => {
			setMyProfile({ ...myProfile, BIO: _bio });

			// update user leaderboard
			const newLeaderboard = GlobalContextValues?.usersLeaderboard?.map((user) => {
				if (user.id === myProfile.id) {
					return {
						...user,
						BIO: _bio
					};
				}
				return user;
			}) as User[];

			GlobalContextValues?.setUsersLeaderboard?.(newLeaderboard);
		},
		[myProfile]
	);

	const setMyAvatar = useCallback(
		(_avatar: Avatar) => {
			setMyProfile({ ...myProfile, avatar: _avatar });

			// update user leaderboard
			const newLeaderboard = GlobalContextValues?.usersLeaderboard?.map((user) => {
				if (user.id === myProfile.id) {
					return {
						...user,
						avatar: _avatar
					};
				}
				return user;
			}) as User[];

			GlobalContextValues?.setUsersLeaderboard?.(newLeaderboard);
		},
		[myProfile]
	);

	const submitChallenge = useCallback(
		(id: number) => {
			const newChallengesProgression = challengesProgression.map((challenge) => {
				if (challenge.id === id) {
					return {
						...challenge,
						submitted: true
					};
				}
				return challenge;
			});

			setChallengesProgression(newChallengesProgression);
		},
		[challengesProgression]
	);

	return (
		<UserContext.Provider
			value={{
				MAX_TEAM_SCORE,
				MAX_INDIVIDUAL_SCORE,
				myProfile,
				setMyBIO,
				setMyAvatar,
				myTeam,
				defaultBIO,

				challengesProgression,
				submitChallenge,

				firstLogin,
				setFirstLogin
			}}
		>
			{children}
		</UserContext.Provider>
	);
};
