import { GSheetEntity } from './GSheetEntity';

export class CardData extends GSheetEntity {
	static CARD_TYPE_NARRATIVE = 'narrative';
	static CARD_TYPE_EVENT = 'event';

	static ACTION_TYPE_SHOW_CARD = 'show card';
	static ACTION_TYPE_SHOW_RANDOM_EVENT_CARD = 'show random event card';
	static ACTION_TYPE_END_GAME = 'show score';
	static ACTION_TYPE_GAME_OVER = 'game over';

	static override GSheetPageId = 'Cartes';

	id = '';
	chapterId = '';
	sectionId = '';
	type: string = CardData.CARD_TYPE_NARRATIVE;
	image = '';
	mainText = '';

	actionLeftType: string = CardData.ACTION_TYPE_SHOW_RANDOM_EVENT_CARD;
	actionLeftText = '';
	actionLeftParam = '';
	actionLeftGauge1 = 0;
	actionLeftGauge2 = 0;
	actionLeftGauge3 = 0;

	actionRightType: string = CardData.ACTION_TYPE_SHOW_RANDOM_EVENT_CARD;
	actionRightText = '';
	actionRightParam = '';
	actionRightGauge1 = 0;
	actionRightGauge2 = 0;
	actionRightGauge3 = 0;

	constructor() {
		super();
	}
}
