import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CardNarrativeContentProps } from './GameComponents.model';

export default function CardNarrativeContent({ content }: CardNarrativeContentProps): JSX.Element {
	const smallHeight = useMediaQuery('(max-height:800px)');

	return (
		<Box sx={{ ...style.main, ...{ fontSize: smallHeight ? '9px' : '10px' } }}>
			<Box sx={style.content}>{content}</Box>
		</Box>
	);
}

const style = {
	main: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	content: {
		textAlign: 'center',
		backgroundColor: '#ffffffd1',
		color: 'black',
		width: '100%',
		padding: '1.5em 1.5em',
		fontSize: '1.3em',
		lineHeight: '1.1'
	}
};
