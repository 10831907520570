import { AppBar, Container, Modal, Toolbar, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect } from 'react';
import { CloseButton } from '../components/buttons/CloseButton';
import {
	BorderColorEnum,
	ContentDisplayEnum,
	CornerContainer
} from '../components/containers/CornerContainer';

import useSound from 'use-sound';
import closePopupSfx from '../assets/sounds/close-popup.mp3';
import openPopupSfx from '../assets/sounds/open-popup.mp3';
import { ParametersContext } from '../contexts/ParametersContext';

export interface PopupContainerProps {
	title: string;
	open: boolean;
	children: React.ReactNode;
	onClickCloseButton: any;
	contentdisplay?: ContentDisplayEnum;
	borderColor?: BorderColorEnum;
	disableClosable?: boolean;
}

export const PopupContainer = (props: PopupContainerProps) => {
	const [titleColorText, setTitleColorText] = React.useState('text.primary');

	const ParametersContextValues = React.useContext(ParametersContext);
	const [openSfx] = useSound(openPopupSfx, {
		volume: 0.4,
		soundEnabled: ParametersContextValues?.soundEnabled
	});
	const [closeSfx] = useSound(closePopupSfx, {
		volume: 0.4,
		soundEnabled: ParametersContextValues?.soundEnabled
	});
	useEffect(() => {
		if (props.open) {
			openSfx();
		}
		if (!props.open) {
			closeSfx();
		}
	}, [props.open]);

	useEffect(() => {
		switch (props.borderColor) {
			case BorderColorEnum.normal:
				setTitleColorText('text.primary');
				break;
			case BorderColorEnum.warning:
				setTitleColorText('error.main');
				break;
			case BorderColorEnum.success:
				setTitleColorText('text.primary');
				break;
			default:
				setTitleColorText('text.primary');
				break;
		}
	}, [props.borderColor]);

	return (
		<Box sx={styles.modalSizeMax}>
			<Modal
				sx={styles.modalContainer}
				open={props.open}
				onClose={(e, reason) => {
					if (reason == 'backdropClick' && props.disableClosable) return;

					props.onClickCloseButton(e);
				}}
			>
				<Container maxWidth={'xs'} sx={styles.popUp}>
					<CornerContainer
						borderColor={props.borderColor}
						contentdisplay={props.contentdisplay}
					>
						<Box sx={styles.backgroundPopUp}>
							<AppBar sx={styles.title} position="static">
								<Toolbar>
									<Typography
										variant="h4"
										sx={{ flexGrow: 1, color: titleColorText }}
										textAlign={'center'}
										textTransform={'uppercase'}
									>
										{props.title}
									</Typography>
								</Toolbar>

								{!props.disableClosable && (
									<CloseButton onClick={props.onClickCloseButton} />
								)}
							</AppBar>
							<Box sx={styles.scrollInsideContainer}>
								<Box sx={styles.contentPopUp}>{props.children}</Box>
							</Box>
						</Box>
					</CornerContainer>
				</Container>
			</Modal>
		</Box>
	);
};

const styles = {
	modalSizeMax: {
		// minWidth: 'xs',
		// Maxheight: "900px",
	},
	modalContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& :focus': {
			outline: 'none !important'
		},

		'& .MuiBackdrop-root': {
			backgroundColor: 'rgba(0, 0, 0, 0.8)' // couleur de l'arrière-plan de la modal
		}
	},
	popUp: {
		width: '100%',
		height: '80%',
		padding: '2em',
		maxHeight: {
			md: '800px',
			lg: '800px'
		},
		// paddingTop: '5em',
		// paddingBottom: '5em',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		alignItems: 'center',
		// backgroundColor: 'red',
		overflow: 'hidden'
	},

	backgroundPopUp: {
		width: '100%',
		height: {
			xs: '98%',
			sm: '100%'
		}
		// backgroundColor: 'purple',
		// marginBottom: '1em'
	},
	scrollInsideContainer: {
		// backgroundColor: 'blue',
		width: '99%',
		height: {
			xs: '82%',
			sm: '89%'
		},
		overflow: 'scroll',
		overflowY: 'scroll',
		overflowX: 'hidden'
	},
	contentPopUp: {
		padding: '2em',
		paddingTop: '0 !important',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		gap: '1em'
	},
	title: {
		backgroundColor: 'transparent !important',
		// backgroundColor: 'green',
		boxShadow: 'none',
		marginTop: '0.5em'
	}
};
