import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { User } from '../../contexts/Contexts.types';
import { CustomAvatar } from './CustomAvatar';

import { useContext } from 'react';
import useSound from 'use-sound';
import clickButtonSfx from '../../assets/sounds/button-click.mp3';
import { ParametersContext } from '../../contexts/ParametersContext';
import { UIContext } from '../../contexts/UIContext';

interface PlayerPictureProps {
	user?: User;
	disabled?: boolean;
}

export const PlayerPicture = ({ disabled = false, ...props }: PlayerPictureProps) => {
	const navigate = useNavigate();
	const UIContextValues = useContext(UIContext);
	const ParametersContextValues = useContext(ParametersContext);

	const [buttonClickSfx] = useSound(clickButtonSfx, {
		volume: 0.8,
		soundEnabled: ParametersContextValues?.soundEnabled
	});

	return (
		<Button
			disabled={disabled}
			sx={{
				...styles.container,
				transform: {
					xs: 'scale(0.7)',
					sm: 'scale(0.8)'
				}
			}}
			onClick={() => {
				buttonClickSfx();
				navigate(`/profile/${props.user?.id}`);
				UIContextValues.setLeaderboardVisibility(false);
			}}
		>
			<CustomAvatar key={props.user?.id} avatarInfos={props.user} />
		</Button>
	);
};
const styles = {
	container: {
		backgroundColor: '#060C26',
		height: '4.5em',
		width: '4.5em',
		borderRadius: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden',
		border: '0.2em solid #FFFF',
		svg: {
			transform: 'scale(1.6)',
			marginTop: {
				xs: '5vh',
				sm: '4vh',
				md: '4vh'
			}
		}
	}
};
