/* eslint-disable */

export type UserHistory = {
	challenges: Challenge[];
	missions: Mission[];
};

export type User = {
	id: number;
	name: string;
	avatar: Avatar;
	BIO: BIO;
	score: number | undefined;
	rank: number;
	teamId: number;
	history: UserHistory;
	homeFlag: boolean;
	introductionFlag: boolean;
	profileFlag: boolean;
	challengesFlag: boolean;
	microlearningFlag: boolean;
	missionsFlag: boolean;
	practiceFlag: boolean;
	[key: string]: boolean | number | string | Avatar | BIO | UserHistory | undefined;
};

export type BIO = {
	motto: string;
	amazing: string;
	trust: string;
	improve: string;
};

export type Team = {
	id: number;
	name: string;
	score: number;
	fileType: string | undefined;
	filePath: string | undefined;
	fileName: string | undefined;
	scanStatus: string | undefined;
	uploadDate: string | undefined;
	uploadedBy: string | undefined;
	rank: number;
	members: User[];
	base64: string;
};

export type TeamMember = {
	id: number;
	name: string;
	teamId: number;
	score: number;
	rank: number;
	avatar: Avatar;
	bio: BIO;
};

export type Avatar = {
	color: string;
	accessoryName: AvatarAccessory | string;
};

export enum AvatarAccessory {
	CAPE = 'profile_avatar_accessory_cape',
	TIE = 'profile_avatar_accessory_tie',
	DIVING_MASK = 'profile_avatar_accessory_diving_mask',
	ANGEL = 'profile_avatar_accessory_angel',
	SPACE_BAG = 'profile_avatar_accessory_space_bag',
	BLACK_BELT = 'profile_avatar_accessory_black_belt',
	BOB = 'profile_avatar_accessory_bob',
	BOW_TIE = 'profile_avatar_accessory_bow_tie',
	CAP = 'profile_avatar_accessory_cap',
	LEAF = 'profile_avatar_accessory_leaf',
	HAT = 'profile_avatar_accessory_hat',
	RED_CAP = 'profile_avatar_accessory_red_cap',
	SCARF = 'profile_avatar_accessory_scarf',
	TSHIRT = 'profile_avatar_accessory_cmacgm_tshirt',
	ALIEN = 'profile_avatar_accessory_alien',
	TUTU = 'profile_avatar_accessory_dance_dress',
	HEADSET = 'profile_avatar_accessory_headset',
	BAT = 'profile_avatar_accessory_bat',
	PIRATE = 'profile_avatar_accessory_pirate',
	COOKER = 'profile_avatar_accessory_cooker',
	APRON = 'profile_avatar_accessory_apron',
	KNIGHT = 'profile_avatar_accessory_knight',
	SHERIF = 'profile_avatar_accessory_sherif',
	SWORD = 'profile_avatar_accessory_sword'
}

export type AvatarAccessorySvg = {
	name: AvatarAccessory;
	component: React.ReactNode;
};

export enum ChallengeStatus {
	UPCOMING = 'upcoming',
	AVAILABLE = 'available',
	SUBMITTED = 'submitted'
}

export type Challenge = {
	id: number;
	name: string;
	challengeType: string;
	complexity: string;
	topic: string;
	maxPoints: number;
	instructions: string;
	announcement: string;
	deliverables: string;
	successCondition: string[];
	evaluationCriteria: string;
	startDate: number;
	endDate: number;
	sessionId: number;
	mediaFormats: string[];

	score: number | null;
	status: ChallengeStatus;
};

export type Video = {
	themeTitle: string;
	descriptionTheme: string;
	title: string;
	description: string;
	url: string;
};

export type Mission = {
	id: number;
	name: string;
	score: number;
	durationInMinutes: number;
	sessionId: number;
	startDate: number;
	endDate: number;
	displayName: string;
};

export type ChallengesProgression = {
	id: number;
	completion: boolean;
};

export type MissionProgression = {
	id: number;
	name: string | 'demo' | 'tutorial' | 'diversity' | 'trust' | 'emotions';
	score: number;
	locked: boolean;
	durationInMinutes: number;
	displayName: string;
};

export type MicroLearning = {
	id: number;
	type: 'video' | 'conference' | 'other' | string;
	title: string;
	link: string;
	thumbnailLink: string;
	externalThumbnailLink: boolean;
	externalLink: boolean;
	shortDescription: string;
	description: string;
	base64: string;
	name: string;
	miscellaneousType: string;
};

export enum CalendarEventType {
	MISSION = 'mission',
	LEARNING = 'learning',
	CONF_LEARNING = 'Conference_learning',
	VIDEO_LEARNING = 'Video_learning',
	CHALLENGE = 'challenge',
	PRACTICE = 'Practice'
}

export type CalendarEventItem = {
	type: CalendarEventType;
	title: string;
};

export type PopupData = {
	title: string;
	description: string;
};

export type PopupData2 = {
	title: string;
	description: string;
	challengeId: number;
	isBonus: boolean;
};

export type Notification = {
	id: number;
	title: string;
	description: string;
	read: boolean;
	createdDate: Date;
	updatedDate: Date;
	userId: number;
	challengeId: number;
	isBonus: boolean;
	bonusType: string;
};

export type UserInfos = {
	firstName: string;
	lastName: string;
	email: string;
	active: true;
	role: string;
	id: number;
	ccgId: string;
};
