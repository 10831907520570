import { Box } from '@mui/material';
import { useEffect } from 'react';
import { AvatarAccessoryList, AvatarModelSvg } from '../../assets/MediaComponents';
import { Avatar } from '../../contexts/Contexts.types';

interface AvatarProps {
	size?: number | Object;
	avatarInfos?: {
		id?: number;
		avatar?: Avatar;
	};
}

export const CustomAvatar = (props: AvatarProps) => {
	useEffect(() => {
		const customColorEl = document.getElementById(
			`avatar_custom_color_${props.avatarInfos?.id}`
		);

		if (customColorEl) {
			customColorEl.style.fill = props.avatarInfos?.avatar?.color || '#FFF';
		} else {
			console.log('customColorEl not found');
		}
	}, [props.avatarInfos]);

	return (
		<Box component="div" sx={styles.avatar}>
			<Box sx={styles.avatarModelContainer}>
				<AvatarModelSvg userid={props.avatarInfos?.id}></AvatarModelSvg>
			</Box>

			<Box sx={styles.avatarModelContainer}>
				{AvatarAccessoryList.map((accessory) => {
					if (
						accessory.name.valueOf() ===
						props.avatarInfos?.avatar?.accessoryName.valueOf()
					) {
						return accessory.component;
					}
				})}
			</Box>
		</Box>
	);
};

const styles = {
	avatar: {
		position: 'relative',
		height: {
			xs: '30vh',
			sm: '20em',
			md: '20em'
		},
		width: '100%',
		// backgroundColor: 'purple',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'

		// marginTop: '3em',
		// padding: '2em'
	},

	avatarModelContainer: {
		position: 'absolute',
		display: 'flex',
		height: '100%',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',

		// backgroundColor: 'red',
		'& svg': {
			height: '100%'
		}
	}
};
