import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LeaderboardRank } from '../components/LeaderboardRank';
import { BorderColorEnum, ContentDisplayEnum } from '../components/containers/CornerContainer';
import { TabPanel } from '../components/containers/TabPanel';
import { GlobalContext } from '../contexts/GlobalContext';
import { UIContext } from '../contexts/UIContext';
import { UserContext } from '../contexts/UserContext';
import { PopupContainer } from './PopupContainer';

export const IntroductionTeamsPopup = () => {
	const { t } = useTranslation();

	// eslint-disable-next-line
	const [tabPanelValue, setTabPanelValue] = React.useState(0);

	const GlobalContextValues = React.useContext(GlobalContext);
	const UIContextValues = React.useContext(UIContext);

	const UserContextValues = React.useContext(UserContext);

	useEffect(() => {
		// order team and user by score

		// axios
		// 	.get('/api/sm/sessions', {
		// 		params: {
		// 			range: '49'
		// 		}
		// 	})
		// 	.then((res: any) => {
		// 		console.log('res', res);
		// 	})
		// 	.catch((err: any) => {
		// 		console.log('err', err);
		// 	});

		// axios
		// 	.get('/api/tm/teams/leaderBoards', {
		// 		params: {
		// 			sessionId: '1',
		// 			type: 'TEAM'
		// 		}
		// 	})
		// 	.then((res: any) => {
		// 		console.log('res', res);
		// 	})
		// 	.catch((err: any) => {
		// 		console.log('err', err);
		// 	});

		GlobalContextValues?.setTeamsLeaderboard!(
			GlobalContextValues?.teamsLeaderboard!.slice(0, 10).sort((a, b) => b.score - a.score)
		);
		GlobalContextValues?.setUsersLeaderboard!(
			GlobalContextValues?.usersLeaderboard!.slice(0, 10).sort(
				(a, b) => (b.score ?? 0) - (a.score ?? 0)
			)
		);
	}, []);

	return (
		<PopupContainer
			borderColor={BorderColorEnum.tutorial}
			contentdisplay={ContentDisplayEnum.scrollable}
			title={t('team_introduction_popup_title')}
			open={UIContextValues?.introductionTeamsVisibility!}
			onClickCloseButton={() => {
				UIContextValues?.setIntroductionTeamsVisibility!(false);
				UIContextValues.nextOverlaysEvents?.();
			}}
		>
			<Box sx={styles.contentTab}>
				<TabPanel value={tabPanelValue} index={0}>
					<Stack spacing={2}>
						<Box
							sx={{
								backgroundColor: '#181818',
								borderRadius: '1em',
								border: '1px green solid',
								padding: '1em',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							<LeaderboardRank
								key={`leaderboard_team_rank_${UserContextValues.myTeam?.id}_${0}`}
								hideRank={true}
								me={UserContextValues.myTeam?.id === UserContextValues?.myTeam?.id}
								team={UserContextValues.myTeam}
							></LeaderboardRank>
						</Box>

						{GlobalContextValues?.teamsLeaderboard &&
							GlobalContextValues.teamsLeaderboard
								.filter((team) => team.id !== UserContextValues?.myTeam?.id)
								.map((team, index) => (
									<LeaderboardRank
										key={`leaderboard_team_rank_${team.id}_${index}`}
										hideRank={true}
										me={team.id === UserContextValues?.myTeam?.id}
										team={team}
									/>
								))}
					</Stack>
				</TabPanel>
			</Box>
		</PopupContainer>
	);
};

const styles = {
	contentTab: {
		// backgroundColor: 'yellow',
		padding: '0 !important',
		width: '100% !important'
	}
};
