import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import TaskAltIcon from '@mui/icons-material/TaskAlt';

import Chip from '@mui/material/Chip';
import Fade from '@mui/material/Fade';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import LinkTypography from '../../components/LinkTypography';
import { GlobalButton } from '../../components/buttons/GlobalButton';
import { ReturnButton } from '../../components/buttons/ReturnButton';
import { BorderColorEnum } from '../../components/containers/CornerContainer';
import { Header } from '../../components/page/Header';
import { Navbar } from '../../components/page/Navbar';
import { ScrollablePage } from '../../components/page/ScrollablePage';
import { Challenge, ChallengeStatus } from '../../contexts/Contexts.types';
import { GlobalContext } from '../../contexts/GlobalContext';
import { ConfirmationPopup, ConfirmationPopupMethods } from '../../popup/ConfirmationPopup';
import { APIFetchScoreFile } from '../../contexts/API/APIFechers';
import { APIContext } from '../../contexts/API/APIContext';

export const ChallengePage = () => {
	const confirmationPopupRef = React.useRef<ConfirmationPopupMethods>(null);
	const APIContextValues = React.useContext(APIContext);

	const [_challengeData, setChallengeData] = useState<Challenge>();
	const [alreadySubmitted, setAlreadySubmitted] = useState<boolean>(false);
	const [alreadyScored, setAlreadyScored] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const navigate = useNavigate();
	const { challengeId } = useParams();

	const GlobalContextValues = React.useContext(GlobalContext);

	const { t } = useTranslation();

	useEffect(() => {
		if (!challengeId) {
			navigate('/challenges', { replace: true });
			return;
		}

		const challenge = GlobalContextValues?.availableChallenges?.find(
			(challenge) => challenge.id === Number(challengeId)
		);

		if (!challenge) {
			navigate('/challenges', { replace: true });
			return;
		}

		let isSubmitted = challenge?.status === ChallengeStatus.SUBMITTED.valueOf();

		setAlreadySubmitted(Boolean(isSubmitted));
		setAlreadyScored(challenge?.score !== undefined && challenge?.score !== null);
		setChallengeData(challenge);
		return () => {};
	}, [challengeId]);

	const navigateToChallengeSubmission = () => {
		navigate(`/challenges/available/${challengeId}/submission`);
	};

	const downloadFile = () => {
		setIsLoading(true);
		console.log(_challengeData);
		try {
			let result = APIFetchScoreFile(
				_challengeData?.id ?? 0,
				APIContextValues.myProfile.id,
				APIContextValues.myProfile.teamId
			);
			return result;
		} catch (e) {
			console.log(e);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<Header
				title={t('challenge_header_title')}
				leftSlot={<ReturnButton goto="/challenges" />}
			></Header>
			<Box sx={styles.overlay}>
				<ScrollablePage>
					<Stack sx={styles.textContainer} spacing={3}>
						<Typography variant="h5">{_challengeData?.name}</Typography>
						<Box sx={styles.chipContainerDifficulty}>
							{_challengeData?.complexity !== 'notapplicable' && (
								<Chip
									sx={styles.chipDifficulty}
									size="small"
									label={_challengeData?.complexity}
								/>
							)}
							<Chip
								sx={styles.chipType}
								size="small"
								label={_challengeData?.challengeType}
							/>
							{alreadySubmitted && (
								<Fade in={true}>
									<TaskAltIcon sx={styles.pendingIcon} />
								</Fade>
							)}
						</Box>
						<Typography variant="h2">{t('challenge_property_criteria')}</Typography>

						<LinkTypography variant="body1">
							{_challengeData?.evaluationCriteria}
						</LinkTypography>

						<Typography variant="h2">{t('challenge_property_instructions')}</Typography>

						<LinkTypography variant="body1">
							{_challengeData?.instructions}
						</LinkTypography>

						<Typography variant="h2">{t('challenge_property_score')}</Typography>
						<Typography variant="body1">
							{t('challenge_property_points', {
								count: _challengeData?.maxPoints
							})}{' '}
						</Typography>

						<Typography variant="h2">{t('challenge_property_condition')}</Typography>

						<LinkTypography key={`successCondition_`} variant="body1">
							{_challengeData?.successCondition}
						</LinkTypography>

						<Typography variant="h2">{t('challenge_property_deliverables')}</Typography>

						<LinkTypography key={`successCondition_`} variant="body1">
							{_challengeData?.deliverables}
						</LinkTypography>

						{GlobalContextValues?.availableChallenges?.length === 0 && (
							<Typography sx={styles.errorMessage} variant="body1">
								{t('challenge_warning_nomore_challenge')}
							</Typography>
						)}
					</Stack>

					{alreadySubmitted && !alreadyScored && (
						<>
							<Fade in={true}>
								<Typography variant="body1" sx={styles.warningMessage}>
									{t('challenge_warning_message')}
								</Typography>
							</Fade>
							&nbsp;
							<Fade in={true}>
								<Typography
									variant="body1"
									sx={styles.downloadText}
									onClick={() => {
										if (!isLoading) downloadFile();
									}}
								>
									{t('download')}
								</Typography>
							</Fade>
						</>
					)}

					{alreadyScored && (
						<Fade in={true}>
							<Typography variant="body1" sx={styles.warningMessage}>
								{t('challenge_warning_cannot_submit_message')}
							</Typography>
						</Fade>
					)}

					<GlobalButton
						sx={styles.button}
						onClick={() => {
							console.log('alreadyScored', alreadyScored);
							if (alreadySubmitted) {
								confirmationPopupRef.current?.setOpen(true);
								return;
							}
							navigateToChallengeSubmission();
						}}
						disabled={alreadyScored}
					>
						{t('challenge_property_submission')}
					</GlobalButton>
				</ScrollablePage>
			</Box>
			<Navbar></Navbar>
			<ConfirmationPopup
				ref={confirmationPopupRef}
				borderColor={BorderColorEnum.warning}
				title={t('confirmation_popup_title_challenge')}
				onYesClick={() => {
					navigateToChallengeSubmission();
				}}
			>
				<Typography>{t('challenge_warning_override')}</Typography>
			</ConfirmationPopup>
		</>
	);
};
const styles = {
	textContainer: {
		marginTop: '2em',
		width: '100%'
	},

	overlay: {
		// backgroundColor: 'red',
		backgroundColor: 'rgba(40, 40, 40, .6)',
		position: 'relative',
		top: '-1vh'
	},

	button: {
		width: '50%',
		margin: '5em',
		marginTop: '2em !important'
		// backgroundColor: 'purple'
	},
	errorMessage: {
		textAlign: 'center',
		marginTop: '1em',
		color: 'error.main'
	},
	chipContainerDifficulty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '1em'
	},
	chipDifficulty: {
		backgroundColor: 'error.main',
		padding: '0.5em',
		fontWeight: 'bold'
	},
	chipType: {
		backgroundColor: 'error.dark',
		padding: '0.5em',
		fontWeight: 'bold'
	},
	pendingIcon: {
		color: 'primary.main'
	},
	warningMessage: {
		textAlign: 'center',
		color: 'error.main',
		marginTop: '2em'
		// backgroundColor: 'red'
	},
	downloadText: {
		textAlign: 'center',
		color: 'error.main',
		marginTop: '2em',
		textDecoration: 'underline',
		'&:hover': { cursor: 'pointer' }
	}
};
