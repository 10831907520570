import { Collapse, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import marsIcons from '../../assets/elements2D/marsIcons.svg';
import { GlobalPicture } from '../../components/GlobalPicture';
import { GlobalButton } from '../../components/buttons/GlobalButton';
import { NavigationButton } from '../../components/buttons/NavigationButton';
import { Header } from '../../components/page/Header';
import { Navbar } from '../../components/page/Navbar';
import { ScrollablePage } from '../../components/page/ScrollablePage';
import { BioInput } from '../../components/profile/BioInput';
import { CustomAvatar } from '../../components/profile/CustomAvatar';
import { HistoryInput } from '../../components/profile/HistoryInput';
import { MainInfoProfile } from '../../components/profile/MainInfoProfile';
import { BIO, Team, User } from '../../contexts/Contexts.types';
import { UIContext } from '../../contexts/UIContext';
import { UserContext } from '../../contexts/UserContext';

import { ReturnButton } from '../../components/buttons/ReturnButton';
import { CornerContainer } from '../../components/containers/CornerContainer';
import { APIContext } from '../../contexts/API/APIContext';
import { UpdatingProfile } from '../../contexts/API/APIFechers';

export const ProfilePage = () => {
	const { t } = useTranslation();

	const UserContextValues = React.useContext(UserContext);
	const APIContextValues = React.useContext(APIContext);

	const { profileId } = useParams();

	const [_userData, _setUserData] = React.useState<User>();
	const [_userTeam, _setUserTeam] = React.useState<Team>();
	const [_seeMore, _setSeeMore] = React.useState(false);

	const [BIOEditable, setBIOEditable] = React.useState(false);

	const UIContextValues = useContext(UIContext);

	useEffect(() => {
		UIContextValues.checkOverlaysEventsCompletion?.('profile');
	}, []);

	useEffect(() => {
		const init = async () => {
			const myProfile = await APIContextValues.getProfile(Number(profileId));

			if (myProfile && Number(profileId) === UserContextValues.myProfile?.id) {
				_setUserData(myProfile);
				_setUserTeam(UserContextValues.myTeam);
				setBIOEditable(true);
			}
		};
		init();
	}, [profileId, BIOEditable, UserContextValues.myProfile]);

	useEffect(() => {
		const init = async () => {
			if (Number(profileId) !== APIContextValues.myProfile.id) {
				setBIOEditable(false);

				const otherUser = await APIContextValues.getProfile(Number(profileId));
				if (otherUser) {
					_setUserData(otherUser);

					const otherUserTeam = await APIContextValues.getTeam(otherUser.teamId);
					if (otherUserTeam) _setUserTeam(otherUserTeam);

					_setUserTeam(await APIContextValues.getTeam(otherUser.teamId));
				}
			}
		};
		init();
	}, [profileId]);

	const submit = async (b: BIO) => {
		const UpdatingProfile: UpdatingProfile = {
			avatar: _userData?.avatar || APIContextValues.myProfile.avatar,
			bio: b,
			teamId: APIContextValues.myProfile.teamId,
			userID: APIContextValues.myProfile.id
		};

		const updatedProfile = await APIContextValues.updateMyProfile(UpdatingProfile);
		if (updatedProfile) {
			_setUserData(updatedProfile);
		}
	};

	return (
		<>
			<Box component={'div'} sx={styles.mars}></Box>
			<Header title={t('profile_header_title')} leftSlot={<ReturnButton />}></Header>

			<ScrollablePage>
				<Grid sx={styles.profileContainer}>
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						{/* CustomAvatar du profil --> à aller chercher dans la BDD */}
						<Grid
							item
							xs={6}
							container
							direction="row"
							justifyContent="center"
							alignItems="center"
						>
							<CustomAvatar avatarInfos={_userData} />
						</Grid>
						{/* Informations générale sur le joueur, à actualiser avec la BDD */}
						<Grid item xs={6}>
							<MainInfoProfile user={_userData} team={_userTeam} />
						</Grid>

						{/* bouton vers le profil équipe */}

						<Grid item xs={12} sx={styles.teamContainer}>
							<Typography variant="h3">{_userTeam?.name}</Typography>
							<Grid id="my-team-profile" item xs={12} sx={styles.buttonArea}>
								<Box sx={{ transform: 'scale(1.5)' }}>
									<GlobalPicture team={_userTeam} />
								</Box>

								<NavigationButton
									sx={styles.teamButton}
									gotopath={`/team/${_userTeam?.id}`}
								>
									{t('profile_subtitle_myTeam')}
								</NavigationButton>
							</Grid>
						</Grid>

						{/* Historique de l'activité: gain de points */}
						<Grid item xs={12}>
							<Typography variant="h3">{t('profile_subtitle_history')}</Typography>
							<CornerContainer sx={styles.historyContainer}>
								<Stack sx={styles.historyContent}>
									{_userData?.history?.challenges
										?.filter((e) => e.score !== 0)
										.slice(0, 2)
										.map((h) => {
											return (
												<HistoryInput
													key={h.name}
													point={h.score ?? 0}
													historyDetails={h.name}
												/>
											);
										})}
									{_userData?.history?.missions
										?.filter((e) => e.score !== 0)
										.slice(0, 2)
										.map((h) => {
											return (
												<HistoryInput
													key={h.name}
													point={h.score}
													historyDetails={h.name}
												/>
											);
										})}
									<Collapse in={_seeMore}>
										{_userData?.history?.challenges
											?.filter((e) => e.score !== 0)
											?.slice(2, _userData?.history?.challenges.length)
											.map((h) => {
												return (
													<HistoryInput
														key={h.name}
														point={h.score ?? 0}
														historyDetails={h.name}
													/>
												);
											})}
										{_userData?.history?.missions
											?.filter((e) => e.score !== 0)
											?.slice(2, _userData?.history?.missions.length)
											.map((h) => {
												return (
													<HistoryInput
														key={h.name}
														point={h.score}
														historyDetails={h.name}
													/>
												);
											})}
									</Collapse>
								</Stack>
								<Box sx={styles.historyButton}>
									<GlobalButton
										sx={styles.seeMore}
										variant="text"
										onClick={() => {
											_setSeeMore(!_seeMore);
										}}
									>
										{_seeMore
											? t('profile_button_seeLess')
											: t('profile_button_seeMore')}
									</GlobalButton>
								</Box>
							</CornerContainer>
						</Grid>
						{/* résumé des infos personnelles renseignées au début de l'éxpérience , prendre dans la BDD */}
						<Grid item xs={12}>
							<Box sx={styles.bioTitle}>
								<Typography variant="h3">{t('profile_subtitle_resume')}</Typography>
							</Box>
							<CornerContainer sx={styles.BioContainer}>
								<BioInput
									title={t('profile_BIO_text_motto')}
									BIOProperty={'motto'}
									key={`bio-motto}`}
									editable={BIOEditable}
									BIO={_userData?.BIO}
									setbio={(_b) => {
										// UserContextValues.setMyBIO!(_b);
										submit(_b);
									}}
								/>
								<BioInput
									title={t('profile_BIO_text_amazing')}
									BIOProperty={'amazing'}
									key={`bio-amazing}`}
									editable={BIOEditable}
									BIO={_userData?.BIO}
									setbio={(_b) => {
										// UserContextValues.setMyBIO!(_b);
										submit(_b);
									}}
								/>
								<BioInput
									title={t('profile_BIO_text_trust')}
									BIOProperty={'trust'}
									key={`bio-trust}`}
									editable={BIOEditable}
									BIO={_userData?.BIO}
									setbio={(_b) => {
										// UserContextValues.setMyBIO!(_b);
										submit(_b);
									}}
								/>
								<BioInput
									title={t('profile_BIO_text_improve')}
									BIOProperty={'improve'}
									key={`bio-improve}`}
									editable={BIOEditable}
									BIO={_userData?.BIO}
									setbio={(_b) => {
										// UserContextValues.setMyBIO!(_b);
										submit(_b);
									}}
								/>
							</CornerContainer>
						</Grid>
					</Grid>
				</Grid>
			</ScrollablePage>
			<Navbar></Navbar>
		</>
	);
};

const styles = {
	editPen: {
		cursor: 'pointer'
	},

	// page entière
	profileContainer: {
		position: 'relative'
	},
	mars: {
		position: 'absolute',
		backgroundImage: `url(${marsIcons})`,
		left: '-30%',
		height: '150pt',
		width: '150pt',
		borderColor: '#EE332D',
		boxShadow: '0 0 50px #EE332D',
		borderRadius: '100%',
		scale: '2'
	},

	// infos sur équipe joueur
	teamContainer: {
		marginTop: '3vh'
	},

	buttonArea: {
		marginTop: '1vh',
		marginBottom: '1vh',
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
		height: '9em',
		width: '100%'
	},

	teamButton: {
		width: '60%',
		height: '3em'
	},

	// Historique d'activités
	historyContainer: {
		// padding: '0.5em',
		// backgroundColor: 'red',
		marginTop: '2vh',
		width: '100%'
	},
	historyContent: {
		margin: '2vh',
		marginTop: '2em'
	},
	historyButton: {
		display: 'flex',
		justifyContent: 'flex-end'
	},

	// Biographie joueur
	BioContainer: {
		padding: '2vh',
		width: '90%'
	},
	bioTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '3vh',
		marginBottom: '3vh'
	},
	seeMore: {
		fontSize: '0.8em',
		fontWeight: 'bold',
		backgroundColor: 'transparent',
		color: 'primary.main',
		'&:hover': {
			backgroundColor: 'transparent'
		}
	}
};
