import { Box } from '@mui/material';
import * as React from 'react';
import borderNormal from '../../assets/ui/popup_normal.svg';
import borderSuccess from '../../assets/ui/popup_success.svg';
import borderTutorial from '../../assets/ui/popup_tutorial.svg';
import borderWarning from '../../assets/ui/popup_warning.svg';

export enum ContentDisplayEnum {
	// eslint-disable-next-line
	scrollable = 'scrollable',
	// eslint-disable-next-line
	fixed = 'fixed'
}

export enum BorderColorEnum {
	// eslint-disable-next-line
	normal = 'normal',
	// eslint-disable-next-line
	tutorial = 'tutorial',
	// eslint-disable-next-line
	warning = 'warning',
	// eslint-disable-next-line
	success = 'success'
}

interface CornerContainerProps {
	contentdisplay?: ContentDisplayEnum;
	sx?: any;
	children?: React.ReactNode;
	borderColor?: BorderColorEnum;
}

export const CornerContainer = (props: CornerContainerProps) => {
	const [borderColor, setBorderColor] = React.useState<string>(borderNormal);

	React.useEffect(() => {
		switch (props.borderColor) {
			case BorderColorEnum.normal:
				setBorderColor(borderNormal);
				break;
			case BorderColorEnum.tutorial:
				setBorderColor(borderTutorial);
				break;
			case BorderColorEnum.warning:
				setBorderColor(borderWarning);
				break;
			case BorderColorEnum.success:
				setBorderColor(borderSuccess);
				break;
			default:
				setBorderColor(borderNormal);
				break;
		}
	}, [props.borderColor]);

	return (
		<Box
			component="div"
			sx={{
				...styles.background,
				...props.sx,
				...{
					borderImageSource: `url(${borderColor}) !important`,
					height: props.contentdisplay === 'scrollable' ? '100%' : 'initial'
				}
			}}
		>
			{props.children}
		</Box>
	);
};

const styles = {
	background: {
		position: 'relative',
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		justifyContent: 'center',
		overflow: 'hidden',

		// alignItems: 'stretch',
		// marginTop: '3em',
		// padding: "1em",

		borderImageSlice: '23% 23% 23% 23% fill',
		borderImageWidth: '3em 3em 3em 3em',
		borderImageOutset: '0px 0px 0px 0px',
		borderImageRepeat: 'stretch stretch'
	}
};
