import { Box } from '@mui/system';
import { ExploreLogo } from '../assets/MediaComponents';
import { Login } from '../components/Login';
import { LogoArea } from '../components/LogoArea';
import { FixedPage } from '../components/page/FixedPage';
import { useContext, useEffect } from 'react';
import { UIContext } from '../contexts/UIContext';

export const LoginPage = () => {
	const UIContextValues = useContext(UIContext);

	useEffect(() => {
		UIContextValues.setInsideShipOverlayVisibility?.(false);
		UIContextValues.setDialogAIVisibility?.(false);
	}, []);

	return (
		<>
			<FixedPage>
				<Box sx={styles.exploreLogo}>
					<ExploreLogo />
				</Box>

				<Box sx={styles.container}>
					<Login></Login>
				</Box>
				<LogoArea />
			</FixedPage>
		</>
	);
};
export const styles = {
	container: {
		height: {
			xs: '55vh',
			sm: '100%'
		},
		width: '100%'
	},
	exploreLogo: {
		height: '3vh',
		width: {
			xs: '8em',
			sm: '12em'
		}
	}
};
