import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { GlobalButton } from './buttons/GlobalButton';
import { CornerContainer } from './containers/CornerContainer';

export const Login = () => {
	// eslint-disable-next-line
	const [loginFeedbackMessage, setLoginFeedbackMessage] = useState<string>('');
	const [loginFeedbackColor, setLoginFeedbackColor] = useState<string>('');
	const { t } = useTranslation();

	const params = useParams();

	const ssoRedirect = () => {
		const url = window.location.href;
		const redirectSsoURL = url.split('/')[0].concat('/auth/sso/login');

		window.open(redirectSsoURL, '_self');
	};

	useEffect(() => {
		switch (params['*']) {
			case '':
				setLoginFeedbackMessage(t('login_auth'));
				break;
			case 'success':
				// check if
				setLoginFeedbackMessage('');
				break;
			case 'invalid':
				setLoginFeedbackMessage(t('login_auth_invalid'));
				setLoginFeedbackColor('error.main');
				break;
			default:
				setLoginFeedbackMessage(t('login_error_message_global'));
				setLoginFeedbackColor('error.main');
				break;
		}
	}, [params]);

	return (
		<Box sx={styles.windowFormLogin}>
			<CornerContainer>
				<Box component="div" sx={styles.FormLogin}>
					<Typography variant="h5" sx={styles.title}>
						{t('login_title')}
					</Typography>
					<Stack spacing={1}>
						<Typography sx={{ color: loginFeedbackColor }}>
							{loginFeedbackMessage}
						</Typography>
					</Stack>
				</Box>
			</CornerContainer>
			<GlobalButton variant="contained" style={{ width: '60%' }} onClick={ssoRedirect}>
				{t('login_button')}
			</GlobalButton>
		</Box>
	);
};

const styles = {
	title: {
		paddingBottom: {
			xs: '0.5em',
			sm: '1em'
		}
	},
	errorMessage: {
		textAlign: 'center',
		marginTop: '1em',
		color: 'error.main'
	},
	windowFormLogin: {
		display: 'flex',
		flexDirection: 'column',

		height: '100%',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		gap: 2
	},

	FormLogin: {
		display: 'flex',
		flexDirection: 'column',
		backgroundRepeat: 'no-repeat',
		padding: '3em',
		alignItems: 'center'
	},

	field: {
		'& .MuiInputBase-input': {
			backgroundColor: 'rgba(255, 255,255, .9)',
			color: '#282828 !important',
			padding: '0.6em !important',
			borderRadius: '0.2em !important',
			'& label': {
				display: 'none'
			},
			'&:focus': {
				backgroundColor: '#FFF'
			}
		}
	}
};
