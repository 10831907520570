import React from 'react';

import styled from '@emotion/styled';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/system';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
	sx?: any;
}

export const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={props?.sx}>{children}</Box>}
		</div>
	);
};

export const StyledTab = styled(Tab)(() => ({
	color: 'error.main',
	'&.Mui-selected': {
		color: 'common.white',
		backgroundColor: 'primary.main'
	},
	'&.Mui-focusVisible': {
		backgroundColor: 'primary.main'
	}
}));
