export const getRankWithSuffix = (rank: number) => {
	const suffixes = ['th', 'st', 'nd', 'rd'];
	const lastTwoDigits = rank % 100;

	// Check if the last two digits are between 11 and 13 (which all use 'th' suffix)
	if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
		return rank + 'th';
	}

	// Otherwise, get the single-digit suffix based on the last digit
	const lastDigit = rank % 10;
	return rank + (suffixes[lastDigit] || 'th');
};

export const supportedFileExtensions = [
	'webp',
	'png',
	'jpg',
	'jpeg',
	'avif',
	'pdf',
	'mp4',
	'avi',
	'mkv',
	'mov',
	'svg'
];

export const getMimeType = (fileName: string) => {
	const extension = fileName?.split('.')?.pop()?.toLowerCase();
	switch (extension) {
		case 'jpeg':
		case 'jpg':
			return 'image/jpeg';
		case 'png':
			return 'image/png';
		case 'webp':
			return 'image/webp';
		case 'avif':
			return 'image/avif';
		case 'pdf':
			return 'application/pdf';
		case 'mp4':
			return 'video/mp4';
		case 'avi':
			return 'video/avi';
		case 'mkv':
			return 'video/x-matroska';
		case 'mov':
			return 'video/mov';
		case 'quicktime':
			return 'video/mov';
		case 'svg':
			return 'image/svg+xml';
		case 'xlsx':
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		case 'xlsm':
			return 'application/vnd.ms-excel.sheet.macroEnabled.12';
		case 'xls':
			return 'application/vnd.ms-excel';
		default:
			return '';
	}
};
