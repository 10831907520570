import { StarsScoreProps } from './GameComponents.model';
import starImage from '../assets/score-star.svg';
import Grid from '@mui/material/Unstable_Grid2';
import Grow from '@mui/material/Grow/Grow';

export default function StarsScore({ score, width, spacing }: StarsScoreProps): JSX.Element {
	return (
		<Grid container spacing={spacing} columns={3} width={width}>
			<Grid xs={1} style={{ opacity: score > 0.4 ? 1 : 0.3 }}>
				<Grow in={true} {...{ timeout: 1000 }}>
					<img src={starImage} />
				</Grow>
			</Grid>

			<Grid xs={1} style={{ opacity: score > 0.6 ? 1 : 0.3 }}>
				<Grow in={true} {...{ timeout: 2000 }}>
					<img src={starImage} />
				</Grow>
			</Grid>

			<Grid xs={1} style={{ opacity: score > 0.8 ? 1 : 0.3 }}>
				<Grow in={true} {...{ timeout: 3000 }}>
					<img src={starImage} />
				</Grow>
			</Grid>
		</Grid>
	);
}
