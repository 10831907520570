import { GSheetEntity } from './GSheetEntity';

export class ChapterData extends GSheetEntity {
	id = '';
	name = '';
	image = '';
	introCardId = '';
	conclusionCardId = '';
	gauge1Id = '';
	gauge2Id = '';
	gauge3Id = '';
	visible = true;
	score = 0;
	locked = false;
	durationInMinutes = 5;
	displayName? = '';

	static override GSheetPageId = 'Chapitres';

	constructor() {
		super();
	}
}
