import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import _ from 'underscore';
import { ChapterData } from '../game/datas/ChapterData';
import { APIContext } from './API/APIContext';
import { APIGetProfile, APIPostMission } from './API/APIFechers';
import { MissionContextInterface } from './Contexts.model';
import { MissionProgression, UserInfos } from './Contexts.types';
import { useLocalStorage } from 'usehooks-ts';

export const MissionContext = createContext<MissionContextInterface>({});

export const MissionProvider: FC<PropsWithChildren> = ({ children }) => {
	const [chapters, setChapters] = useState<ChapterData[]>([]);
	const [userInfo] = useLocalStorage<UserInfos>('USER_INFO', {} as UserInfos);

	const APIContextValues = useContext(APIContext);
	const [missionsProgression, setMissionsProgression] = useState<MissionProgression[]>([]);

	useEffect(() => {
		const init = () => {
			const _missionProgression = APIContextValues.missions.map((missionEl) => {
				const isLocked =
					missionEl.startDate >= Date.now() || missionEl.endDate <= Date.now();

				return {
					id: missionEl.id,
					name: missionEl.name.toLowerCase(),
					score: missionEl.score || 0,
					locked: isLocked,
					durationInMinutes: missionEl.durationInMinutes,
					displayName: missionEl.displayName
				};
			});

			ChapterData.getEntities().then(function (chapters: Array<ChapterData>) {
				const updatedChapters = chapters.map((chapter) => {
					const missionEl = _missionProgression.find(
						(mission) => mission.name === chapter.id
					);

					return {
						...chapter,
						...missionEl,
						id: chapter.id,
						displayName: missionEl?.displayName,
						missionId: missionEl?.id,
						durationInMinutes: missionEl?.durationInMinutes ?? 20,
						locked:
							chapter.id === 'tutorial'
								? false
								: (missionEl && missionEl.locked) || false
					};
				});

				const visibleChapters = updatedChapters.filter((chapter) => chapter.visible);

				setChapters(visibleChapters);
			});

			setMissionsProgression({ ...missionsProgression, ..._missionProgression });
		};

		init();
	}, [APIContextValues.missions]);

	const registerChapterDone = async (chapterId: string, score: number) => {
		if (
			userInfo.role === 'evaluator' ||
			userInfo.role === 'monitor' ||
			userInfo.role === 'admin'
		)
			return;
		const newMissionsProgression: MissionProgression[] = _.clone(missionsProgression);
		const _missionProgression = _.findWhere(newMissionsProgression, { name: chapterId });

		if (_missionProgression) {
			_missionProgression.score = Math.round(score * 100);
			if (chapterId === 'tutorial') {
				_missionProgression.score = 100;
			}
		}

		setChapters(
			chapters.map((chapter) => {
				const missionPel = _.findWhere(newMissionsProgression, { name: chapter.id });
				if (missionPel) {
					chapter.score = missionPel.score;
					chapter.displayName = missionPel.displayName;
				}
				return chapter;
			})
		);

		if (_missionProgression && _missionProgression?.id !== 0) {
			const result = await APIPostMission(
				APIContextValues.myProfile.id,
				_missionProgression.id,
				_missionProgression.score
			);
			if (result.success) {
				APIGetProfile(APIContextValues.myProfile.id);
				setMissionsProgression(newMissionsProgression);
			}
		}
	};

	return (
		<MissionContext.Provider
			value={{
				chapters,
				missionsProgression,
				registerChapterDone
			}}
		>
			{children}
		</MissionContext.Provider>
	);
};
