import Papa from 'papaparse';

export class GSheetEntity {
	static GSheetId = process.env.REACT_APP_G_SHEET_ID;
	static GSheetKey = process.env.REACT_APP_G_SHEET_KEY;
	static GSheetPageId = '';

	constructor() {
		//
	}

	static async getEntities() {
		// const forceGSheet = process.env.REACT_APP_USE_G_SHEET === "true";
		const forceGSheet = process.env.REACT_APP_USE_G_SHEET === 'true';

		if (forceGSheet) {
			return GSheetEntity.getEntitiesFromGSheets(this.GSheetPageId);
		} else {
			return GSheetEntity.getEntitiesFromCSV(this.GSheetPageId);
		}
	}

	static async getEntitiesFromGSheets(GSheetPageId: string) {
		return fetch(
			`https://sheets.googleapis.com/v4/spreadsheets/${GSheetEntity.GSheetId}/values/${GSheetPageId}?key=${GSheetEntity.GSheetKey}`
		).then(function (data) {
			return data.json().then(function (data) {
				return GSheetEntity.getEntitiesFromArray(data.values);
			});
		});
	}

	static async getEntitiesFromCSV(GSheetPageId: string) {
		return fetch(`${process.env.PUBLIC_URL}/csv/${GSheetPageId}.csv`).then(function (data) {
			return data.text().then(function (data) {
				return GSheetEntity.getEntitiesFromArray(Papa.parse(data).data);
			});
		});
	}

	static getEntitiesFromArray(rows: Array<any>) {
		const entities: Array<any> = [];

		rows.forEach(function (row: any, i: number) {
			if (i === 0) return;
			const entity = new GSheetEntity(); // todo créer une entité du type de la classe en court et non de la classe parente GSheetEntity
			row.forEach(function (val: any, j: number) {
				if (val === '' || val === ' ') val = null;
				else if (!isNaN(+val)) val = +val;
				if (val === 'TRUE') val = true;
				if (val === 'FALSE') val = false;

				(entity as any)[rows[0][j]] = val;
			});
			entities.push(entity);
		});

		//console.info(entities);
		return entities;
	}
}
