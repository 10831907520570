import { Fade } from '@mui/material';
import Box from '@mui/material/Box';
import { useContext } from 'react';
import planetImage from '../../assets/elements2D/marsIcons.svg';
import { UIContext } from '../../contexts/UIContext';
import bottomPartImage from '../assets/space-bg-bottom.svg';
import reflectionsImage from '../assets/space-bg-reflections.svg';
import topPartImage from '../assets/space-bg-top.svg';

interface AnimatedBackgroundProps {
	state?: number;
	zIndex?: number;
	forceVisibility?: boolean;
}

export default function AnimatedBackground({
	zIndex = 10,
	forceVisibility = false
}: AnimatedBackgroundProps): JSX.Element {
	const UIContextValues = useContext(UIContext);

	return (
		<Fade in={UIContextValues.insideShipOverlayVisibility || forceVisibility}>
			<Box sx={{ ...styles.main, zIndex: zIndex }}>
				<Box sx={styles.planet}>
					<img src={planetImage} />
				</Box>

				<Box sx={styles.reflections}>
					<img src={reflectionsImage} />
				</Box>

				<Box sx={styles.topPart}>
					<img src={topPartImage} />
				</Box>

				<Box sx={styles.bottomPart}>
					<img src={bottomPartImage} />
				</Box>
			</Box>
		</Fade>
	);
}

const styles = {
	main: {
		//backgroundImage: `url(${spaceBgImage})`,
		backgroundSize: 'cover',
		position: 'absolute',
		width: '100%',
		height: '100%',
		overflow: 'hidden',
		top: 0,
		left: 0,
		zIndex: 10
	},
	planet: {
		position: 'absolute',
		width: '40%',
		top: '5%',
		left: '5%',
		borderColor: '#EE332D',
		boxShadow: '0 0 50px #EE332D',
		borderRadius: '100%',
		'& > img': {
			display: 'block'
		}
	},
	reflections: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		top: 0
	},
	topPart: {
		position: 'absolute',
		width: '100%',
		top: 0
	},
	bottomPart: {
		position: 'absolute',
		width: '100%',
		bottom: 0
	}
};
