import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReturnButton } from '../components/buttons/ReturnButton';
import { Header } from '../components/page/Header';
import { Navbar } from '../components/page/Navbar';
import { ScrollablePage } from '../components/page/ScrollablePage';

export const LegalsPage = () => {
	const { t } = useTranslation();

	return (
		<>
			<Header
				title={t('legals_header_title')}
				leftSlot={<ReturnButton goto={'/'} />}
			></Header>
			<ScrollablePage>
				<Stack sx={styles.textContainer} spacing={3}>
					<Typography variant="h2" sx={{ textAlign: 'center' }}>
						{/* {t('legals_title')} */}
						Legal mentions
					</Typography>

					{/* {t('legals_text')} */}
					<Typography variant="body1">Effective 01/09/2023</Typography>

					<Typography variant="body1">
						In accordance with the provisions of Articles 6-III and 19 of Law n°2004-575
						of June 21, 2004 for Confidence in the Digital Economy, known as L.C.E.N.,
						users (hereinafter the "User") of the site explore.cma-cgm.net (hereinafter
						the "Site") are hereby informed of the present legal notice. CMA CGM may, at
						any time and without notice, modify the present legal notice. connection to
						and browsing of the Site by the User implies full and unreserved acceptance
						of the present legal notice.
					</Typography>

					<Typography variant="h6">ARTICLE 1 - PUBLISHER</Typography>
					<Typography variant="body1">
						The Site is published by CMA CGM SA with a share capital of 249,985,429.74
						euros, registered in the Marseilles Trade and Companies Register under
						number 562,024,422 and having its registered office at Boulevard Jacques
						Saadé, 4 Quai d'Arenc - 13002 Marseilles - France (hereinafter the
						"Publisher").
					</Typography>

					<Typography variant="h6">ARTICLE 2 - L'HEBERGEUR</Typography>
					<Typography variant="body1">
						The Site is hosted by Amazon Web Service.{' '}
					</Typography>

					<Typography variant="h6">ARTICLE 3 - ACCESS TO THE SITE </Typography>
					<Typography variant="body1">
						The Site is always accessible, 7 days a week, 24 hours a day, except in the
						event of force majeure, scheduled or unscheduled interruption, or where
						maintenance is required. In the event of modification, interruption or
						suspension of the Site, the Publisher shall not be held liable.
					</Typography>

					<Typography variant="h6">ARTICLE 4 - DATA COLLECTION</Typography>
					<Typography variant="body1">
						The Site ensures that the User's personal data is collected and processed
						with due respect for privacy, in accordance with the French Data Protection
						Act no. 78-17 of January 6, 1978. In accordance with the French Data
						Protection Act of January 6, 1978, the User has the right to access,
						rectify, delete and object to his or her personal data. The User may
						exercise this right by sending an e-mail to the following address:
						ho.explore@cma-cgm.com For further information on the protection of personal
						data, please refer to the Charter on the protection of personal data on the
						www.cma-cgm.explore.com website, accessible under the heading "Personal
						data".
					</Typography>

					<Typography variant="h6">ARTICLE 5 - INTELLECTUAL PROPERTY</Typography>
					<Typography variant="body1">
						All elements accessible on the site (text, images, graphics, logos, icons,
						sounds, software, etc.) are protected by copyright. Any reproduction,
						representation, modification, publication, adaptation of all or part of the
						elements of the site, whatever the means or process used, is forbidden,
						except with the prior written authorization of CMA CGM. Any unauthorized use
						of the Site or of any of the elements it contains is considered to
						constitute an infringement and is liable to prosecution. The trademarks and
						logos reproduced on the Site are registered by the companies that own them.
					</Typography>

					<Typography variant="h6">ARTICLE 6 - LIABILITY</Typography>
					<Typography variant="body1">
						The information and documents on the site are presented for information
						purposes only, are not exhaustive, and do not incur the liability of the
						owner of the site. The owner of the site cannot be held responsible for
						direct or indirect damage resulting from access to the site.
					</Typography>

					<Typography variant="h6">ARTICLE 7 - INTERACTIVITY</Typography>
					<Typography variant="body1">
						Site users may post content on the Site in dedicated areas (notably via
						comments). The content submitted remains under the responsibility of its
						authors, who assume full legal liability. The owner of the Site nevertheless
						reserves the right to withdraw without notice and without justification any
						content posted by Users which does not comply with the Site's ethical
						charter or with current legislation
					</Typography>
				</Stack>
			</ScrollablePage>
			<Navbar></Navbar>
		</>
	);
};
const styles = {
	textContainer: {
		marginTop: '2em'
	}
};
