import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FixedPage } from '../../components/page/FixedPage';
import { APIContext } from '../../contexts/API/APIContext';
import { GlobalContext } from '../../contexts/GlobalContext';
import { UIContext } from '../../contexts/UIContext';

export const EndingSummaryVideoPage = () => {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const [controls, setControls] = useState(false);

	const [errorMessage, setErrorMessage] = useState('');

	const GlobalContextValues = useContext(GlobalContext);
	const UIContextValues = useContext(UIContext);
	const APIContextValues = useContext(APIContext);

	const videoRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		if (UIContextValues?.overlaysEventsCompletion?.endingSummaryVideo == false) {
			UIContextValues.checkOverlaysEventsCompletion?.('endingSummaryVideo');
		} else {
			UIContextValues.checkOverlaysEventsCompletion?.('endingSummary');
		}
	}, []);

	useEffect(() => {
		APIContextValues.getLeaderboardTeams();
		APIContextValues.getLeaderboardUsers();
		if (GlobalContextValues?.endingSummaryVideoUrl) {
			videoRef.current?.play().catch(() => {
				// console.log("error", error)
				setControls(true);
			});
		} else {
			setErrorMessage(t('introduction_video_error_not_found'));
			setTimeout(() => {
				handleVideoEnded();
			}, 1000);
		}
	}, []);

	const handleVideoEnded = () => {
		UIContextValues.setAsCompleteOverlayCompletion('endingSummaryVideo');
		navigate('/ending-summary', { replace: true });
	};

	return (
		<>
			<FixedPage noPadding={true}>
				<Box sx={styles.container}>
					{!errorMessage && (
						<video
							style={{
								position: 'absolute',
								top: 0,
								height: '100%',
								width: '100%',
								backgroundColor: '#282828'
							}}
							ref={videoRef}
							onError={handleVideoEnded}
							autoPlay
							controls={controls}
							onEnded={handleVideoEnded}
						>
							<source
								src={GlobalContextValues?.endingSummaryVideoUrl}
								type="video/mp4"
							/>
							{t('introduction_video_error_browser')}
						</video>
					)}

					{errorMessage && (
						<Typography sx={styles.errorMessage} variant="body1">
							{errorMessage}
						</Typography>
					)}
				</Box>
			</FixedPage>
		</>
	);
};

const styles = {
	container: {
		height: '100%',
		width: '100%',
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	errorMessage: {
		textAlign: 'center',
		marginTop: '1em',
		color: 'error.main'
	}
};
