import { Box, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Cristal from '../assets/profil/green_cristal.svg';
import teamRankIcon from '../assets/profil/rank_team.svg';
import SpaceShipModel from '../assets/profil/spaceship_blue.svg';
import { GlobalPicture } from '../components/GlobalPicture';
import { ReturnButton } from '../components/buttons/ReturnButton';
import { Header } from '../components/page/Header';
import { Navbar } from '../components/page/Navbar';
import { ScrollablePage } from '../components/page/ScrollablePage';
import { AvatarMember } from '../components/profile/AvatarMember';
import { InfoIcon } from '../components/profile/InfoIcon';
import { APIContext } from '../contexts/API/APIContext';
import { Team } from '../contexts/Contexts.types';
import { UserContext } from '../contexts/UserContext';

export const TeamPage = () => {
	const { t } = useTranslation();

	const APIContextValues = useContext(APIContext);
	const UserContextValues = useContext(UserContext);

	const { teamId } = useParams();

	const [_teamData, _setTeamData] = useState<Team>();

	useEffect(() => {
		const init = async () => {
			if (Number(teamId) === UserContextValues.myTeam?.id) {
				_setTeamData(JSON.parse(JSON.stringify(UserContextValues.myTeam)));
			} else {
				if (!teamId) return;
				const otherUserTeam = await APIContextValues.getTeam(Number(teamId));
				if (otherUserTeam) _setTeamData(otherUserTeam);
			}
		};
		init();
	}, [teamId, UserContextValues]);

	return (
		<>
			<Box component={'div'} sx={styles.spaceshipImg}></Box>
			<Header title={t('team_header_title')} leftSlot={<ReturnButton />}></Header>
			<ScrollablePage>
				<Grid sx={styles.profilContainer}>
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid
							item
							xs={6}
							container
							direction="row"
							justifyContent="center"
							alignItems="center"
						>
							{' '}
						</Grid>

						{/* Informations générale sur le joueur, à actualiser avec la BDD */}
						<Grid item xs={6} sx={styles.infoPlayer}>
							<Typography sx={styles.teamName} variant="h5">
								{_teamData?.name}
							</Typography>

							<Box component="div" sx={styles.infoiconPlayer}>
								<InfoIcon
									icon={Cristal}
									text={_teamData?.score.toString()}
									th={false}
								></InfoIcon>
								<InfoIcon
									icon={teamRankIcon}
									text={`${_teamData?.rank}`}
									th={true}
								></InfoIcon>
							</Box>
						</Grid>
					</Grid>

					<Grid item xs={12} sx={styles.logoArea}>
						<Box sx={{ transform: 'scale(2)' }}>
							<GlobalPicture team={_teamData} disabled />
						</Box>
					</Grid>

					<Grid item xs={12} sx={styles.memberArea}>
						<Typography variant="h3">{t('team_subtitle_teamMembers')}</Typography>

						<Grid
							container
							spacing={{ xs: 2, md: 3 }}
							columns={{ xs: 4, sm: 8, md: 12 }}
						>
							{UserContextValues.myProfile?.id === _teamData?.id && (
								<Grid
									sx={styles.member}
									item
									xs={2}
									sm={4}
									md={4}
									key={`member_${UserContextValues?.myProfile?.id}`}
								>
									<AvatarMember user={UserContextValues?.myProfile!} me={true} />
								</Grid>
							)}

							{_teamData?.members?.map((member) => (
								<Grid
									sx={styles.member}
									item
									xs={2}
									sm={4}
									md={4}
									key={`member_${member.id}`}
								>
									<AvatarMember
										user={member}
										me={member.id === UserContextValues.myProfile?.id}
									/>
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
			</ScrollablePage>
			<Navbar></Navbar>
		</>
	);
};
const styles = {
	teamName: {
		paddingTop: 2,
		paddingBottom: 2,
		alignText: 'center',
		width: '100%'
		// backgroundColor: 'red'
	},
	profilContainer: {
		position: 'relative',
		width: '100%'
	},
	infoPlayer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-start',
		// backgroundColor: 'green',
		flexDirection: 'column',
		height: '100%',
		marginTop: {
			xs: '5vh',
			sm: '5vh',
			md: '6vh'
		}

		// width: '70%',
		// backgroundColor: 'yellow'
	},
	infoiconPlayer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-wrap',
		gap: '1em'
		// backgroundColor: 'green'
	},
	infoContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-end',
		flexDirection: 'column',
		width: '100%',
		marginTop: '1em',
		marginBottom: '2em'
	},
	mainInfo: {
		width: '50%'
	},

	logoArea: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '9em',
		marginTop: '4vh'
	},
	memberArea: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		gap: '2em',
		width: '100%',
		paddingTop: '1em'
	},
	member: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-start'
	},

	spaceshipImg: {
		position: 'absolute',
		height: '40em',
		width: '40em',
		backgroundImage: `url(${SpaceShipModel})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		transform: 'rotate(55deg)',
		top: '-10em',
		left: '-20em'
	},
	infoTeam: {
		padding: 2,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
};
