import { Box } from '@mui/material';
import Container from '@mui/material/Container/Container';
import { Outlet } from 'react-router-dom';
import backgroundImg from '../../assets/ui/background_app.svg';
import backgroundDesktop from '../../assets/ui/background_desktop.svg';
import AnimatedBackground from '../../game/components/AnimatedBackground';

import { CalendarPopup } from '../../popup/CalendarPopup';
import { ErrorPopup } from '../../popup/ErrorPopup';
import { IntroductionTeamsPopup } from '../../popup/IntroductionTeamsPopup';
import { LeaderboardPopup } from '../../popup/LeaderboardPopup';
import { MenuPopup } from '../../popup/MenuPopup';
import { NotificationPopup } from '../../popup/NotificationPopup';
import { SuccessPopup } from '../../popup/SuccessPopup';
import { DialogAI } from '../DialogAI';
import { TutorialOverlay } from '../TutorialOverlay';

// interface BackgroundContainerProps {}

export const BackgroundContainer = () => {
	return (
		<Container id={'global-bg-container'} maxWidth={false} sx={styles.background}>
			<Container maxWidth={'xs'} sx={styles.pageContainer}>
				<Box id="stars_1"></Box>
				<Box id="stars_2"></Box>
				<Box id="stars_3"></Box>

				<AnimatedBackground />
				<DialogAI />
				<LeaderboardPopup></LeaderboardPopup>
				<IntroductionTeamsPopup />
				<TutorialOverlay />
				<ErrorPopup />
				<SuccessPopup />
				<CalendarPopup />
				<MenuPopup />
				<NotificationPopup />

				<Outlet></Outlet>
			</Container>
		</Container>
	);
};

const styles = {
	background: {
		position: 'fixed',
		width: '100%',
		height: '100%',
		backgroundColor: 'common.black',
		backgroundImage: `url(${backgroundDesktop})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		padding: '0px',
		margin: '0px',
		display: 'flex',
		alignItems: 'center'
	},
	pageContainer: {
		position: 'relative',
		height: '100%',
		justifyContent: 'center',
		padding: '0 !important',
		backgroundImage: `url(${backgroundImg})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100% 300%',
		overflow: 'hidden',
		maxHeight: '900px',
		borderRadius: {
			xs: '0',
			sm: '0.2em'
		},
		outline: '1em ridge #282828',
		outlineOffset: '0.1px'
	},
	stars: {
		position: 'relative',
		height: '100%',
		width: '100%',
		justifyContent: 'center',
		padding: '0 !important'
		// borderImageSource: `url(${starsImg})`,
		// borderImageSlice: '27 27 27 27 fill',
		// borderImageWidth: '4  0px 4  0px 4  0px 4  0px',
		// borderImageOutset: '0px 0px 0px 0px',
		// borderImageRepeat: 'repeat repeat',
	}
};
