import { Fade, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container/Container';
import { useTranslation } from 'react-i18next';

export const LoaderFallback: React.FC = () => {
	const { t } = useTranslation();

	return (
		<>
			<Fade in={true}>
				<Container maxWidth={false} sx={styles.background}>
					<CircularProgress />
					<Typography variant="body1" sx={styles.textLoad}>
						{t('load_text')}
					</Typography>
				</Container>
			</Fade>
		</>
	);
};

const styles = {
	background: {
		position: 'absolute',
		top: '0',
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(40, 40, 40, .7)',
		padding: '0px !important',
		margin: '0px !important',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '0.5em',
		zIndex: '1000'
	},
	textLoad: {
		fontsize: {
			xs: '0.7em',
			sm: '0.8em',
			md: '1em'
		}
	}
};
