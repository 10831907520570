import { Box } from '@mui/material';
import Fade from '@mui/material/Fade';
import { CardActionProps } from './GameComponents.model';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function CardAction({ side, action, transformX }: CardActionProps): JSX.Element {
	if (action === '') return <div></div>;

	const smallHeight = useMediaQuery('(max-height:800px)');

	return (
		<Fade in={Math.abs(transformX) > 8 && (transformX > 0 ? 1 : -1) == side}>
			<Box sx={{ ...style.main, ...{ fontSize: smallHeight ? '9px' : '10px' } }}>
				<Box sx={style.content} textAlign={side == 1 ? 'right' : 'left'}>
					{action}
				</Box>
			</Box>
		</Fade>
	);
}

const style = {
	main: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		bgcolor: '#00000087',
		color: '#fff'
	},
	content: {
		width: '100%',
		padding: '1.1em 1.3em',
		fontSize: '1.4em',
		lineHeight: '1.1'
	}
};
