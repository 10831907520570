import { Box } from '@mui/material';
import * as React from 'react';
import SpaceShipPlayer from '../../assets/elements2D/spaceshipPlayer.svg';
import { Team } from '../../contexts/Contexts.types';
import { UserContext } from '../../contexts/UserContext';

export interface SpaceShipProps {
	index: number;
	team: Team;
	animationdelay: number;
}

export const SpaceShip = (props: SpaceShipProps) => {
	const UserContextValues = React.useContext(UserContext);
	const [progression, setProgression] = React.useState<number>(0);

	React.useEffect(() => {
		// calculate progression percentage for each spaceship
		const calculatedPercent = (props.team.score / UserContextValues.MAX_TEAM_SCORE) * 100;
		setProgression(calculatedPercent);
	}, []);

	return (
		<Box sx={styles.spaceShipContainer} key={`spaceship_${props.index}`}>
			<Box component="div" sx={styles.spaceShip}></Box>
			<Box
				component="div"
				sx={{ ...styles.progressionTrail, height: `${progression}%` }}
			></Box>
		</Box>
	);
};

const styles = {
	spaceShipContainer: {
		width: '60px',
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		alignItems: 'center',
		justifyContent: 'flex-end',
		alignContent: 'flex-end'
	},
	spaceShip: {
		position: 'sticky',
		height: '1.4em',
		width: '1.4em',
		backgroundImage: `url(${SpaceShipPlayer})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center'
	},
	progressionTrail: {
		transition: 'height 1s ease-in-out',

		height: '0%',
		minHeight: '17vh',
		maxHeight: '100%',
		width: '2px',
		background: 'linear-gradient(#FFFF, transparent)',
		outline: 'none',
		borderColor: '#FFF',
		boxShadow: '0 0 10px #FFF',
		borderRadius: '100%'
	}
};
