import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../locales/en.json';

const resources = {
	en: {
		translation: {
			...translationEN,
			rank_end_one: 'st', // 1st, 21st, 31st
			rank_end_two: 'nd', // 2nd, 22nd, 32nd
			rank_end_few: 'rd', // 3rd, 23rd, 33rd
			rank_end_other: 'th' // 4th, 5th, 24th, 11th
		}
	}
};

i18n.use(initReactI18next).init({
	resources,
	lng: 'en',
	fallbackLng: 'en',
	interpolation: {
		escapeValue: true
	}
});

export default i18n;
