import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { NavigationButton } from '../../components/buttons/NavigationButton';
import { PopupContainer } from '../../popup/PopupContainer';
import failImage from '../assets/mission-fail.svg';

export default function GameOverBox(): JSX.Element {
	const navigate = useNavigate();

	return (
		<PopupContainer
			title="Mission failed..."
			open={true}
			onClickCloseButton={() => navigate(`/game`, { replace: true })}
		>
			<img src={failImage} width={'30%'} />

			<Typography textAlign={'center'}>
				One of the gauges is too critical to continue the mission.
			</Typography>

			<NavigationButton gotopath={'/game'}>Back to chapters</NavigationButton>
		</PopupContainer>
	);
}
