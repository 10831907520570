import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GlobalButton } from '../components/buttons/GlobalButton';
import { BorderColorEnum } from '../components/containers/CornerContainer';
import { APIContext } from '../contexts/API/APIContext';
import { APIPostNotificationAsShown } from '../contexts/API/APIFechers';
import { User } from '../contexts/Contexts.types';
import { UIContext } from '../contexts/UIContext';
import { UserContext } from '../contexts/UserContext';
import { PopupContainer } from './PopupContainer';

export const NotificationPopup = () => {
	const UIContextValues = useContext(UIContext);
	const navigate = useNavigate();
	const APIContextValues = useContext(APIContext);
	const { t } = useTranslation();
	// eslint-disable-next-line
	const [_userData, _setUserData] = React.useState<User>();

	const UserContextValues = React.useContext(UserContext);

	const closePopup = async () => {
		UIContextValues?.setNotificationPopupVisibility!(false);

		// call api to update user's notification
		const notificationShown = await APIPostNotificationAsShown(
			APIContextValues.notificationList[0].id
		);

		if (notificationShown) APIContextValues.getNotifications(false);
	};

	return (
		<PopupContainer
			borderColor={BorderColorEnum.normal}
			title={
				UIContextValues.notificationPopupText?.isBonus === false &&
				UIContextValues.notificationPopupText?.challengeId === null
					? UIContextValues.notificationPopupText?.title
					: UIContextValues.notificationPopupText?.title.includes('is ending soon.')
					? 'Reminder'
					: 'Congrats'
			}
			open={UIContextValues?.notificationPopupVisibility!}
			onClickCloseButton={closePopup}
		>
			<Box sx={styles.settingContent}>
				<Typography style={{ overflowWrap: 'anywhere' }}>
					{UIContextValues.notificationPopupText?.isBonus === false &&
					UIContextValues.notificationPopupText?.challengeId === null
						? UIContextValues.notificationPopupText?.description
						: UIContextValues.notificationPopupText?.title}
				</Typography>
				<GlobalButton
					sx={{ whiteSpace: 'nowrap' }}
					onClick={() => {
						closePopup();
						navigate(`/profile/${UserContextValues.myProfile?.id}`);
					}}
				>
					{t('score_notification_button')}
				</GlobalButton>
			</Box>
		</PopupContainer>
	);
};

const styles = {
	iconArea: {
		// backgroundColor: 'yellow',
		width: '100%',
		height: '5em',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingBottom: '0.2em'
	},
	icon: {
		color: 'primary.main',
		transform: {
			xs: 'scale(3)',
			sm: 'scale(4)'
		}
	},
	settingContent: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '1em'
	},
	history: {
		marginTop: '1em',
		marginBottom: '1em'
	}
};
