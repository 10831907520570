import Box from '@mui/material/Box';
import React from 'react';
import soloRankIcon from '../../assets/profil/rank_solo.svg';
import teamRankIcon from '../../assets/profil/rank_team.svg';
import { Team, User } from '../../contexts/Contexts.types';
import { InfoIcon } from './InfoIcon';

interface MainInfoProfileProps {
	children?: React.ReactNode;
	user?: User;
	team?: Team;
	displayTitle?: boolean;
	flexDirection?: string;
}

export const InfoHomePage = (props: MainInfoProfileProps) => {
	return (
		<Box id={'my-points'} component="div" sx={styles.container}>
			<InfoIcon icon={teamRankIcon} text={`${props.team?.rank}`} th={true}></InfoIcon>
			<InfoIcon icon={soloRankIcon} text={`${props.user?.rank}`} th={true}></InfoIcon>
		</Box>
	);
};

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		// backgroundColor: 'red',
		width: '100%',
		height: '100%'
	}
};
