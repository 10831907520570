import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { CalendarEventItem, CalendarEventType } from '../contexts/Contexts.types';

import ChallengeIcon from '../assets/navbar/icon_challenge.svg';
import ConferenceIcon from '../assets/navbar/icon_conf.svg';
import MissionIcon from '../assets/navbar/icon_game.svg';
import LearningIcon from '../assets/navbar/icon_learning.svg';
import PracticeIcon from '../assets/navbar/icon_practice.svg';
import VideoIcon from '../assets/navbar/icon_video.svg';

interface CalendarIconTextProps {
	event: CalendarEventItem;
}

export const CalendarIconText = (props: CalendarIconTextProps) => {
	return (
		<Grid container direction="row" sx={styles.event} key={props.event.title}>
			<Grid item xs={1} sx={styles.iconEvent}>
				{props.event.type.valueOf() === CalendarEventType.CHALLENGE.valueOf() && (
					<Box sx={styles.iconChallenge} />
				)}
				{props.event.type.valueOf() === CalendarEventType.MISSION.valueOf() && (
					<Box sx={styles.iconMission} />
				)}
				{props.event.type.valueOf() === CalendarEventType.LEARNING.valueOf() && (
					<Box sx={styles.iconLearning} />
				)}
				{props.event.type.valueOf() === CalendarEventType.CONF_LEARNING.valueOf() && (
					<Box sx={styles.iconConference} />
				)}
				{props.event.type.valueOf() === CalendarEventType.VIDEO_LEARNING.valueOf() && (
					<Box sx={styles.iconVideo} />
				)}
				{props.event.type.valueOf() === CalendarEventType.PRACTICE.valueOf() && (
					<Box sx={styles.iconPractice} />
				)}
			</Grid>
			<Grid item xs={11}>
				<Typography variant="body1" sx={styles.textEvent}>
					{props.event.title}
				</Typography>
			</Grid>
		</Grid>
	);
};

const styles = {
	event: {
		paddingBottom: '1em !important'
	},
	iconLearning: {
		backgroundImage: `url(${LearningIcon})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		// backgroundColor: 'green',
		height: '85%',
		width: '85%',
		padding: '0.1em'
	},
	iconConference: {
		backgroundImage: `url(${ConferenceIcon})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		// backgroundColor: 'green',
		height: '85%',
		width: '85%',
		padding: '0.1em'
	},
	iconVideo: {
		backgroundImage: `url(${VideoIcon})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		// backgroundColor: 'green',
		height: '85%',
		width: '85%',
		padding: '0.1em'
	},
	iconChallenge: {
		backgroundImage: `url(${ChallengeIcon})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		// backgroundColor: 'green',
		height: '85%',
		width: '85%',
		padding: '0.1em'
	},

	iconMission: {
		backgroundImage: `url(${MissionIcon})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		// backgroundColor: 'green',
		height: '85%',
		width: '85%',
		padding: '0.1em'
	},

	iconPractice: {
		backgroundImage: `url(${PracticeIcon})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		height: '85%',
		width: '85%',
		padding: '0.1em'
	},

	textEvent: {
		// backgroundColor: 'blue',
		// width: '80%',
		paddingLeft: '1em',
		marginBottom: '0.5em'
	},
	eventRow: {
		// display: 'flex',
		// flexDirection: 'row',
		// alignItems: 'center',
		// justifyContent: 'center',
		width: '100%',
		backgroundColor: 'purple'
	},
	iconEvent: {
		// backgroundColor: 'red',
	}
};
