import { NarrativeContentProps } from './GameComponents.model';
import Box from '@mui/material/Box';
import bgScreenImage from '../assets/progress-bg.svg';
import bgImage from '../assets/narrative-bg.svg';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typewriter from '../../utils/Typewriter';

export default function NarrativeContent({ card }: NarrativeContentProps): JSX.Element {
	const smallHeight = useMediaQuery('(max-height:800px)');

	return (
		<Box
			sx={{
				...style.main,
				...{
					fontSize: smallHeight ? '9px' : '10px',
					height: {
						xs: '18em',
						sm: '16.5em',
						md: '18em'
					}
				}
			}}
		>
			<Box sx={style.bg}>
				<img src={bgImage} />
			</Box>
			<Box sx={style.content}>
				<Typography>
					<Typewriter
						text={!card.actionRightText && !card.actionLeftText ? '...' : card.mainText}
						delay={10}
					/>
				</Typography>
			</Box>
		</Box>
	);
}

const style = {
	main: {
		fontSize: '10px',
		width: '100%',
		position: 'relative',

		display: 'flex',
		justifyContent: 'center'
	},
	content: {
		position: 'absolute',
		bottom: '0',
		width: '90%',
		height: '80%',
		backgroundImage: `url(${bgScreenImage})`,
		backgroundSize: '1.2em',
		border: '0.5em solid #282828',
		display: 'flex',
		alignItems: 'center',
		textAlign: 'center',
		'& > p': {
			padding: '0 6%',
			width: '100%',
			color: '#fff',
			textAlign: 'center',
			fontSize: {
				xs: '1.4em',
				sm: '1.5em',
				md: '1.6em'
			},
			lineHeight: {
				xs: '1.5em',
				sm: '1.4em',
				md: '1.3em'
			}
		}
	},
	bg: {
		width: '100%',
		position: 'absolute'
	}
};
