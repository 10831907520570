import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { getRankWithSuffix } from '../../utils/UtilMethods';

interface InfoIconProps {
	id?: string;
	icon?: string;
	text?: string;
	th?: boolean;
}

export const InfoIcon = (props: InfoIconProps) => {
	return (
		<Box id={props.id} component="div" sx={styles.container}>
			<Box component="div" sx={{ ...styles.icon, backgroundImage: `url(${props.icon})` }} />
			<Typography variant="body1">
				{props?.th ? getRankWithSuffix(Number(props.text)) : props.text}
			</Typography>
		</Box>
	);
};

const styles = {
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		marginLeft: '0.8em'
		// backgroundColor: 'red'
	},
	icon: {
		height: '1.5em',
		width: '1.5em',
		marginRight: '0.5em',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center'
		// backgroundColor: 'yellow'
	}
};
