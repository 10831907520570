import { ThemeProvider } from '@mui/material';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useIsMounted } from 'usehooks-ts';
import { APIProvider } from './contexts/API/APIContext';
import { CalendarProvider } from './contexts/CalendarContext';
import { GlobalProvider } from './contexts/GlobalContext';
import { MissionProvider } from './contexts/MissionContext';
import { ParametersProvider } from './contexts/ParametersContext';
import { ScoreProvider } from './contexts/ScoreContext';
import { UIProvider } from './contexts/UIContext';
import { UserProvider } from './contexts/UserContext';
import AppRouter from './router/AppRouter';
import { theme } from './style/Theme';

function App() {
	const isMounted = useIsMounted();
	useEffect(() => {
		if (!isMounted()) return;
	}, [isMounted]);

	return (
		<BrowserRouter>
			<ThemeProvider theme={theme}>
				<APIProvider>
					<GlobalProvider>
						<UIProvider>
							<ParametersProvider>
								<MissionProvider>
									<CalendarProvider>
										<UserProvider>
											<ScoreProvider>
												<AppRouter />
											</ScoreProvider>
										</UserProvider>
									</CalendarProvider>
								</MissionProvider>
							</ParametersProvider>
						</UIProvider>
					</GlobalProvider>
				</APIProvider>
			</ThemeProvider>
		</BrowserRouter>
	);
}

export default App;
