import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import LinkTypography from '../../components/LinkTypography';
import { ReturnButton } from '../../components/buttons/ReturnButton';
import { BorderColorEnum } from '../../components/containers/CornerContainer';
import { Header } from '../../components/page/Header';
import { Navbar } from '../../components/page/Navbar';
import { ScrollablePage } from '../../components/page/ScrollablePage';
import { MicroLearning } from '../../contexts/Contexts.types';
import { GlobalContext } from '../../contexts/GlobalContext';
import { UIContext } from '../../contexts/UIContext';
import { ConfirmationPopup, ConfirmationPopupMethods } from '../../popup/ConfirmationPopup';
import MediaComponent from './MediaComponent';

export const MicroLearningPage = () => {
	const { t } = useTranslation();

	const GlobalContextValues = React.useContext(GlobalContext);
	const UIContextValues = React.useContext(UIContext);
	const navigate = useNavigate();
	const { microlearningId } = useParams();

	const [currentMicrolearningData, setCurrentMicrolearningData] = useState<MicroLearning>();
	const confirmationPopupRef = React.useRef<ConfirmationPopupMethods>(null);

	useEffect(() => {
		UIContextValues?.setLoading?.(true);

		if (!microlearningId) {
			navigate('/micro-learning', { replace: true });
			UIContextValues?.setLoading?.(false);
			return;
		}

		const microlearning = GlobalContextValues?.microLearning?.find(
			(microlearning) => microlearning.id === Number(microlearningId)
		);

		if (!microlearning) {
			navigate('/micro-learning', { replace: true });
			UIContextValues?.setLoading?.(false);
			return;
		}

		setCurrentMicrolearningData(microlearning);
		UIContextValues?.setLoading?.(false);

		return () => {
			UIContextValues?.setLoading?.(false);
		};
	}, [microlearningId]);

	return (
		<>
			<Header
				title={t('micro-learning_header_title')}
				leftSlot={<ReturnButton goto={'/micro-learning'} />}
			></Header>
			<Box sx={styles.overlay}>
				<ScrollablePage>
					<Stack sx={styles.textContainer} spacing={3}>
						<Typography variant="h5">{currentMicrolearningData?.title}</Typography>
						<Chip
							sx={styles.chip}
							size="small"
							label={currentMicrolearningData?.type}
						/>

						<Typography variant="h2" sx={styles.subtitle}>
							{t('micro-learning_subtitle_more_informations')}
						</Typography>

						<LinkTypography variant="body1">
							{currentMicrolearningData?.description}
						</LinkTypography>

						<MediaComponent
							link={currentMicrolearningData?.link}
							externalLink={currentMicrolearningData?.externalLink}
							type={currentMicrolearningData?.type}
						/>

						<ConfirmationPopup
							ref={confirmationPopupRef}
							borderColor={BorderColorEnum.warning}
							title={t('micro-learning_confirmation_popup_title')}
							onYesClick={() => {
								window.open(
									currentMicrolearningData?.externalLink
										? currentMicrolearningData?.link
										: '/api/download/' + currentMicrolearningData?.link,
									'_blank',
									'noreferrer'
								);
							}}
						>
							<Typography>
								{t('micro-learning_confirmation_popup_content')}
							</Typography>
						</ConfirmationPopup>
					</Stack>
				</ScrollablePage>
			</Box>
			<Navbar></Navbar>
		</>
	);
};

const styles = {
	chip: {
		backgroundColor: 'error.main',
		padding: '0.5em',
		fontWeight: 'bold'
		// width: '20%'
	},
	textContainer: {
		marginTop: '2em',
		display: 'flex',
		alignItems: 'center'
		// backgroundColor: 'red'
	},
	mediaContainer: {
		backgroundColor: 'transparent',
		boxShadow: '0',
		width: '100%'
	},
	mediaContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
		'& 	.MuiCardActionArea-focusHighlight': {
			background: 'none'
		}
	},
	media: {
		overflow: 'hidden !important',
		position: 'relative',
		border: '0',
		marginBottom: '1em',
		opacity: '0.8'
	},

	overlay: {
		backgroundColor: 'rgba(40, 40, 40, .6)'
	},
	link: {
		paddingTop: '2em'
	},
	subtitle: {
		width: '100%',
		fontSize: {
			xs: '1em',
			sm: '1.2em'
		}
	},
	video: {
		backgroundColor: '#282828',

		top: 0,
		height: '100%',
		width: '100%'
	}
};
