import React, { useContext, useEffect } from 'react';
import useSound from 'use-sound';
import typewriterSfx from '../assets/sounds/typewriter.mp3';
import { ParametersContext } from '../contexts/ParametersContext';

interface Props {
	text: string;
	delay: number;
}

const Typewriter: React.FC<Props> = ({ text, delay }) => {
	const ParametersContextValues = useContext(ParametersContext);
	const [index, setIndex] = React.useState(0);
	const [splitedText, setSplitedText] = React.useState<string[]>([]);
	const handleType = () => {
		setIndex((prevIndex) => prevIndex + 1);
	};
	const [playTypewriterSfx] = useSound(typewriterSfx, {
		volume: 0.02,
		soundEnabled: ParametersContextValues?.soundEnabled
	});

	useEffect(() => {
		if (text === undefined) return;

		setIndex(0);
		setSplitedText(text?.split(' '));
	}, [text]);

	useEffect(() => {
		if (index > splitedText.length) return;
		const timeout = setTimeout(handleType, delay);
		playTypewriterSfx();
		return () => {
			clearTimeout(timeout);
		};
	}, [index, splitedText, delay]);

	// useEffect(() => {
	//   const i = setInterval(() => {
	//     console.log('splitedText', splitedText);
	//     console.log('index', index);
	//   }, 1000);
	//   return () => {
	//     clearInterval(i);
	//   };
	// }, [splitedText, index]);

	return <span>{splitedText.slice(0, index).join(' ')}</span>;
};

export default Typewriter;
