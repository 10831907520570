import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { NavigationButton } from '../../components/buttons/NavigationButton';
import { PopupContainer } from '../../popup/PopupContainer';
import { ScoreBoxProps } from './GameComponents.model';
import StarsScore from './StarsScore';

export default function ScoreBox({ score }: ScoreBoxProps): JSX.Element {
	const navigate = useNavigate();

	return (
		<PopupContainer
			title="Mission completed!"
			open={true}
			onClickCloseButton={() => navigate('/game', { replace: true })}
		>
			<StarsScore score={score} spacing={2} width={'70%'} />

			<Typography textAlign={'center'} textTransform={'uppercase'}>
				Your score
			</Typography>

			<Box sx={style.score}>
				<Box sx={style.finalScore}>{Math.round(score * 100)}</Box>
				<Box sx={style.maxScore}> / 100</Box>
			</Box>

			{score < 100 && (
				<Typography textAlign={'center'}>
					You can try to improve your final score by replaying this chapter.
				</Typography>
			)}

			<NavigationButton gotopath={'/game'}>Back to chapters</NavigationButton>
		</PopupContainer>
	);
}

const style = {
	score: {
		fontSize: '10px',
		lineHeight: '1',
		textAlign: 'center',
		color: '#fff'
	},
	finalScore: {
		fontSize: '4em',
		display: 'inline'
	},
	maxScore: {
		fontSize: '1.2em',
		display: 'inline'
	}
};
