import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import * as React from 'react';
import { GlobalButton } from './GlobalButton';

import useSound from 'use-sound';
import avatarChangeButtonSfx from '../../assets/sounds/change-avatar-skin.mp3';
import { ParametersContext } from '../../contexts/ParametersContext';

export enum Direction {
	// eslint-disable-next-line
	Up,
	// eslint-disable-next-line
	Down,
	// eslint-disable-next-line
	Left,
	// eslint-disable-next-line
	Right
}

interface ColorSelectionProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	arrowdirection: Direction;
}

export const ArrowButton = (props: ColorSelectionProps | any) => {
	const [arrowIcon, setArrowIcon] = React.useState<any>(null);

	const ParametersContextValues = React.useContext(ParametersContext);
	const [clickSfx] = useSound(avatarChangeButtonSfx, {
		volume: 0.4,
		soundEnabled: ParametersContextValues?.soundEnabled
	});

	React.useEffect(() => {
		switch (props.arrowdirection) {
			case Direction.Left.valueOf():
				setArrowIcon(<ArrowLeftIcon sx={styles.arrow} />);
				break;
			case Direction.Right.valueOf():
				setArrowIcon(<ArrowRightIcon sx={styles.arrow} />);
				break;
			default:
				break;
		}
	}, []);

	return (
		<GlobalButton
			disablesfx={true}
			sx={styles.container}
			{...props}
			onClick={(e) => {
				clickSfx();
				props.onClick?.(e);
			}}
		>
			{arrowIcon}
		</GlobalButton>
	);
};
const styles = {
	container: {
		backgroundColor: 'transparent',
		'&:hover': {
			backgroundColor: 'transparent'
		}
	},
	arrow: {
		transform: 'scale(1.7)',
		color: '#FFFF',
		// backgroundColor: 'purple',
		'&:hover': {
			color: 'primary.main'
		}
	}
};
