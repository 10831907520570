import { FormControlLabel, FormGroup, Link, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { GlobalButton } from '../components/buttons/GlobalButton';
import { BorderColorEnum } from '../components/containers/CornerContainer';
import { Version } from '../components/version';
import { ParametersContext } from '../contexts/ParametersContext';
import { ConfirmationPopup, ConfirmationPopupMethods } from './ConfirmationPopup';
import { PopupContainer } from './PopupContainer';

export const ParametersPopup = () => {
	const ParamsContextValues = useContext(ParametersContext);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [, setUser] = useLocalStorage('USER_INFO', null);

	const confirmationPopupRef = useRef<ConfirmationPopupMethods>(null);

	const logout = () => {
		(async () => {
			try {
				ParamsContextValues?.setParametersVisibility!(false);

				window.sessionStorage.removeItem('user');
				setUser(null);

				window.location.replace(
					`${
						process.env.NODE_ENV === 'development' ? 'https://localhost:3000' : ''
					}/logout`
				);
			} catch (e) {
				console.error('Could not log out');
			}
		})();
	};

	return (
		<PopupContainer
			borderColor={BorderColorEnum.tutorial}
			title={t('homePage_setting_title')}
			open={ParamsContextValues?.parametersVisibility!}
			onClickCloseButton={() => {
				ParamsContextValues?.setParametersVisibility!(false);
			}}
		>
			<Box sx={styles.settingContent}>
				<FormGroup sx={styles.setting}>
					<Typography>{t('homePage_setting_sound')}</Typography>
					<FormControlLabel
						label=""
						control={
							<IOSSwitch
								sx={{ m: 1 }}
								checked={ParamsContextValues.soundEnabled}
								onChange={(e) => {
									ParamsContextValues?.setSoundEnabled!(e.target.checked);
								}}
							/>
						}
					/>
				</FormGroup>
				<GlobalButton
					onClick={() => {
						confirmationPopupRef.current?.setOpen(true);
					}}
					sx={{
						backgroundColor: 'error.main'
					}}
				>
					{t('homePage_setting_logout')}
				</GlobalButton>
				<ConfirmationPopup
					ref={confirmationPopupRef}
					borderColor={BorderColorEnum.warning}
					title={t('homePage_setting_title_confirmation_logout')}
					onYesClick={logout}
				>
					<Typography>{t('homePage_setting_description_confirmation_logout')}</Typography>
				</ConfirmationPopup>
				<Link
					underline="hover"
					sx={{ textAlign: 'center', padding: '0.5em' }}
					onClick={() => {
						ParamsContextValues?.setParametersVisibility!(false);
						navigate('/legals', { replace: true });
					}}
				>
					{t('homePage_setting_legal')}
				</Link>
			</Box>
			<Version></Version>
		</PopupContainer>
	);
};

const styles = {
	settingContent: {
		width: '100%',

		// backgroundColor: 'red',
		display: 'flex',
		flexDirection: 'column',
		gap: '2em'
	},
	setting: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: '2em'
	}
};
const IOSSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 2,
		transitionDuration: '300ms',
		'&.Mui-checked': {
			transform: 'translateX(16px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				backgroundColor: theme.palette.mode === 'dark' ? '#329E51' : '#329E51',
				opacity: 1,
				border: 0
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5
			}
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			color: '#329E51',
			border: '6px solid #fff'
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
			color:
				theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
		},
		'&.Mui-disabled + .MuiSwitch-track': {
			opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
		}
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 22,
		height: 22
	},
	'& .MuiSwitch-track': {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
			duration: 500
		})
	}
}));
