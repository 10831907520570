import AddIcon from '@mui/icons-material/Add';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useSound from 'use-sound';
import clickButtonSfx from '../../assets/sounds/button-click.mp3';
import uploadFileIcon from '../../assets/ui/upload_file.svg';
import { GlobalButton } from '../../components/buttons/GlobalButton';
import { ReturnButton } from '../../components/buttons/ReturnButton';
import { CornerContainer } from '../../components/containers/CornerContainer';
import { Header } from '../../components/page/Header';
import { Navbar } from '../../components/page/Navbar';
import { ScrollablePage } from '../../components/page/ScrollablePage';
import { APIContext } from '../../contexts/API/APIContext';
import { APIPostChallenge, APIUploadFile } from '../../contexts/API/APIFechers';
import { Challenge } from '../../contexts/Contexts.types';
import { GlobalContext } from '../../contexts/GlobalContext';
import { ParametersContext } from '../../contexts/ParametersContext';
import { UIContext } from '../../contexts/UIContext';
import { UserContext } from '../../contexts/UserContext';
import { supportedFileExtensions } from '../../utils/UtilMethods';

export const ChallengeSubmissionPage = () => {
	const UIContextValues = useContext(UIContext);
	const GlobalContextValues = useContext(GlobalContext);
	const UserContextValues = useContext(UserContext);
	const APIContextValues = useContext(APIContext);

	const navigate = useNavigate();
	const maxFileSize = 100000000; // 100MB
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(true);

	// display name of media file
	const [media, setMedia] = useState<File>();
	const [fileExtension, setFileExtension] = useState<string>();

	const { challengeId } = useParams();

	const [currentChallenge, setCurrentChallenge] = useState<Challenge>();

	const [correctionTime, setCorrectionTime] = useState<string>('');

	const ParametersContextValues = useContext(ParametersContext);
	const [buttonClickSfx] = useSound(clickButtonSfx, {
		volume: 0.8,
		soundEnabled: ParametersContextValues?.soundEnabled
	});

	useEffect(() => {
		setCorrectionTime(new Date(Number(currentChallenge?.endDate)).toLocaleDateString('us-US'));
	}, [currentChallenge]);

	useEffect(() => {
		setIsLoading(true);

		if (!challengeId) {
			navigate('/challenges', { replace: true });
			setIsLoading(false);
			return;
		}

		const _currentChallenge = GlobalContextValues?.availableChallenges?.find(
			(challenge) => challenge.id === Number(challengeId)
		);
		if (!_currentChallenge) {
			navigate('/challenges', { replace: true });
			setIsLoading(false);
			return;
		}

		setCurrentChallenge(_currentChallenge);
		setIsLoading(false);
	}, [challengeId, GlobalContextValues?.availableChallenges]);

	useEffect(() => {
		if (media) {
			const name = media?.name;
			const _fileExt = name.split('.')[name.split('.').length - 1].toLowerCase();
			setFileExtension(_fileExt);
		}
	}, [media]);

	const handleMediaChange = (event: any) => {
		setMedia(undefined);
		const sanitizedMedia = sanitizeFile(event.target.files[0]);

		if (sanitizedMedia) {
			setErrorMessage('');
			setMedia(sanitizedMedia);
		}
	};

	const displayFileExtensionErrorPopup = useCallback(() => {
		UIContextValues?.setErrorPopupText!({
			title: t('challenge_submission_error_message_extension_title'),
			description: t('challenge_submission_error_message_extension', {
				extensionList: supportedFileExtensions.join(', ')
			})
		});
		UIContextValues?.setErrorPopupVisibility?.(true);
	}, []);

	const displayUploadErrorPopup = useCallback(() => {
		UIContextValues?.setErrorPopupText!({
			title: t('challenge_submission_error_message_general_title'),
			description: t('challenge_submission_error_message_general')
		});
		UIContextValues?.setErrorPopupVisibility?.(true);
	}, []);

	const displayFileTooLargeErrorPopup = useCallback(() => {
		UIContextValues?.setErrorPopupText!({
			title: t('challenge_submission_error_message_size_title'),
			description: t('challenge_submission_error_message_size', {
				maxFileSize: maxFileSize / 1000000
			})
		});
		UIContextValues?.setErrorPopupVisibility?.(true);
	}, []);

	const displaySubmissionConfirmationPopup = useCallback(() => {
		UIContextValues?.setSuccessPopupText!({
			title: '',
			description: t('challenge_submission_popup_success', {
				date: correctionTime,
				interpolation: { escapeValue: false }
			})
		});
		UIContextValues?.setSuccessPopupVisibility?.(true);
	}, [correctionTime]);

	const sanitizeFile = (file: File): File | null => {
		if (!file) return null;
		const _fileExt = file?.name.split('.')[file?.name.split('.').length - 1].toLowerCase();
		if (!supportedFileExtensions.includes(_fileExt)) {
			displayFileExtensionErrorPopup();
			return null;
		}

		// check file size is less than 10MB
		if (file.size > maxFileSize) {
			displayFileTooLargeErrorPopup();
			return null;
		}

		return file; // File passed sanitization checks
	};

	const submit = useCallback(async () => {
		if (isLoading) return;
		setIsLoading(true);
		if (!media) {
			setIsLoading(false);

			setErrorMessage(t('challenge_submission_error_message_media'));
			return;
		}

		const fileType = media.type.split('/')[1].toLowerCase();

		const fullFileName = `Team_${
			UserContextValues.myTeam?.name.length !== 0
				? UserContextValues.myTeam?.name
				: APIContextValues.myProfile.teamId
		}_${APIContextValues.myProfile.name}_${currentChallenge?.name}.${fileType}`;

		const uploadStatus = await APIUploadFile({
			file: media,
			name: fullFileName,
			label: fullFileName
		});

		if (!uploadStatus.success) {
			setIsLoading(false);

			displayUploadErrorPopup();
			return;
		}

		const postStatus = await APIPostChallenge({
			challengeId: currentChallenge?.id!,
			teamId: APIContextValues.myProfile.teamId,
			memberId: APIContextValues.myProfile.id,
			fileType: media.type,
			filePath: fullFileName,
			fileName: fullFileName
		});

		if (postStatus.success) {
			UserContextValues?.submitChallenge?.(currentChallenge?.id!, media);
			setErrorMessage('');

			displaySubmissionConfirmationPopup();
			setIsLoading(false);

			navigate('/challenges', { replace: true });
		} else {
			setIsLoading(false);

			displayUploadErrorPopup();
			return;
		}
	}, [currentChallenge, media]);

	const { t } = useTranslation();

	return (
		<>
			<Header
				title={t('challenge_submission_header_title')}
				leftSlot={<ReturnButton goto={`/challenges/available/${challengeId}`} />}
			></Header>
			<ScrollablePage>
				<CornerContainer sx={styles.cornerContainer}>
					<Box sx={styles.challengeContent}>
						<Stack spacing={2}>
							<Typography variant="h3">
								{t('challenge_submission_subtitle_media')}
							</Typography>
							<Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
								{media?.name}
							</Typography>
							<Button
								sx={{
									...styles.uploadButton,
									backgroundImage: media ? `url(${uploadFileIcon})` : 'none'
								}}
								variant="contained"
								component="label"
								onClick={() => {
									buttonClickSfx();
								}}
							>
								{!media && <AddIcon color="secondary" />}
								{media && (
									<Typography sx={styles.textExtension} variant="body1">
										{fileExtension}
									</Typography>
								)}

								<input hidden multiple type="file" onChange={handleMediaChange} />
							</Button>

							<Typography sx={styles.errorMessage} variant="body1">
								{errorMessage}
							</Typography>

							<Box sx={styles.buttonArea} onClick={submit}>
								{isLoading ? (
									<CircularProgress></CircularProgress>
								) : (
									<GlobalButton>{t('challenge_submission_button')}</GlobalButton>
								)}
							</Box>
						</Stack>
					</Box>
				</CornerContainer>
			</ScrollablePage>
			<Navbar></Navbar>
		</>
	);
};

const styles = {
	submitBox: {
		alignItems: 'center !important'
	},
	errorMessage: {
		textAlign: 'center',
		marginTop: '1em',
		color: 'error.main'
	},
	commentBox: {
		backgroundColor: '#FFFF',
		color: '#282828 !important',
		borderRadius: '0.4em',
		'& .MuiInputBase-input': {
			color: '#282828 !important'
		}
	},
	uploadButton: {
		backgroundColor: 'transparent',
		border: '0.2em #FFFF dashed',
		height: '10em',
		width: '100%',
		backgroundSize: '5em',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		'&:hover': {
			backgroundColor: 'transparent',
			border: '0.2em #329E51 dashed'
		}
	},
	cornerContainer: {
		marginTop: '4em'
	},
	challengeContent: {
		padding: '3em'
	},
	buttonArea: {
		display: 'flex',
		justifyContent: 'center'
	},

	textExtension: {
		// position: 'absolute',
		// backgroundColor: 'red',
		textAlign: 'center',
		// border: '0.4em #FFFF solid',
		// borderRadius: '0.4em',
		minWidth: '5em'
	}
};
