import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useSound from 'use-sound';
import clickButtonSfx from '../../assets/sounds/button-click.mp3';
import { User } from '../../contexts/Contexts.types';
import { ParametersContext } from '../../contexts/ParametersContext';
import { UIContext } from '../../contexts/UIContext';
import { CustomAvatar } from './CustomAvatar';

interface AvatarMemberProps {
	user: User;
	me?: boolean;
	displayFirstName?: boolean;
}

export const AvatarMember = ({ displayFirstName = true, ...props }: AvatarMemberProps) => {
	const navigate = useNavigate();

	const UIContextValues = useContext(UIContext);

	const ParametersContextValues = React.useContext(ParametersContext);
	const [buttonClickSfx] = useSound(clickButtonSfx, {
		volume: 0.8,
		soundEnabled: ParametersContextValues?.soundEnabled
	});

	return (
		<Button
			sx={styles.avatarMemberContainer}
			onClick={() => {
				buttonClickSfx();
				navigate(`/profile/${props.user?.id}`);
				UIContextValues?.setMenuVisibility!(false);
			}}
		>
			<Box
				sx={
					props.me
						? { ...styles.avatarMemberPortrait, ...styles.avatarMemberPortraitBorder }
						: { ...styles.avatarMemberPortrait }
				}
			>
				<CustomAvatar avatarInfos={props.user} />
			</Box>
			{displayFirstName && (
				<Typography
					sx={
						props.me
							? { ...styles.teamMemberName, ...styles.teamMemberNamePortrait }
							: { ...styles.teamMemberName }
					}
					variant="body1"
				>
					{props.user.name}
				</Typography>
			)}
		</Button>
	);
};
const styles = {
	avatarMemberContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '0.5em',
		textAlign: 'center'
	},
	teamMemberName: {
		fontSize: '1em',
		textTransform: 'none !important',
		textAlign: 'center'
	},
	teamMemberNamePortrait: {
		color: ' #329E51',
		fontWeight: 'bold',
		textAlign: 'center'
	},
	avatarMemberPortrait: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden',
		backgroundColor: 'rgba(28,28,28,0.6)',
		height: '80px',
		width: '80px',
		padding: '0.5em',
		borderRadius: '0.8em',
		svg: {
			transform: 'scale(1.6)',
			marginTop: {
				xs: '11vh',
				sm: '8vh',
				md: '7vh'
			}
		}
	},
	avatarMemberPortraitBorder: {
		border: ' #329E51 2px',
		borderStyle: 'solid solid none solid '
	}
};
