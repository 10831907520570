import Box from '@mui/material/Box';
import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { Team } from '../contexts/Contexts.types';
import { UIContext } from '../contexts/UIContext';
import { GlobalButton } from './buttons/GlobalButton';

interface GlobalPictureProps {
	size?: Object | string;
	team?: Team;
	disabled?: boolean;
}

export const GlobalPicture = (props: GlobalPictureProps) => {
	const navigate = useNavigate();
	const UIContextValues = useContext(UIContext);

	return (
		<GlobalButton
			disabled={props.disabled}
			sx={styles.container}
			onClick={() => {
				navigate?.(`/team/${props.team?.id}`);
				UIContextValues.setLeaderboardVisibility(false);
			}}
		>
			<Box
				component="img"
				sx={{
					height: {
						xs: '4em',
						sm: '5.8em',
						md: '4em',
						lg: '4.5em'
					},
					width: {
						xs: '4em',
						sm: '5.8em',
						md: '4em',
						lg: '4.5em'
					}
					// borderRadius: '100%',
				}}
				alt="Teamlogo"
				src={props.team?.base64}
			/>
		</GlobalButton>
	);
};

const styles = {
	container: {
		backgroundColor: '#060C26',
		height: '4.5em',
		width: '4.5em',
		padding: '0 !important',
		borderRadius: '100%',
		display: 'flex',
		justifyContent: 'center !important',
		alignItems: 'center !important',
		alignContent: 'center !important',
		overflow: 'hidden',
		border: '0.2em solid #FFFF',
		transform: {
			xs: 'scale(0.7)',
			sm: 'scale(0.8)'
		},
		img: {
			transform: {
				xs: 'scale(1)',
				sm: 'scale(0.7)',
				md: 'scale(1)'
			}
		}
	}
};
