import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { CMACGMLogo, TangramLogo } from '../assets/MediaComponents';

export const LogoArea = () => {
	const { t } = useTranslation();

	return (
		<Box sx={styles.logoArea}>
			<Box sx={styles.cmacgm}>
				<CMACGMLogo></CMACGMLogo>
			</Box>
			<Box sx={styles.tangram}>
				<Typography>{t('login_powered_by')}</Typography>
				<TangramLogo></TangramLogo>
			</Box>
		</Box>
	);
};
export const styles = {
	logoArea: {
		position: 'absolute',
		bottom: '0',
		height: {
			xs: '6vh',
			sm: '6vh'
		},

		width: '100%',
		backgroundColor: 'rgba(28, 28, 28, .5)',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
		alignItems: 'center',
		padding: {
			xs: '0.2em',
			sm: '0.5em'
		}
	},
	tangram: {
		// backgroundColor: 'red',
		// position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '6vh',
		width: '30%'
	},
	cmacgm: {
		height: '5vh',
		width: {
			xs: '15%',
			sm: '20%'
		}
	}
};
